/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import ParConstants from "../../../common/ParConstants";

import CollapsibleCard from "../../shared/CollapsibleCard";
import TextBoxField from "../../shared/TextBoxField";
import ParHelpers, {
  isGlobalEmployee,
  getPercentageIncrease
} from "../../../helpers/ParHelpers";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import security from "../../../services/Security";
import "../../../styles/par/par_status.scss";

const ParStatus = ({
  parType,
  fillType,
  formikProps,
  showFieldsReadOnly,
  hideFields,
  parGeoRange,
  parPayRanges
}) => {
  const getStatus = (status) =>
    ParConstants.PAR_QUEUE_STATUS[status]?.toUpperCase();
  const canModifyPARRecords = security.canEditPARData();

  const showWarning = () => {
    const {
      requested_offer: requestedOffer,
      current_base_salary: currentBaseSalary,
      approved_offer: approvedOffer
    } = formikProps?.values || {};
    const offerValue = approvedOffer || requestedOffer;
    const percentageIncrease = getPercentageIncrease(
      currentBaseSalary,
      offerValue
    );
    const compaRatio = ParHelpers.getCompRatioOfOffer(
      parGeoRange,
      parPayRanges,
      formikProps
    );

    if (
      ((percentageIncrease > 20 || compaRatio > 1.2) &&
        formikProps.values.current_queue_name ===
          ParConstants.PAR_QUEUE.FINANCE) ||
      formikProps.values.current_queue_name === ParConstants.PAR_QUEUE.BRAND_CEO
    ) {
      return (
        <span data-testid="exception-message" className="exception-message">
          The Approved Offer value has triggered an exception. Please include
          the justification for the exception in the comments before advancing
          the ticket.
        </span>
      );
    }

    return null;
  };

  return (
    <CollapsibleCard title="PAR Status">
      <div className="row">
        <div className="col-md-3 mt-3">
          <label htmlFor="par_type">PAR Type</label>
          <div id="par_type" className="form-description">
            <strong>
              {`${
                parType != null
                  ? parType
                  : ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST
              }${
                fillType?.value
                  ? `, ${fillType.value}`
                  : formikProps.values?.reallocation_reason
                  ? `, ${formikProps.values?.reallocation_reason.value}`
                  : ""
              }`}
            </strong>
          </div>
        </div>
        {!hideFields.ticket_status && (
          <div className="col-md-3 mt-3">
            <label htmlFor="par_ticket_status">Ticket Status</label>
            <div id="par_ticket_status" className="form-description">
              {formikProps.values?.current_queue_name === null &&
              formikProps.values?.ticket_status ===
                ParConstants.PAR_ACTIONS.PAR_COMPLETE ? (
                <strong>
                  {formikProps.values?.ticket_status?.toUpperCase()}
                </strong>
              ) : (
                <strong>
                  {getStatus(formikProps.values?.current_queue_name)}
                </strong>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Show requisition_id for non-global employee types */}
      {!isGlobalEmployee(formikProps.values?.employee_type?.value) && (
        <div className="row">
          {!hideFields.requisition_id && (
            <div className="col-md-3 mt-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="requisition_id"
                label="Requisition ID"
                className="ml-1"
                showDescription={showFieldsReadOnly.requisition_id}
              />
            </div>
          )}
          {!hideFields.position_id && (
            <div className="col-md-3 mt-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="position_id"
                label="Position ID"
                className="ml-1"
                showDescription={showFieldsReadOnly.position_id}
              />
            </div>
          )}
        </div>
      )}
      {(!hideFields.approved_offer ||
        !hideFields.approved_effective_date ||
        !hideFields.decision) && (
        <div className="row approved-offer">
          {!hideFields.approved_offer && (
            <div className="col-md-2 mt-3">
              <TextBoxField
                className="ml-1"
                type="number"
                formikProps={formikProps}
                fieldName="approved_offer"
                label="Approved Offer"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.approved_offer}
              />
              <div className="mt-1">{showWarning()}</div>
            </div>
          )}
          {!hideFields.approved_effective_date && (
            <div className="col-md-2 mt-3">
              <DatePickerBoxField
                className="ml-0"
                formikProps={formikProps}
                label="Approved Effective Date"
                fieldName="approved_effective_date"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.approved_effective_date}
              />
            </div>
          )}
          {!hideFields.decision && (
            <div className="col-md-4 mt-3">
              <label htmlFor="par_decision">Decision</label>
              <div id="par_decision" className="form-description">
                <strong>{formikProps.values.decision}</strong>
              </div>
            </div>
          )}
        </div>
      )}
    </CollapsibleCard>
  );
};

ParStatus.propTypes = {
  parType: PropTypes.string,
  fillType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      ticket_status: PropTypes.string,
      current_queue_name: PropTypes.string,
      decision: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      reallocation_reason: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    })
  }).isRequired,
  showFieldsReadOnly: PropTypes.shape({
    requisition_id: PropTypes.bool,
    position_id: PropTypes.bool,
    approved_offer: PropTypes.bool,
    approved_effective_date: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    requisition_id: PropTypes.bool,
    position_id: PropTypes.bool,
    ticket_status: PropTypes.bool,
    decision: PropTypes.bool,
    approved_offer: PropTypes.bool,
    approved_effective_date: PropTypes.bool
  }),
  parGeoRange: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array)
};

ParStatus.defaultProps = {
  parType: null,
  fillType: {},
  hideFields: {},
  showFieldsReadOnly: {},
  parGeoRange: [],
  parPayRanges: []
};

export default ParStatus;
