import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import Constants from "../../common/Constants";
import api from "../../services/RapApi";
import parApi from "../../services/ParApi";
import LoadingSpinner from "../shared/LoadingSpinner";
import ParContext from "../../store/ParContext";
import ParLandingPage from "./ParLandingPage";

const ParMainPage = () => {
  const [parWorkflows, setPARWorkflows] = useState(null);
  const [validations, setValidations] = useState(null);
  const [parAvailablePins, setAvailablePins] = useState(null);
  const [parPayRanges, setPARPayRanges] = useState(null);

  const parContextValue = useMemo(
    () => ({
      parWorkflows,
      setPARWorkflows,
      validations,
      setValidations,
      parAvailablePins,
      parPayRanges,
      setPARPayRanges
    }),
    [parWorkflows, validations, parAvailablePins, parPayRanges]
  );

  useEffect(async () => {
    try {
      const validationPromise = api.getValidations({ activeInPAR: "true" });
      const workflowPromise = parApi.getPARWorkflows();
      const parPayRangesPromise = parApi.getPARPayRanges();
      const pinsPromise = await parApi.getAvailablePins({});
      // Wait for all promises to resolve, call both apis in parallel
      const [validationsList, workflowList, pinsList, parPayRangeList] =
        await Promise.all([
          validationPromise,
          workflowPromise,
          pinsPromise,
          parPayRangesPromise
        ]);
      setValidations(validationsList);
      setPARWorkflows(workflowList);
      setAvailablePins(pinsList);
      setPARPayRanges(parPayRangeList);
    } catch (ex) {
      toast.error(
        `There was an error in fetching Validations & Workflows - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  }, []);

  return (
    <>
      {(!validations || !parWorkflows || !parAvailablePins) && (
        <div key="loadingContainer" className="loadingContainer">
          <span className="loadingMsg">
            <>
              <LoadingSpinner status="Loading" textMessage="" />
            </>
          </span>
        </div>
      )}
      <ParContext.Provider value={parContextValue}>
        {validations && parWorkflows && parAvailablePins && <ParLandingPage />}
      </ParContext.Provider>
    </>
  );
};

export default ParMainPage;
