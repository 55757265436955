import { useMemo } from "react";
import ParConstants from "../../../common/ParConstants";

const useSectionVisibility = (parTypeParam) => {
  const checkSectionVisibility = useMemo(() => {
    const {
      ADD_TO_STAFF_REQUEST,
      OPEN_REQUEST_TBH,
      URGENT_COUNTER_OFFER,
      BACKFILL_REQUEST,
      REALLOCATION_TBH,
      PROMOTION_IN_PLACE,
      REALLOCATION_ACTIVE,
      SALARY_ADJUSTMENT
    } = ParConstants.PAR_WORKFLOW;

    const workflowName =
      parTypeParam?.name?.split("-")[0]?.trim() ?? ADD_TO_STAFF_REQUEST;

    const parTypeConfig = {
      [OPEN_REQUEST_TBH]: [
        "PositionDetails",
        "RequisitionInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [ADD_TO_STAFF_REQUEST]: [
        "PositionDetails",
        "RequisitionInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [URGENT_COUNTER_OFFER]: [
        "PositionDetails",
        "EmployeeDetails",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [REALLOCATION_TBH]: [
        "PositionDetails",
        "EmployeeDetails",
        "ReallocationInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [BACKFILL_REQUEST]: [
        "PositionDetails",
        "RequisitionInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [PROMOTION_IN_PLACE]: [
        "PositionDetails",
        "EmployeeDetails",
        "PromotionInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [REALLOCATION_ACTIVE]: [
        "PositionDetails",
        "EmployeeDetails",
        "ReallocationInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ],
      [SALARY_ADJUSTMENT]: [
        "PositionDetails",
        "EmployeeDetails",
        "SalaryAdjustmentInstructions",
        "PositionJustification",
        "ParStatus",
        "TicketAction"
      ]
    };

    return (section) => parTypeConfig[workflowName]?.includes(section);
  }, [parTypeParam]);

  return checkSectionVisibility;
};

export default useSectionVisibility;
