/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import TextBoxField from "../../shared/TextBoxField";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import Tooltip from "../../shared/Tooltip";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";
import CollapsibleCard from "../../shared/CollapsibleCard";
import helpers, {
  getPercentageIncrease,
  isGlobalEmployee
} from "../../../helpers/ParHelpers";

const InternalFill = ({
  formikProps,
  canModifyPARRecords,
  availablePINs,
  showDescription,
  showFieldsReadOnly,
  hideFields,
  parGeoRange,
  parPayRanges,
  workflowName
}) => {
  const setEmployeeDetails = (value) => {
    let details = availablePINs.filter((x) => x.employeeName === value);
    if (details && details[0]) {
      formikProps.setFieldValue("employee_workdayid", details[0]?.workdayId);
      formikProps.setFieldValue(
        "employee_workdaytitle",
        details[0]?.workdayTitle
      );
      formikProps.setFieldValue("employee_type", {
        label: details[0]?.employeeType,
        value: details[0]?.employeeType
      });
      formikProps.setFieldValue("employee_location", {
        label: details[0]?.location,
        value: details[0]?.location
      });
    }
    if (value === RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
      formikProps.setFieldValue("job_code", null);
    }
  };

  const isFieldRequired = () =>
    formikProps?.values?.current_queue_name ===
      ParConstants.PAR_QUEUE.HR_INTAKE &&
    !isGlobalEmployee(formikProps?.values?.employee_type?.value);

  const filterAvailablePINs = (employeeType) => {
    let filteredPINs = [];

    if (workflowName !== ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH) {
      filteredPINs = availablePINs;
    } else if (employeeType === RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
      // Employee Type = 'Global'
      filteredPINs = availablePINs.filter(
        (x) => x.employeeType === employeeType
      );
    } else {
      filteredPINs = availablePINs.filter(
        (x) => x.employeeType !== RapConstants.EMPLOYEE_TYPE_VALUES[1]
      );
    }

    return filteredPINs;
  };

  return (
    <CollapsibleCard
      className="highlighted-panel"
      title="Internal Fill Requirements"
    >
      <div className="row">
        <div className="col-md-3 mt-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={filterAvailablePINs(
              formikProps.initialValues.employee_type?.value
            ).map((x) => x.employeeName)}
            fieldName="employee_name"
            label="Internal Fill Employee"
            disable={!canModifyPARRecords}
            onValueChange={setEmployeeDetails}
            showDescription={
              showDescription && showFieldsReadOnly.employee_name
            }
          />
        </div>
      </div>
      {formikProps.values.employee_name &&
        formikProps.values.employee_name.value && (
          <>
            <div className="row">
              <div className="col-md-10 mt-3">
                <div className="row par-table par-table-highlight ml-0">
                  <div className="col-md-3">
                    <strong>Employee Workday ID</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Business Title</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Type</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Location</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row par-table  par-table-content ml-0">
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_workdayid}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_workdaytitle}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_type?.value}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>
                      {formikProps.values?.employee_location?.value}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {!hideFields.current_base_salary && (
                <div className="col-md-2 mt-3">
                  <TextBoxField
                    className="ml-1"
                    type="number"
                    formikProps={formikProps}
                    fieldName="current_base_salary"
                    label="Current Base Salary"
                    disable={!canModifyPARRecords}
                    isOptional={!isFieldRequired()}
                    showDescription={showFieldsReadOnly.current_base_salary}
                  />
                </div>
              )}
              {!hideFields.requested_offer && (
                <div className="col-md-2 mt-3">
                  <TextBoxField
                    className="ml-1"
                    type="number"
                    formikProps={formikProps}
                    fieldName="requested_offer"
                    label="Requested Offer"
                    disable={!canModifyPARRecords}
                    isOptional={!isFieldRequired()}
                    showDescription={showFieldsReadOnly.requested_offer}
                  />
                </div>
              )}
              {!hideFields.date_of_last_compensation_change && (
                <div className="col-md-2 mt-3">
                  <DatePickerBoxField
                    className="ml-0"
                    formikProps={formikProps}
                    label="Date Of Last Compensation Change"
                    isOptional={!isFieldRequired()}
                    fieldName="date_of_last_compensation_change"
                    disable={!canModifyPARRecords}
                    showDescription={
                      showFieldsReadOnly.date_of_last_compensation_change
                    }
                  />
                </div>
              )}
              {!hideFields.date_of_lastpromotion && (
                <div className="col-md-2 mt-3">
                  <DatePickerBoxField
                    className="ml-0"
                    formikProps={formikProps}
                    label="Date Of Last Promotion"
                    isOptional={!isFieldRequired()}
                    fieldName="date_of_lastpromotion"
                    disable={!canModifyPARRecords}
                    showDescription={showFieldsReadOnly.date_of_lastpromotion}
                  />
                </div>
              )}
              {!hideFields.current_performance_rating && (
                <div className="col-md-2 mt-3">
                  <TypeAheadBoxField
                    formikProps={formikProps}
                    suggestions={ParConstants.PERFORMANCE_RATINGS}
                    fieldName="current_performance_rating"
                    label="Current Performance Rating"
                    disable={!canModifyPARRecords}
                    isOptional={!isFieldRequired()}
                    showDescription={
                      showFieldsReadOnly.current_performance_rating
                    }
                  />
                </div>
              )}
            </div>
            {formikProps.values?.employee_type &&
              formikProps.values.employee_type?.value !==
                RapConstants.EMPLOYEE_TYPE_VALUES[1] && (
                <div className="row">
                  {!hideFields.percent_increase && (
                    <div className="mt-3 col-md-2">
                      <div className="topTooltipContainer ml-1">
                        <div className="textLabel">Percent Increase</div>
                        <div className="tooltip-cell">
                          <Tooltip
                            className="tooltip-content"
                            text="Please enter the Requested Offer and Current Base Salary to see the Percent Increase value"
                          >
                            <i className="fas fa-info-circle fa-lg info-color" />
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        id="percent_increase"
                        className="form-description mt-2 ml-1"
                      >
                        <strong>
                          {getPercentageIncrease(
                            formikProps.values.current_base_salary,
                            formikProps.values.approved_offer ||
                              formikProps.values.requested_offer
                          )}
                        </strong>
                      </div>
                    </div>
                  )}

                  {formikProps.values?.employee_type &&
                    formikProps.values.employee_type?.value !==
                      RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                    !hideFields.compa_ratio_of_offer && (
                      <div className="mt-3 col-md-2">
                        <div className="topTooltipContainer">
                          <div className="textLabel">Compa Ratio of Offer</div>
                          <div className="tooltip-cell">
                            <Tooltip
                              className="tooltip-content"
                              text="Please Enter the Requested Offer and Select Job Profile to see this Calculated Ratio"
                            >
                              <i className="fas fa-info-circle fa-lg info-color" />
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          id="compa_ratio_of_offer"
                          className="form-description mt-2"
                        >
                          <strong>
                            {helpers.getCompRatioOfOffer(
                              parGeoRange,
                              parPayRanges,
                              formikProps
                            )}
                          </strong>
                        </div>
                      </div>
                    )}
                </div>
              )}
          </>
        )}
    </CollapsibleCard>
  );
};

InternalFill.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      employee_name: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      current_queue_name: PropTypes.string,
      current_base_salary: PropTypes.string,
      requested_offer: PropTypes.string,
      approved_offer: PropTypes.string,
      employee_location: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    initialValues: PropTypes.shape({
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  availablePINs: PropTypes.instanceOf(Array),
  parGeoRange: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  showFieldsReadOnly: PropTypes.shape({
    current_performance_rating: PropTypes.bool,
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    requested_offer: PropTypes.bool,
    employee_name: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    current_performance_rating: PropTypes.bool,
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    requested_offer: PropTypes.bool,
    compa_ratio_of_offer: PropTypes.bool,
    percent_increase: PropTypes.bool
  }),
  workflowName: PropTypes.string
};

InternalFill.defaultProps = {
  availablePINs: [],
  parGeoRange: [],
  parPayRanges: [],
  canModifyPARRecords: false,
  showDescription: false,
  showFieldsReadOnly: {},
  hideFields: {},
  workflowName: ""
};

export default InternalFill;
