import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import ParApi from "../../services/ParApi";
import Constants from "../../common/Constants";

import ParStaffTable from "./submitter_staff/ParStaffTable";
import TypeAheadBoxField from "../shared/TypeAheadBoxField";

import "../../styles/par-table.scss";
import "../../styles/tabs.scss";
import String from "../../helpers/String";
import spreadhseet from "../../assets/spreadhseet.png";
import spreadhseetdisabled from "../../assets/Spreadhseet_icon_disabled.png";
import ParQueue from "./ParQueue";
import { useParContext } from "../../store/ParContext";
import LoadingSpinner from "../shared/LoadingSpinner";
import ParConstants from "../../common/ParConstants";

/**
 * Main Landing PAGE of PAR. Displays list of PAR Submissions.
 * Only for Brand Submitter Role, shows 2 tabs:
 *  - Staff Plans (Active TAB)
 *  - PAR Submissions
 */

const ParLandingPage = () => {
  const appRef = useRef(); // Create a ref object
  const winUser = window.userSession || "";

  const navigate = useNavigate();
  const location = useLocation();

  const [availablePins, setAvailablePins] = useState([]);
  const [clientBrandList, setClientBrandList] = useState([]);
  const [departmentServiceList, setDepartmentServiceList] = useState([]);
  const [disableFilters, setDisableFilters] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [clientBrandFilter, setClientBrandFilter] = useState("");
  const [deptServiceLineFilter, setDeptServiceLineFilter] = useState("");
  const [activeTab, setActiveTab] = useState(
    location && location.state && location.state.currentTab
      ? location.state.currentTab
      : "tab1"
  );
  const { validations } = useParContext();
  const { parAvailablePins } = useParContext();
  const { parPayRanges } = useParContext();
  const [userRole] = useState(
    window.userSession.parPermissions.permission_name
  );
  const clientBrandRef = useRef(clientBrandFilter);
  const departmentRef = useRef(deptServiceLineFilter);
  const tableContainerRef = useRef(null);

  // sort allocations to bring primary allocation on top
  const sortAllocations = async (allocations, clientBrandLob) => {
    let primaryClient = allocations;

    if (clientBrandLob) {
      primaryClient = allocations.sort((a, b) => {
        if (a.clientBrandLob === clientBrandLob) return -1;
        if (b.clientBrandLob === clientBrandLob) return 1;
        return 0;
      });
    }

    return primaryClient;
  };

  // creating object for react-table
  const createTableData = async (records, clientFilter) => {
    let recordData = [];
    const tableRecords = [...records];
    for (let i = 0; i < tableRecords.length; i++) {
      let element = records[i];
      let { allocations } = element;
      let mainObject = {};
      let subRowData = [];
      let arrClientBrandLob = [];
      let fte = [];

      mainObject = {
        mslPin: element.mslPin,
        effectiveDates: element.startDate + " - " + element.endDate,
        employeeName: element.employeeName,
        workdayTitle: element.workdayTitle,
        departmentService:
          element.summaryDepartment + " - " + element.serviceLineDepartment,
        pinStatus: element.pinStatus
      };

      if (allocations.length > 0) {
        allocations = await sortAllocations(allocations, clientFilter);

        for (let j = 0; j < allocations.length; j++) {
          let allocation = allocations[j];

          if (j === 0) {
            arrClientBrandLob.push({
              clientBrandLob: allocation.clientBrandLob,
              allocation: allocation.allocation
            });
          } else {
            arrClientBrandLob.push({
              clientBrandLob: allocation.clientBrandLob,
              allocation: allocation.allocation
            });
          }

          // pushing sum of allocations
          if (allocations.length === j + 1) {
            mainObject = {
              ...mainObject,
              allocations: parseFloat(allocation.sumOfAllocations).toFixed(2)
            };
          }
        }
      } else {
        fte.push(0);
      }

      subRowData.push({
        mslPin: element.pinStatus,
        effectiveDates: element.parStatus === "" ? "NO PAR" : element.parStatus,
        employeeName: element.employeeType !== null ? element.employeeType : "",
        workdayTitle: element.location,
        departmentService: "Profit Center - " + element.payrollProfitCenter,
        clientBrandLob: arrClientBrandLob,
        allocations: fte
      });

      mainObject = {
        ...mainObject,
        subRows: subRowData
      };

      recordData.push(mainObject);
    }

    return recordData;
  };

  const getAvailableMslPins = async (searchRequest = {}) => {
    try {
      setIsLoading(true);
      setDisableFilters(true);
      setAvailablePins([]);

      const response = await ParApi.getAvailablePins(searchRequest);
      if (response && response.length > 0) {
        const recordObjects = await createTableData(
          response,
          searchRequest.clientBrandFilter
        );
        setAvailablePins(recordObjects);
      }

      setIsLoading(false);
    } catch (ex) {
      toast.error(
        `There was an error in loading the msl pins - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
      setIsLoading(false);
    }
  };

  const handleSearchText = async (event) => {
    const value = event.target?.value.trim() || "";
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission

      if (value !== "") {
        getAvailableMslPins({
          textSearch: value,
          clientBrandFilter,
          deptServiceLineFilter
        });
      } else {
        getAvailableMslPins({
          textSearch: "",
          clientBrandFilter,
          deptServiceLineFilter
        });
      }

      setTextSearch(value);
    }
  };

  const clearSearchText = async (searchText) => {
    if (searchText !== "") {
      getAvailableMslPins({
        textSearch: "",
        clientBrandFilter,
        deptServiceLineFilter
      });
    }
  };

  const getFilterOptions = async () => {
    try {
      let parPermissions = {};
      let parBrands = [];
      if (winUser && winUser.parPermissions) {
        parPermissions = winUser.parPermissions;
        if (
          parPermissions.permission_name ===
          ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter
        ) {
          parBrands = parPermissions.field_value.map((item) => item) || [];
        }
      }
      let filteredclientBrand = validations.clientList.filter((item) =>
        parBrands.includes(item?.split("-")[1]?.trim())
      );

      let clientBrand =
        filteredclientBrand.length > 0
          ? filteredclientBrand
          : validations.clientList;

      if (validations) {
        setClientBrandList(clientBrand);
        const departmentService = validations.serviceLineDepartmentList
          .filter((item) => item.parentValue)
          .map((item) => item.parentValue + " - " + item.value);
        setDepartmentServiceList(departmentService);
      }
    } catch (ex) {
      toast.error(
        `There was an error in loading filters data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
      setIsLoading(false);
    }
  };

  const navigateToCreatePAR = () => {
    navigate("/paredit", {
      state: {
        validations,
        parAvailablePins,
        parPayRanges,
        workflowType: {
          workflow_id: 1,
          name: ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST
        },
        activeTab
      }
    });
  };

  const handleWorkflowMenuClick = (event, workflowType, mslData) => {
    // TODO: Remove this if condition once we implement other workflows
    if (
      workflowType.name === ParConstants.PAR_WORKFLOW.URGENT_COUNTER_OFFER ||
      workflowType.name === ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST ||
      workflowType.name === ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH ||
      workflowType.name === ParConstants.PAR_WORKFLOW.BACKFILL_REQUEST ||
      workflowType.name === ParConstants.PAR_WORKFLOW.PROMOTION_IN_PLACE ||
      workflowType.name === ParConstants.PAR_WORKFLOW.REALLOCATION_ACTIVE ||
      workflowType.name === ParConstants.PAR_WORKFLOW.SALARY_ADJUSTMENT ||
      workflowType.name === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH
    ) {
      navigate("/paredit", {
        state: {
          mslData,
          workflowType,
          validations,
          parAvailablePins,
          parPayRanges,
          activeTab
        }
      });
    }
  };

  const exportPARStaffPlans = async () => {
    let filePath = "";
    try {
      const response = await ParApi.exportPARStaffPlans({
        clientBrandFilter,
        deptServiceLineFilter
      });

      // Get full url
      if (response && response.downloadURL) {
        filePath = response.downloadURL;
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }

    await window.open(`${filePath}`, "_blank");
  };

  const toggleExportButton = () => {
    if (!deptServiceLineFilter && !clientBrandFilter) {
      return true;
    }
    if (isLoading) {
      return true;
    }
    if (availablePins.length === 0) {
      return true;
    }
    return false;
  };

  const parQueueSelected = () => activeTab === "tab2";

  useEffect(() => {
    // on filter selection call method getAvailableMslPins
    if (
      clientBrandFilter !== clientBrandRef.current ||
      deptServiceLineFilter !== departmentRef.current
    ) {
      getAvailableMslPins({
        textSearch,
        clientBrandFilter,
        deptServiceLineFilter
      });
    }

    // ref to identify if selection in filter dropdowns is changed
    clientBrandRef.current = clientBrandFilter;
    departmentRef.current = deptServiceLineFilter;
  }, [clientBrandFilter, deptServiceLineFilter]);

  useEffect(async () => {
    // get the filter data on initial load and construct staff plans object
    getFilterOptions();
    if (parAvailablePins && parAvailablePins.length > 0) {
      setIsLoading(true);
      setDisableFilters(true);
      setAvailablePins([]);
      const recordObjects = await createTableData(parAvailablePins, {});
      setAvailablePins(recordObjects);
      setIsLoading(false);
    } else {
      getAvailableMslPins();
    }
  }, []);

  useEffect(() => {
    // if no data is available then disable the filters
    if (isLoading) {
      setDisableFilters(true);
    } else {
      setDisableFilters(false);
    }
  }, [isLoading]);

  return (
    <div className="parLandingContainer">
      <Formik initialValues={{ searchText: "" }}>
        {(formikProps) => (
          <Form>
            <div className="row msg-row">
              <div>
                <div className="mainContainer">
                  <div className="initialContainer">
                    <div className="initialText">
                      {String.getInitials(winUser?.name)}
                    </div>
                  </div>
                  <div className="welcomeContainer">
                    <div className="welcomeMsg">Welcome, {winUser.name}</div>
                  </div>
                </div>
              </div>
              {userRole ===
                ParConstants.PAR_PERMISSIONS_MAPPING
                  .PAR_ClientBrandSubmitter && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btnAddToStaff"
                    onClick={navigateToCreatePAR}
                  >
                    Add To Staff
                  </button>
                </div>
              )}
            </div>
            {userRole ===
              ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter &&
              activeTab === "tab1" && (
                <div className="filterRow">
                  <div className="searchContainer mr-3">
                    <div className="searchIcon">
                      <i
                        className="fas fa-search fa-lg"
                        style={{ color: "#d6d6d8" }}
                      />
                    </div>
                    <div className="searchInput">
                      <Field
                        type="text"
                        name="searchText"
                        className="form-control textSearchField"
                        aria-label="searchText"
                        onKeyPress={handleSearchText}
                        placeholder="Search"
                        disabled={disableFilters}
                      />
                    </div>
                    <div className="closeIcon">
                      <div
                        className="clearBtn"
                        onClick={() => {
                          clearSearchText(formikProps.values.searchText);
                          formikProps.setFieldValue("searchText", "");
                        }}
                      >
                        <i
                          className="fas fa-times fa-xs"
                          style={{ color: "#d6d6d8" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="clientContainer mr-3">
                    <TypeAheadBoxField
                      formikProps={formikProps}
                      suggestions={clientBrandList}
                      fieldName="clientBrand"
                      placeholder="Client - Brand"
                      label="Client - Brand"
                      onValueChange={(value) => setClientBrandFilter(value)}
                      isOptional
                      disable={disableFilters}
                    />
                  </div>
                  <div className="departmentContainer mr-3">
                    <TypeAheadBoxField
                      formikProps={formikProps}
                      suggestions={departmentServiceList}
                      fieldName="deparmentService"
                      label="Department - Service"
                      placeholder="Department - Service"
                      onValueChange={(value) => setDeptServiceLineFilter(value)}
                      disable={disableFilters}
                      isOptional
                    />
                  </div>
                  <div className="exportBtnContainer">
                    <button
                      type="button"
                      className="btn btn-primary btnExportFile"
                      disabled={toggleExportButton()}
                      onClick={exportPARStaffPlans}
                    >
                      Export File{" "}
                      <img
                        alt=""
                        src={
                          toggleExportButton()
                            ? spreadhseetdisabled
                            : spreadhseet
                        }
                      />
                    </button>
                  </div>
                </div>
              )}
          </Form>
        )}
      </Formik>
      {userRole ===
        ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter && (
        <div
          ref={appRef}
          className={`full-height ${
            parQueueSelected() ? "par-queue-parent" : ""
          }`}
        >
          <div className="tab-menu tabRow">
            <button
              type="button"
              className={activeTab === "tab1" ? "tab selected" : "tab"}
              onClick={() => {
                setActiveTab("tab1");
                setAvailablePins([]);
                getAvailableMslPins({
                  textSearch,
                  clientBrandFilter,
                  deptServiceLineFilter
                });
              }}
            >
              Staff Plans
            </button>
            <button
              type="button"
              className={parQueueSelected() ? "tab selected" : "tab"}
              onClick={() => setActiveTab("tab2")}
            >
              PAR Submissions
            </button>
          </div>

          <div
            ref={tableContainerRef}
            className={`tab-container ${
              parQueueSelected() ? "table-container" : "staff-table-container"
            }`}
          >
            {activeTab === "tab1" && (
              <>
                {isLoading && (
                  <LoadingSpinner
                    status="Loading"
                    textMessage="Please wait, staff plan is being loaded..."
                  />
                )}

                {!isLoading && availablePins.length === 0 && (
                  <LoadingSpinner
                    status="NoData"
                    textMessage="No PINs available for this selection"
                  />
                )}

                {availablePins.length > 0 && (
                  <ParStaffTable
                    data={availablePins}
                    handleClick={handleWorkflowMenuClick}
                    tableRef={tableContainerRef}
                  />
                )}
              </>
            )}
            {parQueueSelected() && (
              <ParQueue
                parAvailablePins={parAvailablePins}
                parPayRanges={parPayRanges}
                activeTab={activeTab}
              />
            )}
          </div>
        </div>
      )}
      {userRole !==
        ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter && (
        <div ref={appRef} className="full-height par-queue-parent">
          {(userRole === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_RM ||
            userRole === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_HR) && (
            <>
              <div className="tab-menu tabRow">
                <button
                  type="button"
                  className={activeTab === "tab1" ? "tab selected" : "tab"}
                  onClick={() => setActiveTab("tab1")}
                >
                  Intake
                </button>
                <button
                  type="button"
                  className={parQueueSelected() ? "tab selected" : "tab"}
                  onClick={() => setActiveTab("tab2")}
                >
                  Action
                </button>
                <button
                  type="button"
                  className={activeTab === "tab3" ? "tab selected" : "tab"}
                  onClick={() => setActiveTab("tab3")}
                >
                  PAR Submissions
                </button>
              </div>
              <div className="tab-container table-container">
                {activeTab === "tab1" && (
                  <ParQueue
                    parAvailablePins={parAvailablePins}
                    parPayRanges={parPayRanges}
                    queueType={ParConstants.PAR_INTAKE_QUEUE}
                    activeTab={activeTab}
                  />
                )}
                {activeTab === "tab2" && (
                  <ParQueue
                    parAvailablePins={parAvailablePins}
                    parPayRanges={parPayRanges}
                    queueType={ParConstants.PAR_ACTION_QUEUE}
                    activeTab={activeTab}
                  />
                )}
                {activeTab === "tab3" && (
                  <ParQueue
                    parAvailablePins={parAvailablePins}
                    parPayRanges={parPayRanges}
                    queueType={ParConstants.PAR_SUBMISSIONS_QUEUE}
                    activeTab={activeTab}
                  />
                )}
              </div>
            </>
          )}
          {userRole !== ParConstants.PAR_PERMISSIONS_MAPPING.PAR_RM &&
            userRole !== ParConstants.PAR_PERMISSIONS_MAPPING.PAR_HR && (
              <>
                <div className="tabRow">
                  <button
                    type="button"
                    className={activeTab === "tab1" ? "tab selected" : "tab"}
                    onClick={() => setActiveTab("tab1")}
                  >
                    PAR Tickets
                  </button>
                  <button
                    type="button"
                    className={activeTab === "tab2" ? "tab selected" : "tab"}
                    onClick={() => setActiveTab("tab2")}
                  >
                    PAR Submissions
                  </button>
                </div>
                <div className="tab-container table-container">
                  {activeTab === "tab1" && (
                    <ParQueue
                      parAvailablePins={availablePins}
                      parPayRanges={parPayRanges}
                      queueType={ParConstants.PAR_REGULAR_QUEUE}
                      activeTab={activeTab}
                    />
                  )}
                  {activeTab === "tab2" && (
                    <ParQueue
                      parAvailablePins={parAvailablePins}
                      parPayRanges={parPayRanges}
                      queueType={ParConstants.PAR_SUBMISSIONS_QUEUE}
                      activeTab={activeTab}
                    />
                  )}
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
};

export default ParLandingPage;
