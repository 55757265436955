import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";

import Constants from "../../../common/Constants";
import ParConstants from "../../../common/ParConstants";

import api from "../../../services/ParApi";

const ParRequestList = (input, queueType) => {
  const [uiState, setUIState] = useState(Constants.UI_STATE_SUCCESS);
  const [parRequestTableData, setParRequestTableData] = useState([]);

  const getParRequestList = useCallback(async (inputObj, queueType) => {
    try {
      setUIState(Constants.UI_STATE_LOADING);
      const res = await api.getParRequestList(inputObj);
      if (res.data) {
        if (res.data.length) {
          setUIState(Constants.UI_STATE_SUCCESS);
          let finalData = res.data;
          // Show only the requests that are in the queue
          // Rest of the requests (with show_in_par_submissions = true)
          // will go in Queue Specific PAR Submissions TAB

          if (queueType === ParConstants.PAR_SUBMISSIONS_QUEUE) {
            finalData = res.data.filter((r) => r.show_in_par_submissions);
          } else {
            finalData = res.data.filter((r) => !r.show_in_par_submissions);
            // For Action and Intake Queues only return data corresponding to those queues
            // Only applicable for HR and RM Types
            if (
              queueType === ParConstants.PAR_ACTION_QUEUE ||
              queueType === ParConstants.PAR_INTAKE_QUEUE
            ) {
              finalData = finalData.filter(
                (d) => d.current_queue && d.current_queue.includes(queueType)
              );
            }
          }

          setParRequestTableData(finalData);
        } else {
          setUIState(Constants.UI_STATE_EMPTY);
          setParRequestTableData([]);
        }
      } else {
        setUIState(Constants.UI_STATE_ERROR);
        toast.error(
          "There was an error in fetching PAR Request List",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      setUIState(Constants.UI_STATE_ERROR);
      // Ignore the exception, this means we did not get the data
      toast.error(
        `There was an error in fetching PAR Request List - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  }, []);

  useEffect(() => {
    getParRequestList(input, queueType);
  }, []);

  return {
    parRequestTableData,
    uiState,
    refresParRequestList: getParRequestList
  };
};

export default ParRequestList;
