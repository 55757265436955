import React from "react";
import PropTypes from "prop-types";
import AutoComplete from "./AutoComplete";

const TypeAheadBoxField = ({
  formikProps,
  suggestions,
  fieldName,
  label,
  showDescription,
  hideHeader,
  isOptional,
  showCreateNewOption,
  clearUserAddedValidations,
  setUserAddedValidationsValue,
  disable,
  updateChildDropdown,
  placeholder,
  isParent,
  onValueChange,
  isMultiSelect,
  isKeyValueProvided,
  hideDropdownArrow,
  createNewOptionMsg,
  menuPlacement
}) => {
  // Previously, TypeAheadBoxField was accepting an array of values for both label and value.
  // This change adds `isKeyValueProvided` so it will now accept label-value pairs [{label: label, value: value}].
  const finalSuggestions = suggestions
    ? suggestions.map((s) => ({
        label: isKeyValueProvided ? s.label : s,
        value: isKeyValueProvided ? s.value : s
      }))
    : [];

  return (
    <>
      {!hideHeader && (
        <label htmlFor={fieldName}>
          {label}
          {!isOptional && <span className="red-text">&nbsp;*</span>}
        </label>
      )}
      {!showDescription && (
        <AutoComplete
          label={label}
          showDescription={showDescription}
          name={fieldName}
          options={finalSuggestions}
          menuPlacement={menuPlacement}
          clearUserAddedValidations={() => {
            clearUserAddedValidations(fieldName);
          }}
          handleCreate={(value) => {
            if (isMultiSelect === true) {
              let prevVal = formikProps.values[fieldName] || [];
              formikProps.setFieldValue(fieldName, [
                ...prevVal,
                {
                  label: value.trim(),
                  value: value.trim()
                }
              ]);
            } else {
              formikProps.setFieldValue(fieldName, {
                label: value.trim(),
                value: value.trim()
              });
            }
            setUserAddedValidationsValue(fieldName, value);
          }}
          showCreateNewOption={showCreateNewOption}
          disable={disable}
          updateChildDropdown={(value) => {
            updateChildDropdown(value);
          }}
          placeholder={placeholder}
          isParent={isParent}
          onValueChange={onValueChange}
          isMultiSelect={isMultiSelect}
          hideDropdownArrow={hideDropdownArrow}
          createNewOptionMsg={createNewOptionMsg}
        />
      )}

      {showDescription && (
        <div id={`${fieldName}-description`} className="form-description">
          <strong>
            {fieldName.indexOf(".") === -1
              ? isMultiSelect
                ? formikProps?.values[fieldName]?.map((x) => (
                    <div className="chip" key={x.value}>
                      {x.value}
                    </div>
                  ))
                : formikProps?.values[fieldName]?.value
              : formikProps?.values[fieldName.split(".")[0]][
                  fieldName.split(".")[1]
                ][fieldName.split(".")[2]]?.value}
          </strong>
        </div>
      )}
    </>
  );
};

TypeAheadBoxField.propTypes = {
  suggestions: PropTypes.instanceOf(Array).isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  hideHeader: PropTypes.bool,
  isOptional: PropTypes.bool,
  showDescription: PropTypes.bool,
  showCreateNewOption: PropTypes.bool,
  clearUserAddedValidations: PropTypes.func,
  setUserAddedValidationsValue: PropTypes.func,
  disable: PropTypes.bool,
  updateChildDropdown: PropTypes.func,
  placeholder: PropTypes.string,
  isParent: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  isKeyValueProvided: PropTypes.bool,
  hideDropdownArrow: PropTypes.bool,
  createNewOptionMsg: PropTypes.string,
  menuPlacement: PropTypes.string
};

TypeAheadBoxField.defaultProps = {
  isOptional: false,
  hideHeader: false,
  showCreateNewOption: false,
  showDescription: false,
  setUserAddedValidationsValue: () => {},
  clearUserAddedValidations: () => {},
  disable: false,
  updateChildDropdown: () => {},
  placeholder: "",
  isParent: false,
  onValueChange: () => {},
  isMultiSelect: false,
  isKeyValueProvided: false,
  hideDropdownArrow: false,
  createNewOptionMsg: "",
  menuPlacement: "auto"
};

export default TypeAheadBoxField;
