import React, { useMemo } from "react";
import PropTypes from "prop-types";
import CollapsibleCard from "../../shared/CollapsibleCard";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import RapConstants from "../../../common/RapConstants";
import ParConstants from "../../../common/ParConstants";

const ReallocationInstructions = ({
  formikProps,
  canModifyPARRecords,
  showFieldsReadOnly,
  validations,
  workflowName,
  availablePINs
}) => {
  const employeeType = formikProps?.values?.employee_type;
  const filteredReallocationReasons = useMemo(() => {
    let response = [];
    if (!employeeType) return [];
    if (workflowName === ParConstants.PAR_WORKFLOW.REALLOCATION_ACTIVE) {
      response = ParConstants.REALLOCATION_REASONS.filter(
        (x) =>
          x === ParConstants.REALLOCATION_REASONS[5] ||
          x === ParConstants.REALLOCATION_REASONS[6]
      );
    }
    if (workflowName === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH) {
      response =
        employeeType.value === RapConstants.EMPLOYEE_TYPE_VALUES[1]
          ? ParConstants.REALLOCATION_REASONS.filter(
              (x) =>
                x !== ParConstants.REALLOCATION_REASONS[3] &&
                x !== ParConstants.REALLOCATION_REASONS[5] &&
                x !== ParConstants.REALLOCATION_REASONS[6]
            )
          : ParConstants.REALLOCATION_REASONS.filter(
              (x) =>
                x !== ParConstants.REALLOCATION_REASONS[2] &&
                x !== ParConstants.REALLOCATION_REASONS[5] &&
                x !== ParConstants.REALLOCATION_REASONS[6]
            );
    }
    return response;
  }, [employeeType]);

  const filteredEmployeeType = useMemo(() => {
    if (!employeeType) return [];
    return employeeType.value === RapConstants.EMPLOYEE_TYPE_VALUES[1]
      ? RapConstants.EMPLOYEE_TYPE_VALUES.filter(
          (x) => x !== RapConstants.EMPLOYEE_TYPE_VALUES[1]
        )
      : RapConstants.EMPLOYEE_TYPE_VALUES.filter(
          (x) => x === RapConstants.EMPLOYEE_TYPE_VALUES[1]
        );
  }, [employeeType]);

  const filteredLevelList = useMemo(() => {
    if (
      formikProps.values.reallocation_level &&
      formikProps.values.reallocation_level.value
    ) {
      let index = validations.levelList.indexOf(
        formikProps.values.reallocation_level.value
      );
      if (!formikProps.values.reallocation_reason) return [];
      if (
        formikProps.values.reallocation_reason.value ===
        ParConstants.REALLOCATION_REASONS[0]
      ) {
        return validations.levelList.slice(0, index);
      }
      if (
        formikProps.values.reallocation_reason.value ===
        ParConstants.REALLOCATION_REASONS[1]
      ) {
        return validations.levelList.slice(
          index + 1,
          validations.levelList.length
        );
      }
    }
    return [];
  }, [formikProps.values.reallocation_reason?.value]);

  return (
    <CollapsibleCard
      className="highlighted-panel"
      title="Reallocation Instructions"
    >
      <div className="row">
        <div className="col-md-3 mt-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={filteredReallocationReasons}
            fieldName="reallocation_reason"
            placeholder="Reallocation Reason"
            label="Reallocation Reason"
            disable={!canModifyPARRecords}
            showDescription={showFieldsReadOnly.reallocation_reason}
          />
        </div>
        {formikProps.values.reallocation_reason &&
          (formikProps.values.reallocation_reason.value ===
            ParConstants.REALLOCATION_REASONS[2] ||
            formikProps.values.reallocation_reason.value ===
              ParConstants.REALLOCATION_REASONS[3]) && (
            <div className="mt-3 col-md-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={filteredEmployeeType}
                fieldName="reallocation_employee_type"
                placeholder="Employee Type"
                label="Employee Type"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.reallocation_employee_type}
              />
            </div>
          )}
        {formikProps.values.reallocation_reason &&
          (formikProps.values.reallocation_reason.value ===
            ParConstants.REALLOCATION_REASONS[0] ||
            formikProps.values.reallocation_reason.value ===
              ParConstants.REALLOCATION_REASONS[1]) && (
            <div className="mt-3 col-md-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={filteredLevelList}
                fieldName="reallocation_level"
                placeholder="Level"
                label="Level"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.reallocation_level}
              />
            </div>
          )}
        {formikProps.values.reallocation_reason &&
          formikProps.values.reallocation_reason.value ===
            ParConstants.REALLOCATION_REASONS[4] && (
            <div className="mt-3 col-md-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={validations.serviceLineDepartmentList
                  .filter(
                    (x) =>
                      x.parentValue ===
                        formikProps.values.summary_department.value &&
                      x.value !==
                        formikProps.values.serviceline_department.value
                  )
                  .map((x) => x.value)}
                fieldName="reallocation_serviceline_department"
                placeholder="Service Line Department"
                label="Service Line Department"
                disable={!canModifyPARRecords}
                showDescription={
                  showFieldsReadOnly.reallocation_serviceline_department
                }
              />
            </div>
          )}
        {formikProps.values.reallocation_reason &&
          workflowName === ParConstants.PAR_WORKFLOW.REALLOCATION_ACTIVE && (
            <div className="col-md-3 mt-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={availablePINs.map((x) => x.employeeName)}
                fieldName="reallocation_reassign_allocation_to"
                placeholder="Reassign Allocation to"
                label="Reassign Allocation to"
                disable={!canModifyPARRecords}
                showDescription={
                  showFieldsReadOnly.reallocation_reassign_allocation_to
                }
              />
            </div>
          )}

        {formikProps.values.reallocation_reason && (
          <div className="col-md-3 mt-3">
            <DatePickerBoxField
              className="ml-0"
              formikProps={formikProps}
              label="Target Effective Date"
              fieldName="target_effective_date"
              disable={!canModifyPARRecords}
              showDescription={showFieldsReadOnly.target_effective_date}
            />
          </div>
        )}
      </div>
    </CollapsibleCard>
  );
};

ReallocationInstructions.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      reallocation_reason: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      reallocation_level: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      reallocation_employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      reallocation_serviceline_department: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      par_level: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      summary_department: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      serviceline_department: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    errors: PropTypes.any,
    initialValues: PropTypes.shape({}),
    setFieldValue: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  hideFields: PropTypes.shape({}),
  showFieldsReadOnly: PropTypes.shape({
    reallocation_reason: PropTypes.bool,
    reallocation_employee_type: PropTypes.bool,
    reallocation_level: PropTypes.bool,
    reallocation_serviceline_department: PropTypes.bool,
    target_effective_date: PropTypes.bool,
    reallocation_reassign_allocation_to: PropTypes.bool
  }),
  workflowName: PropTypes.string,
  availablePINs: PropTypes.instanceOf(Array),
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array),
    parGeoRange: PropTypes.instanceOf(Array)
  })
};

ReallocationInstructions.defaultProps = {
  canModifyPARRecords: false,
  showFieldsReadOnly: {
    reallocation_reason: false,
    reallocation_employee_type: false,
    reallocation_level: false,
    reallocation_serviceline_department: false,
    target_effective_date: false,
    reallocation_reassign_allocation_to: false
  },
  workflowName: "",
  availablePINs: [],
  validations: {},
  hideFields: {}
};

export default ReallocationInstructions;
