import React, { useMemo } from "react";
import PropTypes from "prop-types";

import CollapsibleCard from "../../shared/CollapsibleCard";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import TextBoxField from "../../shared/TextBoxField";
import Tooltip from "../../shared/Tooltip";
import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";
import string from "../../../helpers/String";
import helpers, {
  getPercentageIncrease,
  isGlobalEmployee
} from "../../../helpers/ParHelpers";

const EmployeeDetails = ({
  formikProps,
  canModifyPARRecords,
  showFieldsReadOnly,
  hideFields,
  parGeoRange,
  parPayRanges,
  workflowName,
  parType
}) => {
  const parLevel = formikProps?.values?.par_level;

  const filteredPayRanges = useMemo(() => {
    if (!parLevel) return [];
    // Extract numeric part from par_level
    const numericLevel = parLevel?.value.match(/\d+/)?.[0];
    return numericLevel
      ? parPayRanges.filter((range) => range.level.includes(numericLevel))
      : [];
  }, [parLevel]);

  const getEmployeeName = (employeeName) => {
    let name = "";
    if (!string.isNullOrEmptyOrUndefined(employeeName)) {
      let splitName = employeeName.split("-");

      if (splitName.length > 0) {
        [name] = splitName;
      }
    }

    return name;
  };
  const isSalaryAdjustmentWorkflow =
    workflowName === ParConstants.PAR_WORKFLOW.SALARY_ADJUSTMENT;

  const titleText = isSalaryAdjustmentWorkflow
    ? "SALARY ADJUSTMENT INSTRUCTIONS"
    : "Counter Offer Instructions";

  const isFieldRequired = () =>
    formikProps?.values?.current_queue_name ===
      ParConstants.PAR_QUEUE.HR_INTAKE &&
    !isGlobalEmployee(formikProps?.values?.employee_type?.value);

  return (
    <CollapsibleCard title="Employee Details">
      <>
        {workflowName === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH ? (
          <div className="row">
            <div className="col-md-2 mt-3">
              <TypeAheadBoxField
                className="ml-1"
                suggestions={RapConstants.EMPLOYEE_TYPE_VALUES}
                formikProps={formikProps}
                fieldName="employee_type"
                label="Employee Type"
                disable={!canModifyPARRecords}
                isOptional
                showDescription
              />
            </div>
          </div>
        ) : (
          <>
            <div className="row employee-details">
              <div className="col-md-10 mt-3">
                <div className="row par-table par-table-highlight ml-0">
                  <div className="col-md-3">
                    <strong>Employee Name</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Workday ID</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Type</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Location</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row par-table  par-table-content ml-0">
                  <div className="col-md-3">
                    <strong>
                      {getEmployeeName(
                        formikProps.values?.employee_name?.value
                      )}
                    </strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_workdayid}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_type?.value}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>
                      {formikProps.values?.employee_location?.value}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {!hideFields.current_base_salary && (
                <div className="col-md-2 mt-3">
                  <TextBoxField
                    className="ml-1"
                    type="number"
                    formikProps={formikProps}
                    fieldName="current_base_salary"
                    label="Current Base Salary"
                    disable={!canModifyPARRecords}
                    isOptional={!isFieldRequired()}
                    showDescription={showFieldsReadOnly.current_base_salary}
                  />
                </div>
              )}
              {!hideFields.date_of_last_compensation_change && (
                <div className="col-md-2 mt-3">
                  <DatePickerBoxField
                    className="ml-0"
                    formikProps={formikProps}
                    label="Date Of Last Compensation Change"
                    isOptional={!isFieldRequired()}
                    fieldName="date_of_last_compensation_change"
                    disable={!canModifyPARRecords}
                    showDescription={
                      showFieldsReadOnly.date_of_last_compensation_change
                    }
                  />
                </div>
              )}
              {!hideFields.date_of_lastpromotion && (
                <div className="col-md-2 mt-3">
                  <DatePickerBoxField
                    className="ml-0"
                    formikProps={formikProps}
                    label="Date Of Last Promotion"
                    isOptional={!isFieldRequired()}
                    fieldName="date_of_lastpromotion"
                    disable={!canModifyPARRecords}
                    showDescription={showFieldsReadOnly.date_of_lastpromotion}
                  />
                </div>
              )}
              {!hideFields.current_performance_rating && (
                <div className="col-md-2 mt-3">
                  <TypeAheadBoxField
                    formikProps={formikProps}
                    suggestions={ParConstants.PERFORMANCE_RATINGS}
                    fieldName="current_performance_rating"
                    label="Current Performance Rating"
                    disable={!canModifyPARRecords}
                    isOptional={!isFieldRequired()}
                    showDescription={
                      showFieldsReadOnly.current_performance_rating
                    }
                  />
                </div>
              )}
            </div>
            {parType?.name !== ParConstants.PAR_WORKFLOW.PROMOTION_IN_PLACE &&
              workflowName !==
                ParConstants.PAR_WORKFLOW.REALLOCATION_ACTIVE && (
                <CollapsibleCard
                  className="highlighted-panel"
                  title={titleText}
                >
                  <div className="row">
                    {!hideFields.requested_offer && (
                      <div className="col-md-2 mt-3">
                        <TextBoxField
                          className="ml-1"
                          type="number"
                          formikProps={formikProps}
                          fieldName="requested_offer"
                          label="Requested Offer"
                          disable={!canModifyPARRecords}
                          isOptional={!isFieldRequired()}
                          showDescription={showFieldsReadOnly.requested_offer}
                        />
                      </div>
                    )}

                    {!hideFields.target_effective_date && (
                      <div className="col-md-2 mt-3">
                        <DatePickerBoxField
                          className="ml-0"
                          formikProps={formikProps}
                          label="Target Effective Date"
                          fieldName="target_effective_date"
                          disable={!canModifyPARRecords}
                          showDescription={
                            showFieldsReadOnly.target_effective_date
                          }
                        />
                      </div>
                    )}
                    {!hideFields.job_code &&
                      formikProps.values.employee_type?.value !==
                        RapConstants.EMPLOYEE_TYPE_VALUES[1] && (
                        <div className="mt-3 col-md-3">
                          <TypeAheadBoxField
                            formikProps={formikProps}
                            suggestions={
                              filteredPayRanges?.map((x) => x.job_profile) || []
                            }
                            fieldName="job_code"
                            placeholder="Job Profile"
                            label="Job Profile"
                            disable={!canModifyPARRecords}
                            showDescription={showFieldsReadOnly.job_code}
                          />
                        </div>
                      )}

                    {!hideFields.percent_increase && (
                      <div className="mt-3 col-md-2">
                        <div className="topTooltipContainer ml-1">
                          <div className="textLabel">Percent Increase</div>
                          <div className="tooltip-cell">
                            <Tooltip
                              className="tooltip-content"
                              text="Please enter the Requested Offer and Current Base Salary to see the Percent Increase value"
                            >
                              <i className="fas fa-info-circle fa-lg info-color" />
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          id="percent_increase"
                          className="form-description mt-2 ml-1"
                        >
                          <strong>
                            {getPercentageIncrease(
                              formikProps.values.current_base_salary,
                              formikProps.values.requested_offer
                            )}
                          </strong>
                        </div>
                      </div>
                    )}
                    {formikProps.values?.employee_type &&
                      formikProps.values.employee_type?.value !==
                        RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                      !hideFields.compa_ratio_of_offer && (
                        <div className="mt-3 col-md-3">
                          <div className="ml-1">
                            <div className="topTooltipContainer">
                              <div className="textLabel">
                                Compa Ratio of Offer
                              </div>
                              <div className="tooltip-cell">
                                <Tooltip
                                  className="tooltip-content"
                                  text="Please Enter the Requested Offer and Select Job Profile to see this Calculated Ratio"
                                >
                                  <i className="fas fa-info-circle fa-lg info-color" />
                                </Tooltip>
                              </div>
                            </div>
                            <div
                              id="compa_ratio_of_offer"
                              className="form-description mt-2"
                            >
                              <strong>
                                {helpers.getCompRatioOfOffer(
                                  parGeoRange,
                                  parPayRanges,
                                  formikProps
                                )}
                              </strong>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </CollapsibleCard>
              )}
          </>
        )}
      </>
    </CollapsibleCard>
  );
};

EmployeeDetails.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      allocations: PropTypes.instanceOf(Array),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      current_queue_name: PropTypes.string,
      current_base_salary: PropTypes.string,
      requested_offer: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_name: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      par_level: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_location: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      client_lob: PropTypes.string
    }),
    errors: PropTypes.any,
    initialValues: PropTypes.shape({
      allocations: PropTypes.instanceOf(Array),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      client_lob: PropTypes.string
    }),
    setFieldValue: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  hideFields: PropTypes.shape({
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    current_performance_rating: PropTypes.bool,
    requested_offer: PropTypes.bool,
    target_effective_date: PropTypes.bool,
    compa_ratio_of_offer: PropTypes.bool,
    percent_increase: PropTypes.bool,
    job_code: PropTypes.bool
  }),
  showFieldsReadOnly: PropTypes.shape({
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    current_performance_rating: PropTypes.bool,
    requested_offer: PropTypes.bool,
    target_effective_date: PropTypes.bool,
    job_code: PropTypes.bool
  }),
  parPayRanges: PropTypes.instanceOf(Array),
  parGeoRange: PropTypes.instanceOf(Array),
  workflowName: PropTypes.string,
  parType: PropTypes.shape({
    name: PropTypes.string
  })
};

EmployeeDetails.defaultProps = {
  canModifyPARRecords: false,
  showFieldsReadOnly: {},
  hideFields: {},
  parPayRanges: [],
  parGeoRange: [],
  workflowName: "",
  parType: null
};

export default EmployeeDetails;
