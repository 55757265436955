import React, { useState, useMemo } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useOktaAuth, LoginCallback } from "@okta/okta-react";

import Constants from "./common/Constants";
import Cookies from "./helpers/Cookies";
import OktaLoginPage from "./OktaLoginPage";
import TopNav from "./components/TopNav";
import UploadPage from "./components/UploadPage";
import WorkflowList from "./components/WorkflowList/WorkflowList";
import ChangeLog from "./components/ChangeLog/ChangeLog";

import CTSSystemUploads from "./components/cost_to_serve/CTSSystemUploads";
import ScenarioEditRecord from "./components/cost_to_serve/ScenarioEditRecord";
import ScenarioListPage from "./components/cost_to_serve/ScenarioListPage";
import ManageScenarioPage from "./components/cost_to_serve/ManageScenarioPage";

import ParMainPage from "./components/par/ParMainPage";

import ProtectedRoute from "./ProtectedRoute";
import UserManager from "./components/users/UserManager";
import UserManagerAction from "./components/users/UserManagerAction";
import FinopsPermissions from "./components/permissions/FinopsPermissions";

import security from "./services/Security";
import packageJson from "../package.json";
import dentsuLogo from "./assets/logo.png";
import DataManagementForm from "./components/DataManagement/DataManagementForm";
import CountrySelectionModal from "./components/users/CountrySelectionModal";
import UserContext from "./store/UserContext";
import CreatePAR from "./components/par/create_par/CreatePAR";
import Sidebar from "./components/shared/Sidebar";
import Notification from "./components/par/notification/Notification";
import ParUploads from "./components/par/ParUploads";

const EmbedPage = () => {
  // this feels very 2003, but firefox's css zoom works very differently to chrome
  const myFoxIsOnFire =
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const isProd = process.env.NODE_ENV + "" === "production";
  // QA ReportID
  let reportID = "1f78d9bd-aa13-499b-a422-6d97f4931bec";
  if (isProd) {
    reportID = "f0f8e726-e5c0-4f7b-afcb-f3892e47adc0";
  }
  // iFrame the Power BI reports URL
  return (
    <div style={{ marginTop: myFoxIsOnFire ? "0" : "55px" }}>
      <iframe
        title="RAP Pivots - Count of Open Position Highest to Lowest"
        style={{
          width: "125vw",
          height: `calc( 125vh - ${myFoxIsOnFire ? "5" : "65"}px )`
        }}
        src={`https://app.powerbi.com/reportEmbed?reportId=${reportID}&autoAuth=true&ctid=6e8992ec-76d5-4ea5-8eae-b0c5e558749a`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

const ErrorPage = () => {
  const location = useLocation();
  const errorMessage = location.state && location.state.errorMessage;
  const errorTitle =
    (location.state && location.state.errorTitle) || "Login Error";
  return (
    <div id="main-display">
      <div className="row ml-2">
        <h4 className="red-text">{errorTitle}</h4>
      </div>
      <div className="row ml-2">
        <b>{errorMessage}</b>
      </div>
      <br />
      <div className="row ml-2">Please contact Finops support</div>
    </div>
  );
};

const getDefaultCountry = () =>
  JSON.parse(localStorage.getItem(Constants.FINOPS_COUNTRY) || "{}");

const BasePage = ({
  isAuthenticated,
  isSessionSet,
  oktaAuthConfig,
  setSession,
  isLoadingCountry
}) => {
  // Get the roles
  // const canFinOps = security.canFinOps();
  const canEditRAPData = security.canEditRAPData();
  const canViewRAPReports = security.canViewRAPReports();
  const canEditCTSData = security.canEditCTSData();
  const canLoadCTSData = security.canLoadCTSData();
  const canEditPARData = security.canEditPARData();
  const canUploadPARData = security.canUploadPARData();
  const isAdmin = security.isAdmin();

  return (
    <div id="main-display">
      <Routes>
        <Route
          path="/"
          element={
            canEditRAPData ? (
              <ProtectedRoute
                isLoggedIn={isAuthenticated}
                isSessionSet={isSessionSet}
                hasPermissions={canEditRAPData}
                routeName="Rap"
                oktaAuthConfig={oktaAuthConfig}
                setSession={setSession}
              >
                {!isLoadingCountry && <WorkflowList />}
              </ProtectedRoute>
            ) : isAuthenticated && isSessionSet && canEditPARData ? (
              <Navigate replace to="/par" />
            ) : isAuthenticated && isSessionSet && canLoadCTSData ? (
              <Navigate replace to="/scenarios" />
            ) : (
              <ProtectedRoute
                isLoggedIn={isAuthenticated}
                isSessionSet={isSessionSet}
                hasPermissions={canEditRAPData}
                routeName="FinOps"
                oktaAuthConfig={oktaAuthConfig}
                setSession={setSession}
              />
            )
          }
        />
        <Route
          exact
          path="/embed"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canViewRAPReports}
              routeName="RAP Reports"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <EmbedPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/par-uploads"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canUploadPARData}
              routeName="PAR Uploads"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <ParUploads />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/par"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canEditPARData}
              routeName="PAR Landing"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <ParMainPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/paredit"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canEditPARData}
              routeName="PAR Details"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <CreatePAR />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/paredit/:parRequestIdParam"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canEditPARData}
              routeName="PAR Details"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <CreatePAR />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/scenarios"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canLoadCTSData && canEditCTSData}
              routeName="CTS Scenario Load"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <ScenarioListPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/ctsupload"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canLoadCTSData && canEditCTSData}
              routeName="CTS Source Upload"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <ManageScenarioPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/ctsedit"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canLoadCTSData && canEditCTSData}
              routeName="CTS Source Upload"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <ScenarioEditRecord />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/system"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canLoadCTSData && canEditCTSData}
              routeName="CTS System Upload"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <CTSSystemUploads />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/user-manager"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={isAdmin}
              routeName="User Manager"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <UserManager />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/finops-permissions"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={isAdmin}
              routeName="FinOps Permissions"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <FinopsPermissions />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/user-manager/edit"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={isAdmin}
              routeName="User Manager"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <UserManagerAction action="Edit" />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/user-manager/create"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={isAdmin}
              routeName="User Manager"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <UserManagerAction action="Create" />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/data-management/"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canEditRAPData}
              routeName="Data Management"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <DataManagementForm />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/login"
          element={
            !isAuthenticated || !isSessionSet ? (
              <OktaLoginPage config={oktaAuthConfig} setSession={setSession} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route exact path="/error" element={<ErrorPage />} />
        <Route path="callback" element={LoginCallback} />
        <Route
          exact
          path="/upload"
          element={isAuthenticated ? <UploadPage /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/changeLog"
          element={
            isAuthenticated || isSessionSet ? (
              <ChangeLog versionNumber={packageJson.version} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </div>
  );
};

BasePage.propTypes = {
  oktaAuthConfig: PropTypes.shape({
    issuer: PropTypes.string,
    redirectUri: PropTypes.string,
    clientId: PropTypes.string
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSessionSet: PropTypes.bool.isRequired,
  setSession: PropTypes.func.isRequired,
  isLoadingCountry: PropTypes.bool.isRequired
};

const BaseRouter = ({ isSessionSet, oktaAuthConfig, setSession }) => {
  const [defaultCountry, setDefaultCountry] = useState(getDefaultCountry());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [hasNotification, setHasNotification] = useState(true);
  // Get the App Version No.
  const versionNumber = packageJson.version;
  const { authState } = useOktaAuth();
  let isAuthenticated = false;
  let isLoggedIn = false;

  if (authState && authState.isAuthenticated) {
    isAuthenticated = authState.isAuthenticated && isSessionSet;
    isLoggedIn = authState.isAuthenticated;
  } else {
    // Check for cookie
    const accessTokenVal = Cookies.getCookie(Constants.FINOPS_LOGIN_KEY);
    const accessTokenValAppend = Cookies.getCookie(
      Constants.FINOPS_LOGIN_KEY_APPEND
    );

    const loggedinUser = Cookies.getCookie(Constants.FINOPS_LOGIN_USER);
    if (accessTokenVal && accessTokenValAppend && loggedinUser) {
      isAuthenticated = isSessionSet;
      isLoggedIn = true;
    }
  }
  const contextValue = useMemo(
    () => ({ defaultCountry, setDefaultCountry, isOpenModal, setIsOpenModal }),
    [defaultCountry, isOpenModal]
  );

  const isLoadingCountry = () => {
    const isMultipleCountry = localStorage.getItem(
      Constants.FINOPS_MULTIPLE_COUNTRY
    );
    return isMultipleCountry === "true" && !defaultCountry?.countryid;
  };
  const onBellIconClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setHasNotification(false);
  };

  return (
    <UserContext.Provider value={contextValue}>
      <div className="app-content-wrapper">
        {isAuthenticated && (
          <TopNav
            hasNotification={hasNotification}
            onBellIconClick={onBellIconClick}
          />
        )}
        {isAuthenticated && isOpenModal && <CountrySelectionModal />}

        {isAuthenticated && (
          <Sidebar
            id="notification"
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          >
            <Notification onClose={() => setIsSidebarOpen(false)} />
          </Sidebar>
        )}

        <BasePage
          oktaAuthConfig={oktaAuthConfig}
          isAuthenticated={isLoggedIn}
          isSessionSet={isSessionSet}
          setSession={setSession}
          isLoadingCountry={isLoadingCountry()}
        />
        {isAuthenticated && (
          <div id="footer">
            <a href="/#/changeLog">
              <i>Version {versionNumber}</i>
            </a>

            <div className="float-right">
              <img
                src={dentsuLogo}
                height="15"
                className="img-responsive"
                alt="dentsu logo"
              />
            </div>
          </div>
        )}
      </div>
    </UserContext.Provider>
  );
};

BaseRouter.propTypes = {
  oktaAuthConfig: PropTypes.shape({
    issuer: PropTypes.string,
    redirectUri: PropTypes.string,
    clientId: PropTypes.string
  }).isRequired,
  isSessionSet: PropTypes.bool.isRequired,
  setSession: PropTypes.func.isRequired
};

export default BaseRouter;
