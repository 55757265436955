import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

const Sidebar = ({ isOpen, onClose, children, header, id }) => {
  const sidebarRef = useRef(null);

  // Close the sidebar when clicking outside of it
  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      (event.target.tagName !== "I" || id === "viewHistory")
    ) {
      if (isOpen) {
        onClose();
      }
    }
  };
  // Close the sidebar when back button is clicked
  const handlePopState = () => {
    if (isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("popstate", handlePopState);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("popstate", handlePopState);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isOpen]);
  if (!isOpen) return null;

  return (
    <div className="sidebar-overlay" onClick={onClose}>
      <div
        className="sidebar-content"
        ref={sidebarRef}
        onClick={(e) => e.stopPropagation()}
        id={id}
      >
        <div className="sidebar-header">
          <h5>{header}</h5>
          <div className="sidebar-close" onClick={onClose}>
            &times;
          </div>
        </div>
        <div className="sidebar-body">{children}</div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  header: PropTypes.string,
  id: PropTypes.string
};

Sidebar.defaultProps = {
  isOpen: false,
  onClose: () => {},
  children: <></>,
  header: "",
  id: ""
};

export default Sidebar;
