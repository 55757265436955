/*
 Common method used in PAR Components to get the Compa Ratio Offer 
 calculation based on requested offer and salary mid point
*/

import ParConstants from "../common/ParConstants";
import RapConstants from "../common/RapConstants";

const getCompRatioOfOffer = (parGeoRange, parPayRanges, formikProps) => {
  /* eslint-disable camelcase */
  const {
    employee_location,
    job_code,
    requested_offer,
    par_level,
    fill_location,
    approved_offer
  } = formikProps?.values || {};

  // Internall Fill => employee_location OR External Fill => fill_location
  const location = employee_location?.value || fill_location?.value;
  const level = par_level?.value?.match(/\d+/)?.[0];
  const jobCodeValue = job_code?.value;
  const offer_value = approved_offer || requested_offer;

  // Ensure all necessary fields are present before proceeding
  if (!location || !jobCodeValue || !requested_offer || !level) {
    return "";
  }

  // Find the corresponding geo range and pay range
  const geoRange = parGeoRange.find((x) => location.includes(x.parentValue));
  const parRange = parPayRanges.find(
    (parPayRange) =>
      parPayRange.job_profile === jobCodeValue &&
      parPayRange.level.includes(level)
  );
  /* eslint-enable camelcase */
  if (geoRange && parRange) {
    const marketMidpoints = {
      medium: parRange.mid_market_mid,
      high: parRange.high_market_mid,
      low: parRange.low_market_mid
    };

    const salaryMidPoint = parseFloat(marketMidpoints[geoRange.value] || 0);

    if (salaryMidPoint !== 0) {
      return parseFloat((parseFloat(offer_value) / salaryMidPoint).toFixed(2));
    }
  }
  return "";
};

export const isGlobalEmployee = (empType) =>
  empType && empType === RapConstants.EMPLOYEE_TYPE_VALUES[1];

export const isExternalFill = (fillType) =>
  fillType && fillType === ParConstants.FILL_TYPES[0];

export const getLevelInNumeric = (level) =>
  parseInt(level?.replace(/\D/g, ""), 10);

export const getPercentageIncrease = (currentSalary, requestedOffer) => {
  if (!currentSalary || !requestedOffer) return 0;

  const current = parseFloat(currentSalary);
  const requested = parseFloat(requestedOffer);

  if (isNaN(current) || isNaN(requested) || current <= 0) return 0;

  const percentageIncrease = ((requested - current) / current) * 100;
  return Number(percentageIncrease.toFixed(2));
};

export const isValidNumberWithCommas = (value) => {
  if (!value?.includes(",")) return true;
  const regex = /^\d{1,3}(,\d{3})*(\.\d+)?$/;
  return regex.test(value);
};

export default {
  getCompRatioOfOffer
};
