import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import Cookies from "./helpers/Cookies";
import Constants from "./common/Constants";
import ParConstants from "./common/ParConstants";

// Basic component with logout button
const OktaLogout = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const revokeToken = async () => {
    try {
      await oktaAuth.revokeAccessToken(authState.accessToken);
    } catch (error) {
      console.log("Error revoking token:", error);
    }
  };

  const logout = async () => {
    // Delete the custom cookies
    Cookies.setCookie("X-ats-application", "", null, true);
    Cookies.setCookie(Constants.FINOPS_SESSION_KEY, "", null, true);
    Cookies.setCookie(Constants.FINOPS_LOGIN_KEY, "", null, true);
    Cookies.setCookie(Constants.FINOPS_LOGIN_KEY_APPEND, "", null, true);
    Cookies.setCookie(Constants.FINOPS_LOGIN_USER, "", null, true);
    // get pageSize from localStorage before clearing
    const staffPageSize = localStorage.getItem(
      ParConstants.STAFF_PAGE_LOCAL_STORAGE
    );
    const parPageSize = localStorage.getItem(
      ParConstants.PAR_SUBMISSION_LOCAL_STORAGE
    );
    // Clear localStorage in case there is any
    localStorage.clear();
    // set pageSize back to the localStorage
    localStorage.setItem(ParConstants.STAFF_PAGE_LOCAL_STORAGE, staffPageSize);
    localStorage.setItem(
      ParConstants.PAR_SUBMISSION_LOCAL_STORAGE,
      parPageSize
    );

    await revokeToken();
  };

  return (
    <a
      onClick={logout}
      alt="Logout User"
      className="nav-logout"
      href=" "
      title="Logout"
    >
      <i className="fas fa-power-off" />
    </a>
  );
};

export default OktaLogout;
