import PropTypes from "prop-types";
import React from "react";

import { isBefore, isValid, format } from "date-fns";

import Modal from "react-modal";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Spinner from "../shared/Spinner";
import DatePickerBoxField from "../shared/DatePickerBoxField";
import TextBoxField from "../shared/TextBoxField";
import Collapsible from "../shared/CollapsePanel";
import TypeAheadBoxField from "../shared/TypeAheadBoxField";
import ButtonGroup from "../shared/ButtonGroup";

import RapConstants from "../../common/RapConstants";
import security from "../../services/Security";
import api from "../../services/RapApi";
import Constants from "../../common/Constants";

const filterFieldNames = [
  "clientBrand",
  "familyName",
  "givenName",
  "mslPin",
  "workdayTitle",
  "workdayId",
  "startDate",
  "endDate",
  "allocations",
  "changeReason",
  "payrollProfitCenter",
  "status"
];

const FORM_FIELDS = {
  ...RapConstants.FORM_FIELDS,
  startDateOriginal: "startDateOriginal",
  endDateOriginal: "endDateOriginal"
};

const LIST_FIELDS = [
  ...RapConstants.LIST_FIELDS,
  "mslPin",
  "payrollProfitCenter"
];

const disableFields = true; // flag to disable fields showing original data

class PinReAllocate extends React.Component {
  constructor(props) {
    super(props);
    this.focused = {};
    this.state = {
      screenNumber: 1,
      userAddedValidations: [],
      isSubmitting: false,
      clientLobList: []
    };

    this.canModifyRAPRecords = security.canModifyRapRecords(); // To disable fields for non-authorized users
    this.changeReasonInput = React.createRef();
    this.abuseFormikProps = null;
  }

  updateScreenCount = () => {
    this.setState(() => ({
      screenNumber: this.state.screenNumber + 1
    }));
  };

  backScreen = () => {
    this.setState(() => ({
      screenNumber: this.state.screenNumber - 1
    }));
  };

  getValidationSchema = () => {
    const errorMsg = "Please enter a value";
    const errorMsgSelect = "Please select a value";
    const schemaObj = {};
    Object.keys(FORM_FIELDS).forEach((field) => {
      if (!schemaObj[field]) {
        if (field === "allocations") {
          // Only validatate client and allocation, and do not validate the fields
          schemaObj[field] = Yup.array().of(
            Yup.object().shape({
              status: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect),
                  value: Yup.string().required(errorMsgSelect)
                })
                .required(errorMsgSelect),
              allocation: Yup.number().when("status", (statusVal, schema) => {
                // Only apply the required allocation rule for Active allocations, skip for eliminated
                if (
                  !statusVal ||
                  statusVal.value?.toLowerCase() !== "eliminated"
                ) {
                  return Yup.number()
                    .typeError("Allocation should be a number")
                    .required(errorMsg);
                }
                return schema;
              }),
              client: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect),
                  value: Yup.string().required(errorMsgSelect)
                })
                .required(errorMsgSelect),
              rateCard: Yup.number().typeError("Rate Card should be a number"),
              startDate: Yup.string()
                .trim()
                .nullable()
                .test(
                  "date-format",
                  "Date should be in MM/dd/yyyy format",
                  (value) => {
                    if (!value || value === "") {
                      return true;
                    }
                    const formattedDate = new Date(value).toLocaleDateString(
                      "en-US"
                    );
                    // If its a correctly formatted date
                    return /^([1-9]|1[012])[- /.]([1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d$/.test(
                      formattedDate
                    );
                  }
                ),
              endDate: Yup.string()
                .trim()
                .nullable()
                .test(
                  "date-format",
                  "Date should be in MM/dd/yyyy format",
                  (value) => {
                    if (!value || value === "") {
                      return true;
                    }
                    const formattedDate = new Date(value).toLocaleDateString(
                      "en-US"
                    );
                    // If its a correctly formatted date
                    return /^([1-9]|1[012])[- /.]([1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d$/.test(
                      formattedDate
                    );
                  }
                )
                .test(
                  "enddate-after-start",
                  "End Date should be after Start Date",
                  (value, testContext) => {
                    if (!value || value === "") {
                      return true;
                    }
                    const { parent } = testContext;
                    const { startDate } = parent;
                    if (!startDate || startDate === "") {
                      return true;
                    }
                    return !isBefore(new Date(value), new Date(startDate));
                  }
                ),
              reconcilable: Yup.string().trim().required(errorMsg)
            })
          );
        } else if (
          field === "startDate" ||
          field === "endDate" ||
          field === "startDateOriginal" ||
          field === "endDateOriginal"
        ) {
          schemaObj[field] = Yup.string()
            .trim()
            .required(" ")
            .nullable()
            .test(
              "date-format",
              "Date should be in MM/dd/yyyy format",
              (value) => {
                if (!value || value === "") {
                  return true;
                }
                const formattedDate = new Date(value).toLocaleDateString(
                  "en-US"
                );
                // If its a correctly formatted date
                return /^([1-9]|1[012])[- /.]([1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d$/.test(
                  formattedDate
                );
              }
            )
            .test(
              "enddate-after-start",
              "End Date should be after Start Date",
              (value, testContext) => {
                if (!value || value === "" || field === "startDate") {
                  return true;
                }

                if (field === "endDate") {
                  return !isBefore(
                    new Date(value),
                    new Date(testContext.parent.startDate)
                  );
                }
                return true;
              }
            )
            .test(
              "enddate-after-start",
              "End Date should be after Start Date",
              (value, testContext) => {
                if (!value || value === "" || field === "startDateOriginal") {
                  return true;
                }

                if (field === "endDateOriginal") {
                  return !isBefore(
                    new Date(value),
                    new Date(testContext.parent.startDateOriginal)
                  );
                }
                return true;
              }
            );
        } else if (field === "payrollProfitCenter") {
          schemaObj[field] = Yup.object()
            .shape({
              label: Yup.string().required(errorMsgSelect),
              value: Yup.string().required(errorMsgSelect)
            })
            .required(errorMsgSelect);
        }
      }
    });

    return Yup.object(schemaObj);
  };

  sumOfAllocations = (formikProps, fieldName) => {
    let sum = 0;
    formikProps.values[fieldName].forEach((resource) => {
      if (
        resource.status !== null &&
        resource.status.value !== "Eliminated" &&
        resource.allocation !== "" &&
        resource.allocation !== "."
      ) {
        sum += parseFloat(resource.allocation);
      }
    });

    return sum * 100;
  };

  clearUserAddedValidations = (fieldName) => {
    const validations = [...this.state.userAddedValidations];
    let foundIndex = validations.findIndex((x) => x.fieldName === fieldName);
    if (foundIndex >= 0) {
      validations.splice(foundIndex, 1);
    }
    this.setState(() => ({ userAddedValidations: validations }));
  };

  // Method to set the list of user added validation values, which can be shown
  // in the warning later
  setUserAddedValidationsValue = (fieldName, value) => {
    const validations = [...this.state.userAddedValidations];
    let foundIndex = validations.findIndex((x) => x.fieldName === fieldName);
    if (foundIndex >= 0) {
      validations[foundIndex] = { fieldName, value };
    } else {
      validations.push({ fieldName, value });
    }
    this.setState(() => ({ userAddedValidations: validations }));
  };

  removeAllocation = (index, formikProps) => {
    const currentRow = formikProps.values.allocations[index];
    const initialAllocations = formikProps.initialValues.allocations;
    if (!initialAllocations || initialAllocations.length === 0) {
      // No initial allocations were present, so we just
      // remove the added allocation without marking it as eliminated
      this.abuseAllocations.remove(index);
    } else if (!initialAllocations[index]) {
      this.abuseAllocations.remove(index);
    } else {
      this.abuseAllocations.replace(index, {
        client: currentRow.client,
        clientLob: currentRow.clientLob,
        allocation: currentRow.allocation,
        rateCard: currentRow.rateCard,
        startDate: currentRow.startDate,
        endDate: currentRow.endDate,
        status: { label: "Eliminated", value: "Eliminated" },
        id: currentRow.id,
        reconcilable: currentRow.reconcilable
      });
    }
  };

  filterClientLOB = (selectedClient, index) => {
    if (selectedClient) {
      // Filter the clientLob based on client value
      const clientLobList = this.props.validations.clientLobList.filter(
        (clientLob) => clientLob.parentValue === selectedClient
      );

      if (
        this.abuseFormikProps &&
        !clientLobList.filter(
          (x) => x.value === this.abuseFormikProps.values.clientLob?.value
        ).length > 0
      ) {
        // Clear selection from clientLob dropdown if not in filtered values
        this.abuseFormikProps.setFieldValue(
          `allocations.${index}.clientLob`,
          ""
        );
      }
      this.setState(() => ({ clientLobList }));
    }
  };

  addAllocation = (formValues) => {
    const currentAllocations = formValues.allocations;
    const status = this.getEmployeePinStatus(formValues.status);
    // making a fake lastAllocation as needed to make the next id from
    const lastAllocation = currentAllocations[
      currentAllocations.length - 1
    ] || { id: 0 };
    if (this.abuseAllocations) {
      // add a new object
      this.abuseAllocations.push({
        client: { value: "", label: "" },
        clientLob: { value: "", label: "" },
        allocation: "",
        rateCard: "",
        status: { value: status, label: status },
        id: lastAllocation.id + 1,
        startDate: "",
        endDate: "",
        reconcilable: ""
      });

      // take a beat, and look to set the focus
      setTimeout(() => {
        if (this.lastAllocationInput) {
          this.lastAllocationInput.current.focus();
        }
      }, 100);
    }
  };

  renderResourceAllocations = (
    formikProps,
    clientLobList,
    clientList,
    screenNumber
  ) => {
    const fieldName =
      screenNumber == 1
        ? "allocationsOriginal"
        : screenNumber == 2
        ? "allocations"
        : "allocations";

    return (
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => {
          this.abuseAllocations = arrayHelpers;
          return (
            <div className="mt-1" id={fieldName}>
              {formikProps.values[fieldName].map((resource, index) => {
                const keyIndex = `resource_row_${index}`;
                // Get the value of status
                let statusVal = formikProps.values[fieldName][index].status;
                if (statusVal) {
                  statusVal = statusVal.value || statusVal;
                }
                // Do not display allocation rows with Eliminated Status
                if (statusVal !== "Eliminated") {
                  return (
                    <Collapsible
                      // eslint-disable-next-line react/no-children-prop, react/prop-types
                      children={this.props.children}
                      key={keyIndex}
                      formikProps={formikProps}
                      arrayIndex={index}
                      fieldName={fieldName}
                    >
                      <div
                        data-testid={fieldName}
                        className="pt-2"
                        key={keyIndex}
                      >
                        <div className="row align-items-center">
                          <div className="medium-input-left col-2 no-padding">
                            <TextBoxField
                              formikProps={formikProps}
                              fieldName={`${fieldName}.${index}.allocation`}
                              label="Allocation"
                              isOptional={false}
                              className="ml-1"
                              focus="lastAllocationInput"
                              focusRef={this.lastAllocationInput}
                              disable={
                                !this.canModifyRAPRecords || screenNumber !== 2
                              }
                            />
                          </div>
                          <div className="ml-1 mb-2 med-input col-5">
                            <TypeAheadBoxField
                              formikProps={formikProps}
                              suggestions={clientList}
                              fieldName={`${fieldName}.${index}.client`}
                              label="Client - Brand"
                              setUserAddedValidationsValue={
                                this.setUserAddedValidationsValue
                              }
                              clearUserAddedValidations={
                                this.clearUserAddedValidations
                              }
                              disable={
                                !this.canModifyRAPRecords || screenNumber !== 2
                              }
                              updateChildDropdown={(val) =>
                                this.filterClientLOB(val, index)
                              }
                              isParent
                            />
                          </div>
                          <div className="ml-1 mb-2 med-input col-4 no-padding">
                            <TypeAheadBoxField
                              formikProps={formikProps}
                              suggestions={clientLobList.map(
                                (x) => x.value || x
                              )}
                              fieldName={`${fieldName}.${index}.clientLob`}
                              label="Client LOB"
                              isOptional
                              setUserAddedValidationsValue={
                                this.setUserAddedValidationsValue
                              }
                              clearUserAddedValidations={
                                this.clearUserAddedValidations
                              }
                              placeholder="Please select a Client"
                              disable={
                                !this.canModifyRAPRecords || screenNumber !== 2
                              }
                            />
                          </div>
                          <div className="remove-button col-1 pl-2">
                            {screenNumber == 2 && (
                              <button
                                className="btn btn-xsmall red-text"
                                type="button"
                                data-testid={`btnRemoveAllocationItem_${index}`}
                                id={`btnRemoveAllocationItem_${index}`}
                                name={`btnRemoveAllocationItem_${index}`}
                                onClick={() =>
                                  this.removeAllocation(index, formikProps)
                                }
                                disabled={
                                  !this.canModifyRAPRecords ||
                                  screenNumber !== 2
                                }
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="medium-input-left col-3 no-padding">
                            <TextBoxField
                              className="ml-1"
                              formikProps={formikProps}
                              fieldName={`${fieldName}.${index}.rateCard`}
                              label="Rate Card"
                              isOptional
                              disable={
                                !this.canModifyRAPRecords || screenNumber !== 2
                              }
                            />
                          </div>

                          <div className="input col-3">
                            <DatePickerBoxField
                              isArrayField
                              className="ml-0"
                              formikProps={formikProps}
                              label="Start Date"
                              arrayIndex={index}
                              isOptional
                              fieldName="startDate"
                              arrayFieldName={fieldName}
                              disable={
                                !this.canModifyRAPRecords || screenNumber !== 2
                              }
                            />
                          </div>
                          <div className="input col-3 pb-3">
                            <DatePickerBoxField
                              arrayFieldName={fieldName}
                              isArrayField
                              formikProps={formikProps}
                              label="End Date"
                              isOptional
                              arrayIndex={index}
                              fieldName="endDate"
                              disable={
                                !this.canModifyRAPRecords || screenNumber !== 2
                              }
                            />
                          </div>
                          <div className="col-sm-2 pr-1">
                            <Field name={`${fieldName}.${index}.reconcilable`}>
                              {({ meta }) => (
                                <ButtonGroup
                                  fieldName={`${fieldName}.${index}.reconcilable`}
                                  fieldValue={
                                    formikProps.values[fieldName][index]
                                      .reconcilable
                                  }
                                  label="Reconcilable"
                                  buttonValues={RapConstants.BOOLEAN_VALUES}
                                  setButtonValue={(value) => {
                                    formikProps.setFieldValue(
                                      `${fieldName}.${index}.reconcilable`,
                                      value
                                    );
                                  }}
                                  inError={!!meta.error}
                                  isRequired
                                  disable={
                                    !this.canModifyRAPRecords ||
                                    screenNumber !== 2
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  );
                }
                return null;
              })}
              {screenNumber == 2 && (
                <div className="row">
                  <div className="ml-3 mt-1 mb-4">
                    <button
                      type="button"
                      id="btnAddResource"
                      name="btnAddResource"
                      onClick={() => this.addAllocation(formikProps.values)}
                      className="btn btn-sm btn-secondary"
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  };

  updateEndDate = (formikProps) => {
    // set start date of new record to previous record end date + 1
    const endDateOriginal = new Date(
      formikProps.values.endDateOriginal
    ).toString();
    const newStartDate = new Date(endDateOriginal);
    newStartDate.setDate(newStartDate.getDate() + 1);
    formikProps.values.startDate = newStartDate;

    // Set end date for new record
    const finalYear = newStartDate.getFullYear();
    const currentYear = new Date().getFullYear();
    // To set end Date of new record to 31st Dec
    if (finalYear >= currentYear) {
      formikProps.values.endDate = new Date(finalYear, 11, 31);
    } else {
      formikProps.values.endDate = new Date(currentYear, 11, 31);
    }
  };

  getEmployeePinStatus = (employeeStatus) =>
    RapConstants.EMPLOYEE_PIN_STATUS[employeeStatus] || "";

  saveFormData = async (formInput) => {
    const { onSubmit } = this.props;
    let finalValues = {};
    // Update confirmed the finalValues have been set at this point so use those directly
    finalValues = this.calculateFinalFormValues(formInput, false);
    // Remove the ID from allocations (Added only to be used to compare allocations
    // to be used in the UI Update Confirm modal)
    finalValues.allocations.forEach((a) => {
      delete a.id;
    });

    // Pass pinStatus to backend
    finalValues["pinStatus"] = this.getEmployeePinStatus(
      finalValues.statusOriginal
    );

    try {
      const result = (await api.reallocateRecord(finalValues)) || {};

      if (result.status === "success") {
        toast.success("Record saved successfully", Constants.TOAST_OPTIONS);
        let { newRecord } = result;
        // Get the history again to refresh the main page history
        const history = await api.getRecordHistory(newRecord.mslPin);
        this.props.resetStateDataAfterSubmit();
        this.props.recordHistory(history);
        if (newRecord) {
          // Passing new record to main form to render
          this.props.reallocatedRecord(newRecord);
          this.props.onPinReallocateModalClose();
        }
      } else {
        toast.error(
          "There was a problem in saving the data.",
          Constants.TOAST_OPTIONS
        );
        this.props.resetStateDataAfterSubmit();
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex);
      this.props.resetStateDataAfterSubmit();
      toast.error(
        `There was an error in saving the data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  calculateFinalFormValues = (formInput, isUpdate) => {
    const finalValues = {};
    for (let key in formInput) {
      if (LIST_FIELDS.includes(key)) {
        finalValues[key] = isUpdate
          ? formInput[key]
          : formInput[key]?.value || "";
      } else if (key === "allocations") {
        finalValues.allocations =
          formInput.allocations
            ?.filter((item) => item.status.value !== "Eliminated")
            .map((item) => {
              const { client, clientLob, status } = item;
              const clientVal = isUpdate ? client : client?.value || "";
              const clientLobVal = isUpdate
                ? clientLob
                : clientLob?.value || "";
              const statusVal = isUpdate ? status : status?.value || "";
              const startDate = isUpdate
                ? item.startDate
                : item.startDate
                ? format(item.startDate, "MM/dd/yyyy")
                : "";
              const endDate = isUpdate
                ? item.endDate
                : item.endDate
                ? format(item.endDate, "MM/dd/yyyy")
                : "";
              const allocationReconcilable = item.reconcilable || "No";
              return {
                ...item,
                client: clientVal,
                clientLob: clientLobVal,
                status: statusVal,
                startDate,
                endDate,
                reconcilable: allocationReconcilable
              };
            }) || [];
      } else if (
        (key === "startDate" ||
          key === "endDate" ||
          key === "endDateOriginal") &&
        !isUpdate
      ) {
        finalValues[key] = formInput[key]
          ? format(formInput[key], "MM/dd/yyyy")
          : "";
      } else {
        finalValues[key] = formInput[key] || "";
      }
    }
    return finalValues;
  };

  handleSubmit = async (formInput) => {
    this.setState(() => ({ isSubmitting: true }));
    await this.saveFormData(formInput);
  };

  render() {
    const { isModalOpen, onPinReallocateModalClose, dataInput, validations } =
      this.props;

    if (!isModalOpen) {
      return (
        <div>
          Loading...
          <Spinner waiting />
        </div>
      );
    }

    const formInitialValues = {};
    // Set the initial values for the form
    filterFieldNames.forEach((field) => {
      var originalField = field + "Original";
      if (dataInput[field]) {
        if (field == "startDate") {
          // to set start date of the original/previous record
          const convertedDate = new Date(dataInput[field]);
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
          formInitialValues[originalField] = isValid(convertedDate)
            ? convertedDate
            : "";
        } else if (field == "endDate") {
          // to set end date of the original/previous record
          const convertedDate = new Date(dataInput[field]);
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
          formInitialValues[originalField] = isValid(convertedDate)
            ? convertedDate
            : "";
        } else if (field === "allocations") {
          formInitialValues[field] = [];
          formInitialValues[originalField] = [];
          if (dataInput[field] && dataInput[field].length > 0) {
            dataInput[field].forEach((item, count) => {
              let originalAllocationRow = {
                client: null,
                clientLob: null,
                allocation: item.allocation || "",
                rateCard: item.rateCard || "",
                startDate: isValid(new Date(item.startDate))
                  ? new Date(item.startDate)
                  : "",
                endDate: isValid(new Date(item.endDate))
                  ? new Date(item.endDate)
                  : "",
                id: count
              };

              if (item.client) {
                originalAllocationRow.client = {
                  value: item.client,
                  label: item.client
                };
              }

              if (item.clientLob) {
                originalAllocationRow.clientLob = {
                  value: item.clientLob,
                  label: item.clientLob
                };
              }

              if (item.status) {
                originalAllocationRow.status = {
                  value: item.status,
                  label: item.status
                };
              }
              originalAllocationRow.reconcilable = item.reconcilable || "No";
              formInitialValues[originalField].push(originalAllocationRow);

              //To exclude eliminated allocations from New PIN
              if (item.status !== "Eliminated") {
                let allocationRow = {
                  client: null,
                  clientLob: null,
                  allocation: item.allocation || "",
                  rateCard: item.rateCard || "",
                  startDate: isValid(new Date(item.startDate))
                    ? new Date(item.startDate)
                    : "",
                  endDate: isValid(new Date(item.endDate))
                    ? new Date(item.endDate)
                    : "",
                  id: count
                };

                if (item.client) {
                  allocationRow.client = {
                    value: item.client,
                    label: item.client
                  };
                }
                if (item.clientLob) {
                  allocationRow.clientLob = {
                    value: item.clientLob,
                    label: item.clientLob
                  };
                }
                if (item.status) {
                  allocationRow.status = {
                    value: item.status,
                    label: item.status
                  };
                }
                allocationRow.reconcilable = item.reconcilable || "No";
                formInitialValues[field].push(allocationRow);
              }
            });
          }
        } else if (field === "payrollProfitCenter") {
          formInitialValues[field] = {
            value: dataInput[field],
            label: dataInput[field]
          };

          formInitialValues[originalField] = {
            value: dataInput[field],
            label: dataInput[field]
          };
        } else {
          formInitialValues[field] = dataInput[field];
          formInitialValues[originalField] = dataInput[field];
        }
      } else {
        if (field === "changeReason") {
          formInitialValues[originalField] = "Old PIN; Reallocation";
          formInitialValues[field] = "New PIN; Reallocation";
        } else {
          formInitialValues[field] = "";
          formInitialValues[originalField] = "";
        }
      }
    });

    return (
      <div className="container">
        <Modal
          id="pinReAllocateModal"
          role="dialog"
          isOpen={isModalOpen}
          onRequestClose={onPinReallocateModalClose}
          contentLabel="Re-Allocate PIN"
          shouldCloseOnEsc
          shouldCloseOnOverlayClick={true}
          parentSelector={() => document.body}
          className="modalcontent"
          overlayClassName="modaloverlay"
          ariaHideApp={false}
        >
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={this.handleSubmit}
            validationSchema={this.getValidationSchema()}
          >
            {(formikProps) => (
              <Form className="form-horizontal col-sm-12" name="pinReAllocate">
                {(this.abuseFormikProps = formikProps) && <></>}
                <div className="row progressBarRow">
                  <div className="col-lg-4 progress-container">
                    {this.state.screenNumber > 0 && (
                      <span className="progress-bar">&nbsp;</span>
                    )}
                  </div>
                  <div className="col-lg-4 progress-container">
                    {this.state.screenNumber > 1 && (
                      <span className="progress-bar">&nbsp;</span>
                    )}
                  </div>
                  <div className="col-lg-4 progress-container">
                    {this.state.screenNumber > 2 && (
                      <span className="progress-bar">&nbsp;</span>
                    )}
                  </div>
                </div>
                <div className="row mt-4 mb-4">
                  <div className="col-lg-1 ml-3 screenNumberCol mt-1">
                    <span className="screenNumberLabel">
                      {this.state.screenNumber} of 3
                    </span>
                  </div>
                  <div className="col-lg-9 mt-2">
                    <span className="recordTitle mt-3">Reallocation of </span>
                    <span className="recordName mt-3">
                      {formikProps.values.mslPin} |{" "}
                      {formikProps.values.givenName +
                        " " +
                        formikProps.values.familyName}
                    </span>
                    <p></p>
                    <span className="screenInstructions">
                      {this.state.screenNumber == 1
                        ? `Update the End Date and Reason for Change for the Old PIN
                        record:`
                        : this.state.screenNumber == 2
                        ? `Update the Position Allocations and Profit Center for the New PIN
                        record:`
                        : "Review and confirm reallocaiton to generate new PIN Record."}
                    </span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-6 mb-1">
                    <span className="summaryTitles">OLD PIN SUMMARY</span>
                  </div>
                  {this.state.screenNumber > 1 && (
                    <div className="col-lg-6 mb-1">
                      <span className="summaryTitles">
                        NEW PIN SUMMARY
                        {this.state.screenNumber == 3 && (
                          <span className="newPinWarning float-right">
                            *New PIN will be generated on submitting the
                            details.
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="col-lg-12 hrline">&nbsp;</div>
                    <div className="row mt-3">
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">MSL PIN</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>: </div>
                        <div className="value-text">
                          {formikProps.values.mslPin}
                        </div>
                      </div>
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">Start Date</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>: </div>
                        <div className="value-text">
                          {format(
                            formikProps.values.startDateOriginal,
                            "MM/dd/yyyy"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">Workday Title</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>: </div>
                        <div className="value-text">
                          {formikProps.values.workdayTitle}
                        </div>
                      </div>
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">End Date</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>: </div>
                        <div className="value-text">
                          {isValid(formikProps.values.endDateOriginal)
                            ? format(
                                formikProps.values.endDateOriginal,
                                "MM/dd/yyyy"
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">Employee Name</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>:</div>
                        <div className="value-text">
                          {" "}
                          {formikProps.values.givenName +
                            " " +
                            formikProps.values.familyName}
                        </div>
                      </div>
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">Profit Center</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>: </div>
                        <div className="value-text">
                          {formikProps.values.payrollProfitCenterOriginal.value}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-3 labelCol">
                        <span className="label-text">Workday ID</span>
                      </div>
                      <div className="col-lg-3 valueCol">
                        <div>: </div>
                        <div className="value-text">
                          {formikProps.values.workdayId}
                        </div>
                      </div>
                      {this.state.screenNumber > 1 && (
                        <>
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">
                              Reason for change
                            </span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {formikProps.values.changeReasonOriginal}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row mt-4 mb-4">&nbsp;</div>
                    <div className="row mt-4">
                      <div className="col-lg-3">
                        <span className="position-allocations">
                          Position Allocations
                        </span>
                      </div>
                      <div
                        className={
                          this.sumOfAllocations(
                            formikProps,
                            "allocationsOriginal"
                          ) > 100
                            ? "col-sm-3 allocationExceeded"
                            : "col-sm-3 allocationComplete"
                        }
                      >
                        <span>
                          Total:{" "}
                          {this.sumOfAllocations(
                            formikProps,
                            "allocationsOriginal"
                          ).toFixed(0)}
                          %
                        </span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-12">
                        {this.renderResourceAllocations(
                          formikProps,
                          validations.clientLobList,
                          validations.clientList,
                          1
                        )}
                      </div>
                    </div>
                  </div>
                  {/* show end date and reason for change only on screen #1 */}
                  {this.state.screenNumber == 1 && (
                    <div className="col-lg-6">
                      <div className="row mt-4">
                        <div className="col-lg-4 endDateLabel">
                          <DatePickerBoxField
                            formikProps={formikProps}
                            label="End Date"
                            fieldName="endDateOriginal"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-4 changeReasonLabel">
                          <TextBoxField
                            formikProps={formikProps}
                            fieldName="changeReasonOriginal"
                            label="Reason for Change"
                            isOptional={false}
                            hideHeader={false}
                            className="mt-2 mb-2"
                            focus="changeReasonInput"
                            focusRef={this.changeReasonInput}
                            disable={disableFields}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Screen #3 */}
                  <div className="col-lg-6">
                    {this.state.screenNumber > 1 && (
                      <div className="col-lg-12 hrline">&nbsp;</div>
                    )}
                    {this.state.screenNumber == 3
                      ? this.updateEndDate(formikProps)
                      : ""}
                    {this.state.screenNumber == 3 && (
                      <div>
                        <div className="row mt-3">
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">MSL PIN</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            : <span className="value-text">-</span>
                          </div>
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">Start Date</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {format(
                                formikProps.values.startDate,
                                "MM/dd/yyyy"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">Workday Title</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {formikProps.values.workdayTitle}
                            </div>
                          </div>
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">End Date</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {format(formikProps.values.endDate, "MM/dd/yyyy")}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">Employee Name</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {formikProps.values.givenName +
                                " " +
                                formikProps.values.familyName}
                            </div>
                          </div>
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">Profit Center</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {formikProps.values.payrollProfitCenter.value}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">Workday ID</span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {formikProps.values.workdayId}
                            </div>
                          </div>
                          <div className="col-lg-3 labelCol">
                            <span className="label-text">
                              Reason for change
                            </span>
                          </div>
                          <div className="col-lg-3 valueCol">
                            <div>: </div>
                            <div className="value-text">
                              {formikProps.values.changeReason}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 mb-3">&nbsp;</div>
                      </div>
                    )}

                    {/* screen #2, 3 */}
                    {this.state.screenNumber > 1 && (
                      <div
                        className={
                          this.state.screenNumber == 2
                            ? `row mt-2`
                            : " row mt-3"
                        }
                      >
                        <div className="col-lg-3 mt-2">
                          <span className="position-allocations">
                            Position Allocations
                          </span>
                        </div>
                        <div
                          className={
                            this.sumOfAllocations(formikProps, "allocations") >
                            100
                              ? "col-sm-4 allocationExceeded mt-2"
                              : "col-sm-4 allocationComplete mt-2"
                          }
                        >
                          <span>
                            Total:{" "}
                            {this.sumOfAllocations(
                              formikProps,
                              "allocations"
                            ).toFixed(0)}
                            %
                          </span>
                        </div>
                      </div>
                    )}

                    {/* screen #2, 3 */}
                    {this.state.screenNumber > 1 && (
                      <div className="row mt-2">
                        <div className="col-lg-12">
                          {this.renderResourceAllocations(
                            formikProps,
                            this.state.clientLobList,
                            validations.clientList,
                            this.state.screenNumber
                          )}
                        </div>
                      </div>
                    )}

                    {/* Screen #2 */}
                    {this.state.screenNumber == 2 && (
                      <div>
                        <div className="row mt-2">
                          <div className="col-lg-6 costCenterLabel">
                            <TypeAheadBoxField
                              formikProps={formikProps}
                              suggestions={validations.payrollProfitCenterList}
                              fieldName="payrollProfitCenter"
                              label="Update the Profit Center (as needed)"
                              setUserAddedValidationsValue={
                                this.setUserAddedValidationsValue
                              }
                              clearUserAddedValidations={
                                this.clearUserAddedValidations
                              }
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-4 changeReasonLabel">
                            <TextBoxField
                              formikProps={formikProps}
                              fieldName="changeReason"
                              label="Reason for Change"
                              isOptional={false}
                              hideHeader={false}
                              className="mt-2 mb-2"
                              focus="changeReasonInput"
                              focusRef={this.changeReasonInput}
                              disable={disableFields}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-3 mb-n2 searchModalFooter">
                  <div className="col-sm-12 mt-3 mb-3">
                    {this.state.screenNumber > 1 && (
                      <button
                        type="button"
                        onClick={this.backScreen}
                        className="btn btn-sm btn-secondary float-left ml-2"
                      >
                        Back
                      </button>
                    )}
                    {this.state.screenNumber == 3 && (
                      <button
                        type="submit"
                        className={`btn btn-sm float-right mr-2 btn-${
                          formikProps.isValid ? "success" : "invalid"
                        } ml-2`}
                        disabled={
                          !formikProps.isValid || this.state.isSubmitting
                        }
                      >
                        Confirmed
                      </button>
                    )}
                    {this.state.screenNumber == 1 && (
                      <button
                        type="button"
                        onClick={this.updateScreenCount}
                        className={`btn btn-sm float-right mr-2 btn-${
                          formikProps.errors.endDateOriginal
                            ? "invalid"
                            : "success"
                        }`}
                        disabled={formikProps.errors.endDateOriginal}
                      >
                        Next, Update Allocations
                      </button>
                    )}
                    {this.state.screenNumber == 2 && (
                      <button
                        type="button"
                        onClick={this.updateScreenCount}
                        className={`btn btn-sm float-right mr-2 btn-${
                          formikProps.errors.payrollProfitCenter ||
                          !formikProps.isValid
                            ? "invalid"
                            : "success"
                        }`}
                        disabled={
                          formikProps.errors.payrollProfitCenter ||
                          !formikProps.isValid
                        }
                      >
                        Next, Confirm Reallocation
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={onPinReallocateModalClose}
                      className="btn btn-sm float-right mr-5"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}

PinReAllocate.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onPinReallocateModalClose: PropTypes.func.isRequired,
  updateScreenCount: PropTypes.func,
  validations: PropTypes.shape({
    clientBrandList: PropTypes.instanceOf(Array),
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array)
  }).isRequired,
  dataInput: PropTypes.shape({
    mslPin: PropTypes.string
  }),
  resetStateDataAfterSubmit: PropTypes.func.isRequired
};

export default PinReAllocate;
