/* eslint-disable no-return-assign */
import React from "react";
import PropTypes, { string } from "prop-types";
import { format } from "date-fns";
import CollapsibleCard from "../../shared/CollapsibleCard";
import TextAreaField from "../../shared/TextAreaField";
import ParConstants from "../../../common/ParConstants";

const TicketAction = ({ formikProps, isDraft, queueType }) => (
  <CollapsibleCard title="Ticket Action">
    <div className="row">
      <div className="col-md-3 mt-3">
        <label htmlFor="submitter">{ParConstants.FORM_FIELDS.submitter}</label>
        <div id="submitter">
          <strong>
            {formikProps.values.submitter ||
              (window.userSession && window.userSession.email
                ? window.userSession.name
                : "")}
          </strong>
        </div>
      </div>
      {!isDraft && (
        <div className="col-md-3 mt-3">
          <label htmlFor="submitter">
            {ParConstants.FORM_FIELDS.submitted_date}
          </label>
          <div id="submitter">
            <strong>
              {formikProps.values.submitted_date
                ? format(
                    new Date(formikProps.values.submitted_date),
                    "MM/dd/yyyy"
                  )
                : ""}
            </strong>
          </div>
        </div>
      )}
      <div className="col-md-3 mt-3">
        {isDraft && <div className="rounded-box">Submission In Progress</div>}
      </div>
    </div>
    {queueType !== ParConstants.PAR_SUBMISSIONS_QUEUE && (
      <div className="row">
        <div className="col-md-6 mt-3">
          <TextAreaField
            formikProps={formikProps}
            label={ParConstants.FORM_FIELDS.comment}
            fieldName="comment"
            id="comment"
            isOptional
          />
        </div>
      </div>
    )}
  </CollapsibleCard>
);

TicketAction.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      comment: PropTypes.string,
      submitted_date: string,
      submitter: PropTypes.string
    })
  }),
  isDraft: PropTypes.bool,
  queueType: PropTypes.string
};

TicketAction.defaultProps = {
  formikProps: {
    values: {
      comment: null,
      submitted_date: null,
      submitter: null
    }
  },
  isDraft: false,
  queueType: ""
};

export default TicketAction;
