/* eslint-disable no-return-assign */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isValid, format } from "date-fns";
import { toast } from "react-toastify";
import "../../../styles/shared/collapse-panel.scss";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import security from "../../../services/Security";
import api from "../../../services/ParApi";
import Constants from "../../../common/Constants";
import RapConstants from "../../../common/RapConstants";
import ParConstants from "../../../common/ParConstants";
import ParStatus from "./ParStatus";
import PositionJustification from "./PositionJustification";
import RequisitionInstructions from "./RequisitionInstructions";
import ReallocationInstructions from "./ReallocationInstructions";
import PositionDetails from "./PositionDetails";
import Modal from "../../shared/Modal";
import ConfirmationModal from "../../shared/ConfirmationModal";

import TicketAction from "./TicketAction";
import Sidebar from "../../shared/Sidebar";
import TicketHistory from "../ticket_history/TicketHistory";
import EmployeeDetails from "./EmployeeDetails";

import ParHelpers, {
  getLevelInNumeric,
  getPercentageIncrease,
  isExternalFill,
  isGlobalEmployee
} from "../../../helpers/ParHelpers";
import PromotionInstructions from "./PromotionInstructions";
import useQueueStatus from "../../hooks/par/useQueueStatus";
import useRoleStatus from "../../hooks/par/useRoleStatus";
import useWorkflowStatus from "../../hooks/par/useWorkflowStatus";
import useSectionVisibility from "../../hooks/par/useSectionVisibility";

const ParForm = ({
  validations,
  parType,
  queueType,
  getValidationSchema,
  initialData,
  parAvailablePins,
  parPayRanges,
  refreshForm,
  activeTab
}) => {
  const navigate = useNavigate();
  const DEFAULT_VALUES = {
    allocations: []
  };
  // Set PAR ID in case its an existing PAR Record
  const [parRequestId, setParRequestId] = useState(
    (initialData && initialData.par_request_id) || null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [doNavigate, setDoNavigate] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRefreshForm, setRefreshForm] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState(null);

  const [modalMessage, setModalMessage] = useState("");
  const [availablePINs, setAvailablePINs] = useState(parAvailablePins || []);
  const [payRanges, setPayRanges] = useState(parPayRanges || []);
  const [globalCostData, setGlobalCostData] = useState([]);
  const canModifyPARRecords = security.canEditPARData();
  const [userRole] = useState(
    window.userSession.parPermissions.permission_name
  );

  const {
    isRMIntake,
    isHRIntake,
    isHRAction,
    isRMAction,
    isScaledService,
    isFinance,
    isBrandCEO,
    isExecutive
  } = useQueueStatus(initialData);

  const {
    isSubmitterRole,
    isRMRole,
    isHRRole,
    isScaledServiceRole,
    isFinanceRole,
    isBrandCEORole,
    isExecutiveRole,
    isScaledExRole,
    isFinCEORMHRRole
  } = useRoleStatus(userRole);

  const {
    isUrgentCounterOfferWorkflow,
    isBackfillWorkflow,
    isAddToStaffRequestWorkflow,
    isOpenRequestTBHWorkflow,
    isReallocationActive,
    isReallocationTBH,
    isPromotionInPlace,
    isSalaryAdjustmentWorkflow
  } = useWorkflowStatus(parType);

  const checkSectionVisibility = useSectionVisibility(parType);

  let successMessage =
    "PAR <<PARID>> has been advanced to level - <<nextQueueMsg>>";
  let completeMessage = "PAR <<PARID>> is now Complete. <<nextQueueMsg>>";

  // Queues
  const isPARSubmissionsQueue =
    queueType === ParConstants.PAR_SUBMISSIONS_QUEUE;

  // Only if PAR is complete OR has permissions to be viewed,
  // we will show it as editable in the current user login
  const showCancelOnly = () => {
    let isCancelOnly = false;
    if (initialData && !initialData.existing_msl_pin) {
      // Check if PAR is complete
      isCancelOnly =
        initialData.ticket_status === ParConstants.PAR_ACTIONS.PAR_COMPLETE;
      // Check if user has opened the PAR But does not actually have access to it
      if (ParConstants.PAR_QUEUE_PERMISSION_MAP[userRole]) {
        isCancelOnly =
          isCancelOnly ||
          !ParConstants.PAR_QUEUE_PERMISSION_MAP[userRole].includes(
            initialData.current_queue_name
          );
      } else {
        // Its the client submitter role that means a client submitter is trying
        // to view a PAR that is not created by the viewing user
        isCancelOnly =
          isCancelOnly ||
          initialData.created_by !== window?.userSession?.userId ||
          isPARSubmissionsQueue;
      }
    } else if (queueType) {
      isCancelOnly = isPARSubmissionsQueue;
    }
    return isCancelOnly;
  };

  const isCancelOnly = showCancelOnly();
  // By default always show read only fields if the request is in a queue
  // OR if its already complete
  const showDescription =
    initialData && (initialData.current_queue_id != null || isCancelOnly);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isRefreshForm) {
      const timer = setTimeout(() => {
        setRefreshForm(false);
        refreshForm(parRequestId);
      }, 1000); // Auto-close after 1 seconds
      return () => clearTimeout(timer);
    }
    return false;
  }, [isRefreshForm]);

  useEffect(() => {
    if (doNavigate) {
      const timer = setTimeout(() => {
        setDoNavigate(false);
        navigate("/par", {
          state: { currentTab: activeTab }
        });
      }, 2000); // Auto-close after 2 seconds

      return () => clearTimeout(timer);
    }
    return false;
  }, [doNavigate]);

  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        closeModal();
      }, 3000); // Auto-close after 3 seconds

      return () => clearTimeout(timer);
    }
    return false;
  }, [isModalOpen]);

  let abuseFormikProps = null;

  // Class Object that determines which fields are shown as read only in from
  let showFieldsReadOnly = {
    allocClient: true,
    allocLOB: true,
    allocVal: true,
    allocRateCard: true,
    allocReconcilable: true,
    profit_center: true,
    related_pars: true,
    cc_emails: true,
    is_exempt: true,
    partner: true,
    partner_cost: true,
    offshore_role: true,
    par_brand: true,
    par_axcompany: true,
    par_payroll_company: true,
    par_bpc: true,
    job_code: true,
    requisition_id: true,
    current_performance_rating: true,
    current_base_salary: true,
    date_of_last_compensation_change: true,
    date_of_lastpromotion: true,
    requested_offer: true,
    business_title: true,
    target_budget: true,
    salary_budget: true,
    open_requisition_reason: true,
    backfill_reason: true,
    employee_name: true,
    position_id: true,
    fill_date: true,
    summary_department: true,
    serviceline_department: true,
    reallocation_reassign_allocation_to: true,
    file_upload: true,
    approved_offer: true,
    approved_effective_date: true,
    target_effective_date: true
  };

  // Class Object that determines which fields are hidden in the form
  let hideFields = {
    allocRateCard: true,
    allocReconcilable: true,
    profit_center: true,
    is_exempt: true,
    partner: true,
    partner_cost: true,
    offshore_role: true,
    par_brand: true,
    par_axcompany: true,
    par_payroll_company: true,
    par_bpc: true,
    job_code: true,
    requisition_id: true,
    open_requisition_reason: true,
    par_level: true,
    current_performance_rating: true,
    current_base_salary: true,
    date_of_last_compensation_change: true,
    date_of_lastpromotion: true,
    requested_offer: true,
    compa_ratio_of_offer: true,
    percent_increase: true,
    target_budget: true,
    salary_budget: true,
    business_title: true,
    addAllocationBtn: true,
    relatedPARTickets: true,
    par_billable: true,
    backfill_reason: true,
    deleteAllocationBtn: true,
    position_id: true,
    ticket_status: false,
    decision: true,
    approved_offer: true,
    approved_effective_date: true
  };

  /**
   * Method that determines which fields will be visible
   * and will be either editable or readonly for each of the
   * workflow types
   * Captures the business rules from the requirements to set
   * field status for each field type per workflow.
   */
  const addToStaffWorkflowFieldVisibility = () => {
    hideFields.open_requisition_reason = false;
    hideFields.relatedPARTickets = false;
    if (isSubmitterRole) {
      hideFields.is_exempt = false;
      hideFields.ticket_status = true;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.par_level = false;
      hideFields.addAllocationBtn = showDescription;
      hideFields.deleteAllocationBtn = showDescription;
      hideFields.par_billable = false;
      showFieldsReadOnly.is_exempt = showDescription;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.business_title = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.summary_department = showDescription;
      showFieldsReadOnly.serviceline_department = showDescription;
    } else if (isRMIntake) {
      hideFields.allocRateCard = false;
      hideFields.allocReconcilable = false;
      hideFields.partner = false;
      hideFields.partner_cost = false;
      hideFields.offshore_role = false;
      hideFields.profit_center = false;
      hideFields.par_brand = false;
      hideFields.par_axcompany = false;
      hideFields.par_payroll_company = false;
      hideFields.par_bpc = false;
      hideFields.salary_budget = false;
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.cc_emails = false;

      // All these fields are visible and editable for RM Intake
      showFieldsReadOnly.allocClient = false;
      showFieldsReadOnly.allocLOB = false;
      showFieldsReadOnly.allocVal = false;
      showFieldsReadOnly.allocRateCard = false;
      showFieldsReadOnly.allocReconcilable = false;
      showFieldsReadOnly.profit_center = false;
      showFieldsReadOnly.partner = false;
      showFieldsReadOnly.offshore_role = false;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.file_upload = false;
    } else if (
      (!isPARSubmissionsQueue &&
        (isScaledService || isHRIntake || isExecutive)) ||
      (isScaledExRole && isPARSubmissionsQueue)
    ) {
      hideFields.par_billable = false;
      hideFields.par_level = false;
      hideFields.job_code =
        isScaledExRole && isPARSubmissionsQueue ? true : !isHRIntake;
      hideFields.partner =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;
      hideFields.partner_cost =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;
      hideFields.offshore_role =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;
      // PAR Brand, AxCompany, Payroll, BPC to be shown only for HR Intake
      hideFields.par_brand =
        isScaledExRole && isPARSubmissionsQueue ? true : !isHRIntake;
      hideFields.par_axcompany =
        isScaledExRole && isPARSubmissionsQueue ? true : !isHRIntake;
      hideFields.par_payroll_company =
        isScaledExRole && isPARSubmissionsQueue ? true : !isHRIntake;
      hideFields.par_bpc =
        isScaledExRole && isPARSubmissionsQueue ? true : !isHRIntake;
      // Exempt is shown for all fields for the relevant queues
      hideFields.target_budget = !(
        isHRIntake ||
        !isScaledService ||
        isExecutive
      );
      // job_code should be editable for HRIntake
      showFieldsReadOnly.job_code = isPARSubmissionsQueue ? true : !isHRIntake;

      // Internal Fill Fields need to be enabled and editable for HRIntake Only
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.salary_budget = false;
      hideFields.compa_ratio_of_offer =
        isExecutiveRole && isPARSubmissionsQueue
          ? false
          : !(isHRIntake || isExecutive);
      hideFields.percent_increase =
        isExecutiveRole && isPARSubmissionsQueue
          ? false
          : !(isHRIntake || isExecutive);
      showFieldsReadOnly.current_performance_rating = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.current_base_salary = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.date_of_last_compensation_change =
        isPARSubmissionsQueue ? true : !isHRIntake;
      showFieldsReadOnly.date_of_lastpromotion = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.requested_offer = isPARSubmissionsQueue
        ? true
        : !isHRIntake;

      // HRIntake Queue should be able to edit business_title
      showFieldsReadOnly.business_title = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      // Executive level Hiring Manager is hidden
      hideFields.relatedPARTickets = false;
      hideFields.hiring_manager =
        isExecutiveRole && isPARSubmissionsQueue ? true : isExecutive;

      showFieldsReadOnly.target_budget = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.target_effective_date = isPARSubmissionsQueue
        ? true
        : isExecutive;

      hideFields.approved_offer = !isExecutive;
      hideFields.approved_effective_date = !isExecutive;

      showFieldsReadOnly.approved_offer = !isExecutive;
      showFieldsReadOnly.approved_effective_date = !isExecutive;
    } else if (
      (!isPARSubmissionsQueue &&
        (isFinance || isBrandCEO || isRMAction || isHRAction)) ||
      (isFinCEORMHRRole && isPARSubmissionsQueue)
    ) {
      hideFields.allocReconcilable = false;
      hideFields.target_budget = false;
      hideFields.allocRateCard = false;
      hideFields.par_level = false;
      hideFields.profit_center = false;
      hideFields.par_level = false;
      hideFields.target_budget = false;
      hideFields.salary_budget = false;
      hideFields.addAllocationBtn = true;
      hideFields.par_billable = false;

      // For Finance Rate Card is editable
      showFieldsReadOnly.allocRateCard = isPARSubmissionsQueue || !isFinance;
      showFieldsReadOnly.target_effective_date = false;
      showFieldsReadOnly.target_budget = true;
      showFieldsReadOnly.fill_date = true;
      // Partner and PartnerCost is not shown in HRAction Queue, but shown in submitter view
      hideFields.partner =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;
      hideFields.partner_cost =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;
      hideFields.offshore_role =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;
      // PAR Brand, AxCompanym Payroll, BPC to be shown only for RM & HR action
      hideFields.par_brand =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      hideFields.par_axcompany =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      hideFields.par_payroll_company =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      hideFields.par_bpc =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      // job_code is displayed but is non-editable for RM & HR Action
      hideFields.job_code =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);

      // requisition_id is displayed and editable for RM Action
      // requisition_id is displayed but is non-editable for HR Action
      hideFields.requisition_id =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isHRAction || isRMAction);
      showFieldsReadOnly.requisition_id = isPARSubmissionsQueue
        ? true
        : !isRMAction;
      hideFields.position_id =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isHRAction || isRMAction);
      showFieldsReadOnly.position_id = isPARSubmissionsQueue
        ? true
        : !isRMAction;
      hideFields.compa_ratio_of_offer = !(
        isHRAction ||
        isBrandCEO ||
        isFinance
      );
      hideFields.percent_increase = !(isHRAction || isBrandCEO || isFinance);

      showFieldsReadOnly.salary_budget = isPARSubmissionsQueue
        ? true
        : !isFinance;

      // Hide internal fill fields for RM action and finance for TBH workflo
      hideFields.current_performance_rating =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.current_base_salary =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.date_of_last_compensation_change =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.date_of_lastpromotion =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.requested_offer =
        isRMRole && isPARSubmissionsQueue ? true : isRMAction;
      // Finance, Brand CEO Hiring Manager is hidden
      hideFields.hiring_manager =
        (isFinanceRole || isBrandCEORole) && isPARSubmissionsQueue
          ? true
          : isBrandCEO;

      hideFields.approved_offer = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );
      hideFields.approved_effective_date = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );

      showFieldsReadOnly.approved_offer = !(isBrandCEO || isFinance);
      showFieldsReadOnly.approved_effective_date = !(isBrandCEO || isFinance);

      hideFields.decision = !(isRMAction || isHRAction);
    }
  };

  const urgentCounterWorkflowFieldVisibility = () => {
    if (isSubmitterRole) {
      hideFields.ticket_status = true;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.allocReconcilable = false;
      hideFields.par_billable = false;
      hideFields.profit_center = false;
      hideFields.salary_budget = false;

      showFieldsReadOnly.open_requisition_reason = showDescription;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
    } else if (
      (!isPARSubmissionsQueue && (isHRIntake || isExecutive)) ||
      (isExecutiveRole && isPARSubmissionsQueue)
    ) {
      hideFields.par_billable = false;
      hideFields.profit_center = false;
      hideFields.allocReconcilable = false;
      hideFields.par_level = false;
      hideFields.job_code = false;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.salary_budget = false;
      hideFields.compa_ratio_of_offer = false;
      hideFields.percent_increase = false;

      showFieldsReadOnly.current_performance_rating = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.current_base_salary = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.date_of_last_compensation_change =
        isPARSubmissionsQueue ? true : !isHRIntake;
      showFieldsReadOnly.date_of_lastpromotion = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.requested_offer = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.business_title = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.target_effective_date = isPARSubmissionsQueue
        ? true
        : isExecutive;
      showFieldsReadOnly.job_code = isPARSubmissionsQueue ? true : !isHRIntake;

      hideFields.approved_offer = !isExecutive;
      hideFields.approved_effective_date = !isExecutive;

      showFieldsReadOnly.approved_offer = !isExecutive;
      showFieldsReadOnly.approved_effective_date = !isExecutive;
    } else if (
      (!isPARSubmissionsQueue && isHRAction) ||
      (isHRRole && isPARSubmissionsQueue)
    ) {
      hideFields.allocReconcilable = false;
      hideFields.par_level = false;
      hideFields.profit_center = false;
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.salary_budget = false;
      hideFields.compa_ratio_of_offer = false;
      hideFields.percent_increase = false;
      hideFields.job_code = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.current_performance_rating = false;
      hideFields.requested_offer = false;
      showFieldsReadOnly.target_effective_date = true;

      hideFields.approved_offer = !isHRAction;
      hideFields.approved_effective_date = !isHRAction;

      hideFields.decision = !isHRAction;
    }
  };

  const backfillRequestWorkflowFieldVisibility = () => {
    hideFields.backfill_reason = false;
    hideFields.open_requisition_reason = false;
    hideFields.relatedPARTickets = false;
    if (isSubmitterRole) {
      hideFields.ticket_status = true;
      hideFields.is_exempt = false;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.addAllocationBtn = showDescription;
      hideFields.deleteAllocationBtn = showDescription;

      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.business_title = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.backfill_reason = showDescription;
      showFieldsReadOnly.is_exempt = showDescription;
    } else if (isRMIntake) {
      hideFields.allocRateCard = false;
      hideFields.allocReconcilable = false;
      hideFields.partner = false;
      hideFields.partner_cost = false;
      hideFields.offshore_role = false;
      hideFields.profit_center = false;
      hideFields.is_exempt = false;
      hideFields.par_brand = false;
      hideFields.par_axcompany = false;
      hideFields.par_payroll_company = false;
      hideFields.par_bpc = false;
      hideFields.salary_budget = false;
      hideFields.addAllocationBtn = false;
      hideFields.deleteAllocationBtn = false;
      hideFields.par_level = false;

      // All these fields are visible and editable for RM Intake
      showFieldsReadOnly.allocRateCard = false;
      showFieldsReadOnly.allocReconcilable = false;
      showFieldsReadOnly.profit_center = false;
      showFieldsReadOnly.partner = false;
      showFieldsReadOnly.offshore_role = false;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.allocClient = false;
      showFieldsReadOnly.allocLOB = false;
      showFieldsReadOnly.allocVal = false;
      showFieldsReadOnly.employee_name = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.file_upload = false;
    } else if (
      (!isPARSubmissionsQueue &&
        (isScaledService || isHRIntake || isExecutive)) ||
      (isScaledExRole && isPARSubmissionsQueue)
    ) {
      // Editable For HR Intake
      const editableForHRIntake = isPARSubmissionsQueue ? true : !isHRIntake;

      // Field Visible for isScaledService || isExecutive
      const isVisibleForScaledExRole =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;

      hideFields.par_level = false;
      hideFields.is_exempt = false;
      hideFields.relatedPARTickets = false;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.salary_budget = false;

      // Fields visible in HR Intake
      hideFields.job_code = !isHRIntake;
      hideFields.par_brand = !isHRIntake;
      hideFields.par_axcompany = !isHRIntake;
      hideFields.par_payroll_company = !isHRIntake;
      hideFields.par_bpc = !isHRIntake;

      // Hide fields for HR Intake Queue
      hideFields.partner = isVisibleForScaledExRole;
      hideFields.partner_cost = isVisibleForScaledExRole;
      hideFields.offshore_role = isVisibleForScaledExRole;

      // Hide target_budget in for Scaled Service Queue
      hideFields.target_budget = isScaledService;

      // Internal Fill Fields need to be enabled and editable for HRIntake Only

      // Hide compa_ratio_of_offer for Scaled Service
      hideFields.compa_ratio_of_offer =
        isExecutiveRole && isPARSubmissionsQueue ? false : isScaledService;

      hideFields.percent_increase =
        isExecutiveRole && isPARSubmissionsQueue ? false : isScaledService;

      // Executive level Hiring Manager is hidden
      hideFields.hiring_manager =
        isExecutiveRole && isPARSubmissionsQueue ? true : isExecutive;

      // Fields should be editable for HRIntake
      showFieldsReadOnly.job_code = editableForHRIntake;
      showFieldsReadOnly.is_exempt = editableForHRIntake;
      showFieldsReadOnly.current_performance_rating = editableForHRIntake;
      showFieldsReadOnly.current_base_salary = editableForHRIntake;
      showFieldsReadOnly.date_of_last_compensation_change = editableForHRIntake;
      showFieldsReadOnly.date_of_lastpromotion = editableForHRIntake;
      showFieldsReadOnly.requested_offer = editableForHRIntake;
      showFieldsReadOnly.business_title = editableForHRIntake;
      showFieldsReadOnly.target_budget = editableForHRIntake;

      hideFields.approved_offer = !isExecutive;
      hideFields.approved_effective_date = !isExecutive;

      showFieldsReadOnly.approved_offer = !isExecutive;
      showFieldsReadOnly.approved_effective_date = !isExecutive;
    } else if (
      (!isPARSubmissionsQueue &&
        (isFinance || isBrandCEO || isRMAction || isHRAction)) ||
      (isFinCEORMHRRole && isPARSubmissionsQueue)
    ) {
      // Field visible for HR OR RM Action/ROle and Par Submissions tab
      const visibleForHRRMAction =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isHRAction || isRMAction);

      // Hide Field for HR Action and visible
      const hideFieldForHRAction =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;

      // Hide Field For isFinance || isBrandCEO
      const hideFieldForFinanceAndBrand =
        (isFinanceRole || isBrandCEORole) && isPARSubmissionsQueue
          ? true
          : isFinance || isBrandCEO;

      // Field Editable for RM Action
      const editableForRMAction = isPARSubmissionsQueue ? true : !isRMAction;

      // Hide Field For Finance || RM Action
      // Hide Field For Finance Role || RM Role in PAR Submission tab
      const hideFieldForFinaceRMAction =
        (isFinanceRole || isRMRole) && isPARSubmissionsQueue
          ? true
          : isFinance || isRMAction;

      const hideFieldForRMAction =
        isRMRole && isPARSubmissionsQueue ? true : isRMAction;

      // Visible Field For Finance || Brand CEO
      // Visible Field For Finance Role || Brand CEO Role in PAR Submission tab
      const visibleForFinanceBrand =
        (isFinanceRole || isBrandCEORole) && isPARSubmissionsQueue
          ? false
          : !(isFinance || isBrandCEO);

      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.target_budget = false;
      hideFields.par_level = false;
      hideFields.profit_center = false;
      hideFields.salary_budget = false;
      hideFields.is_exempt = false;

      // Partner and PartnerCost is not shown in HRAction Queue, but shown in submitter view
      hideFields.partner = hideFieldForHRAction;
      hideFields.partner_cost = hideFieldForHRAction;

      // Hide Field For isFinance || isBrandCEO
      hideFields.par_brand = hideFieldForFinanceAndBrand;
      hideFields.par_axcompany = hideFieldForFinanceAndBrand;
      hideFields.par_payroll_company = hideFieldForFinanceAndBrand;
      hideFields.par_bpc = hideFieldForFinanceAndBrand;
      hideFields.job_code = hideFieldForFinanceAndBrand;

      hideFields.requisition_id = visibleForHRRMAction;
      hideFields.position_id = visibleForHRRMAction;
      hideFields.current_performance_rating = hideFieldForFinaceRMAction;
      hideFields.current_base_salary = hideFieldForFinaceRMAction;
      hideFields.date_of_last_compensation_change = hideFieldForFinaceRMAction;
      hideFields.date_of_lastpromotion = hideFieldForFinaceRMAction;

      hideFields.requested_offer = hideFieldForRMAction;
      hideFields.compa_ratio_of_offer = hideFieldForRMAction;
      hideFields.percent_increase = hideFieldForRMAction;
      hideFields.hiring_manager = !visibleForFinanceBrand;

      showFieldsReadOnly.requisition_id = editableForRMAction;
      showFieldsReadOnly.position_id = editableForRMAction;

      hideFields.approved_offer = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );
      hideFields.approved_effective_date = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );

      showFieldsReadOnly.approved_offer = !(isBrandCEO || isFinance);
      showFieldsReadOnly.approved_effective_date = !(isBrandCEO || isFinance);

      hideFields.decision = !(isRMAction || isHRAction);
    }
  };

  const openRequestTBHWorkflowFieldVisibility = () => {
    hideFields.open_requisition_reason = false;
    hideFields.relatedPARTickets = false;
    if (isSubmitterRole) {
      hideFields.ticket_status = true;
      hideFields.is_exempt = false;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.addAllocationBtn = showDescription;

      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.business_title = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;

      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.open_requisition_reason = showDescription;
      showFieldsReadOnly.is_exempt = showDescription;
    } else if (isRMIntake) {
      hideFields.allocRateCard = false;
      hideFields.allocReconcilable = false;
      hideFields.partner = false;
      hideFields.partner_cost = false;
      hideFields.offshore_role = false;
      hideFields.profit_center = false;
      hideFields.is_exempt = false;
      hideFields.par_brand = false;
      hideFields.par_axcompany = false;
      hideFields.par_payroll_company = false;
      hideFields.par_bpc = false;
      hideFields.salary_budget = false;
      hideFields.par_level = false;
      hideFields.par_billable = false;

      // All these fields are visible and editable for RM Intake
      showFieldsReadOnly.allocRateCard = false;
      showFieldsReadOnly.allocReconcilable = false;
      showFieldsReadOnly.profit_center = false;
      showFieldsReadOnly.partner = false;
      showFieldsReadOnly.offshore_role = false;
      showFieldsReadOnly.is_exempt = false;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.allocClient = false;
      showFieldsReadOnly.allocLOB = false;
      showFieldsReadOnly.allocVal = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.file_upload = false;
    } else if (
      (!isPARSubmissionsQueue &&
        (isScaledService || isHRIntake || isExecutive)) ||
      (isScaledExRole && isPARSubmissionsQueue)
    ) {
      hideFields.par_billable = false;
      hideFields.par_level = false;
      hideFields.is_exempt = false;
      hideFields.job_code = !isHRIntake;
      // Hide rateCard,reconcilable, profit_center fields for Scaled Service/HR Intake Queue
      // Partner and PartnerCost is not shown in HRIntake Queue
      hideFields.partner =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;
      hideFields.partner_cost =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;
      hideFields.offshore_role =
        isScaledExRole && isPARSubmissionsQueue ? false : isHRIntake;
      // PAR Brand, AxCompany, Payroll, BPC to be shown only for HR Intake
      hideFields.par_brand = !isHRIntake;
      hideFields.par_axcompany = !isHRIntake;
      hideFields.par_payroll_company = !isHRIntake;
      hideFields.par_bpc = !isHRIntake;
      // Exempt is shown for all fields for the relevant queues
      hideFields.target_budget = !(
        isHRIntake ||
        !isScaledService ||
        isExecutive
      );
      // job_code should be editable for HRIntake
      showFieldsReadOnly.job_code = isPARSubmissionsQueue ? true : !isHRIntake;
      showFieldsReadOnly.is_exempt = isPARSubmissionsQueue ? true : !isHRIntake;

      // Internal Fill Fields need to be enabled and editable for HRIntake Only
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.salary_budget = false;
      hideFields.compa_ratio_of_offer =
        isExecutiveRole && isPARSubmissionsQueue
          ? false
          : !(isHRIntake || isExecutive);
      hideFields.percent_increase =
        isExecutiveRole && isPARSubmissionsQueue
          ? false
          : !(isHRIntake || isExecutive);
      showFieldsReadOnly.current_performance_rating = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.current_base_salary = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.date_of_last_compensation_change =
        isPARSubmissionsQueue ? true : !isHRIntake;
      showFieldsReadOnly.date_of_lastpromotion = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.requested_offer = isPARSubmissionsQueue
        ? true
        : !isHRIntake;

      // HRIntake Queue should be able to edit business_title
      showFieldsReadOnly.business_title = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      // Executive level Hiring Manager is hidden
      hideFields.hiring_manager =
        isExecutiveRole && isPARSubmissionsQueue ? true : isExecutive;

      showFieldsReadOnly.target_budget = isPARSubmissionsQueue
        ? true
        : !isHRIntake;
      showFieldsReadOnly.target_effective_date = isPARSubmissionsQueue
        ? true
        : isExecutive;

      hideFields.approved_offer = !isExecutive;
      hideFields.approved_effective_date = !isExecutive;

      showFieldsReadOnly.approved_offer = !isExecutive;
      showFieldsReadOnly.approved_effective_date = !isExecutive;
    } else if (
      (!isPARSubmissionsQueue &&
        (isFinance || isBrandCEO || isRMAction || isHRAction)) ||
      (isFinCEORMHRRole && isPARSubmissionsQueue)
    ) {
      hideFields.allocReconcilable = false;
      hideFields.target_budget = false;
      hideFields.allocRateCard = false;
      hideFields.par_level = false;
      hideFields.profit_center = false;
      hideFields.par_level = false;
      hideFields.target_budget = false;
      hideFields.salary_budget = false;
      hideFields.par_billable = false;
      hideFields.is_exempt = false;
      showFieldsReadOnly.target_effective_date = false;

      showFieldsReadOnly.fill_date = true;
      // Partner and PartnerCost is not shown in HRAction Queue, but shown in submitter view
      hideFields.partner =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;
      hideFields.partner_cost =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;
      hideFields.offshore_role =
        isHRRole && isPARSubmissionsQueue ? false : isHRAction;
      // PAR Brand, AxCompanym Payroll, BPC to be shown only for RM & HR action
      hideFields.par_brand =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      hideFields.par_axcompany =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      hideFields.par_payroll_company =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      hideFields.par_bpc =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);
      // job_code is displayed but is non-editable for RM & HR Action
      hideFields.job_code =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isRMAction || isHRAction);

      // requisition_id is displayed and editable for RM Action
      // requisition_id is displayed but is non-editable for HR Action
      hideFields.requisition_id =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isHRAction || isRMAction);
      hideFields.position_id =
        (isHRRole || isRMRole) && isPARSubmissionsQueue
          ? false
          : !(isHRAction || isRMAction);
      showFieldsReadOnly.requisition_id = isPARSubmissionsQueue
        ? true
        : !isRMAction;
      showFieldsReadOnly.position_id = isPARSubmissionsQueue
        ? true
        : !isRMAction;
      hideFields.compa_ratio_of_offer = !(
        isHRAction ||
        isBrandCEO ||
        isFinance
      );
      hideFields.percent_increase = !(isHRAction || isBrandCEO || isFinance);

      showFieldsReadOnly.salary_budget = isPARSubmissionsQueue
        ? true
        : !isFinance;

      // Hide internal fill fields for RM action and finance for TBH workflo
      hideFields.current_performance_rating =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.current_base_salary =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.date_of_last_compensation_change =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.date_of_lastpromotion =
        isPARSubmissionsQueue && isRMRole ? true : isRMAction;
      hideFields.requested_offer =
        isRMRole && isPARSubmissionsQueue ? true : isRMAction;
      // Finance, Brand CEO Hiring Manager is hidden
      hideFields.hiring_manager =
        (isFinanceRole || isBrandCEORole) && isPARSubmissionsQueue
          ? true
          : isFinance || isBrandCEO;

      hideFields.approved_offer = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );
      hideFields.approved_effective_date = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );

      showFieldsReadOnly.approved_offer = !(isBrandCEO || isFinance);
      showFieldsReadOnly.approved_effective_date = !(isBrandCEO || isFinance);

      hideFields.decision = !(isRMAction || isHRAction);
    }
  };

  const reallocationTBHWorkflowFieldVisibility = () => {
    hideFields.deleteAllocationBtn = true;
    hideFields.par_billable = false;
    hideFields.profit_center = true;
    hideFields.relatedPARTickets = false;
    hideFields.par_level = false;
    hideFields.salary_budget = true;
    hideFields.business_title = false;
    hideFields.addAllocationBtn = true;
    if (isSubmitterRole) {
      hideFields.ticket_status = true;
      hideFields.allocReconcilable = true;
      showFieldsReadOnly.open_requisition_reason = showDescription;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
      showFieldsReadOnly.reallocation_employee_type = showDescription;
      showFieldsReadOnly.reallocation_level = showDescription;
      showFieldsReadOnly.reallocation_reason = showDescription;
      showFieldsReadOnly.reallocation_serviceline_department = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
      showFieldsReadOnly.allocClient = true;
      showFieldsReadOnly.allocLOB = true;
      showFieldsReadOnly.allocVal = true;
    } else if (isRMIntake) {
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      showFieldsReadOnly.allocRateCard = false;
      showFieldsReadOnly.allocVal = false;
      showFieldsReadOnly.allocReconcilable = false;
      showFieldsReadOnly.profit_center = false;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.reallocation_employee_type = true;
      showFieldsReadOnly.reallocation_level = true;
      showFieldsReadOnly.reallocation_reason = true;
      showFieldsReadOnly.reallocation_serviceline_department = true;
      showFieldsReadOnly.target_effective_date = true;
      showFieldsReadOnly.file_upload = false;
    } else if (
      (!isPARSubmissionsQueue && (isScaledService || isFinance)) ||
      (isPARSubmissionsQueue && (isScaledExRole || isFinanceRole))
    ) {
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      showFieldsReadOnly.allocRateCard = true;
      showFieldsReadOnly.allocVal = true;
      showFieldsReadOnly.allocReconcilable = true;
      showFieldsReadOnly.profit_center = true;
      showFieldsReadOnly.related_pars = true;
      showFieldsReadOnly.cc_emails = true;
      showFieldsReadOnly.reallocation_employee_type = true;
      showFieldsReadOnly.reallocation_level = true;
      showFieldsReadOnly.reallocation_reason = true;
      showFieldsReadOnly.reallocation_serviceline_department = true;
      showFieldsReadOnly.target_effective_date = true;
      hideFields.approved_offer = !isFinance;
      hideFields.approved_effective_date = !isFinance;
      showFieldsReadOnly.approved_offer = !isFinance;
      showFieldsReadOnly.approved_effective_date = !isFinance;
    } else if (
      (!isPARSubmissionsQueue && isRMAction) ||
      (isPARSubmissionsQueue && isRMRole)
    ) {
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      hideFields.decision = false;
      showFieldsReadOnly.allocRateCard = true;
      showFieldsReadOnly.allocVal = true;
      showFieldsReadOnly.allocReconcilable = true;
      showFieldsReadOnly.profit_center = true;
      showFieldsReadOnly.related_pars = true;
      showFieldsReadOnly.cc_emails = true;
      showFieldsReadOnly.reallocation_employee_type = true;
      showFieldsReadOnly.reallocation_level = true;
      showFieldsReadOnly.reallocation_reason = true;
      showFieldsReadOnly.reallocation_serviceline_department = true;
      showFieldsReadOnly.target_effective_date = true;
      hideFields.approved_offer = !isRMAction;
      hideFields.approved_effective_date = !isRMAction;
    }
  };

  const salaryAdjustmentWorkflowFieldVisibility = () => {
    hideFields.par_billable = false;
    hideFields.profit_center = false;
    hideFields.relatedPARTickets = false;
    if (isSubmitterRole) {
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.addAllocationBtn = showDescription;

      showFieldsReadOnly.business_title = showDescription;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
      showFieldsReadOnly.comment = showDescription;
    } else if (isRMIntake) {
      hideFields.par_level = false;
      hideFields.allocRateCard = false;
      hideFields.allocReconcilable = false;
      hideFields.salary_budget = false;
      hideFields.related_pars = false;
      hideFields.cc_emails = false;

      showFieldsReadOnly.allocReconcilable = false;
      showFieldsReadOnly.allocRateCard = false;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.file_upload = false;
    } else if (
      (!isPARSubmissionsQueue &&
        (isScaledService || isHRIntake || isExecutive)) ||
      (isScaledExRole && isPARSubmissionsQueue)
    ) {
      // Editable For HR Intake
      const editableForHRIntake = isPARSubmissionsQueue ? true : !isHRIntake;

      // Hide Field for Scaled Service
      const hideForScaledService =
        isPARSubmissionsQueue && isScaledServiceRole ? true : isScaledService;

      hideFields.par_level = false;
      hideFields.salary_budget = false;
      hideFields.business_title = false;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.target_effective_date = false;
      hideFields.cc_emails = false;
      hideFields.allocRateCard =
        isScaledServiceRole && isPARSubmissionsQueue ? false : isHRIntake;
      hideFields.allocReconcilable =
        isScaledServiceRole && isPARSubmissionsQueue ? false : isHRIntake;
      hideFields.job_code = hideForScaledService;
      hideFields.compa_ratio_of_offer = hideForScaledService;
      hideFields.percent_increase = hideForScaledService;

      showFieldsReadOnly.business_title = editableForHRIntake;
      showFieldsReadOnly.current_performance_rating = editableForHRIntake;
      showFieldsReadOnly.current_base_salary = editableForHRIntake;
      showFieldsReadOnly.date_of_last_compensation_change = editableForHRIntake;
      showFieldsReadOnly.date_of_lastpromotion = editableForHRIntake;
      showFieldsReadOnly.requested_offer = editableForHRIntake;
      showFieldsReadOnly.job_code = editableForHRIntake;
      showFieldsReadOnly.compa_ratio_of_offer = editableForHRIntake;
      showFieldsReadOnly.target_effective_date = editableForHRIntake;
      hideFields.approved_offer = !isExecutive;
      hideFields.approved_effective_date = !isExecutive;

      showFieldsReadOnly.approved_offer = !isExecutive;
      showFieldsReadOnly.approved_effective_date = !isExecutive;
    } else if (
      (!isPARSubmissionsQueue &&
        (isFinance || isBrandCEO || isRMAction || isHRAction)) ||
      (isFinCEORMHRRole && isPARSubmissionsQueue)
    ) {
      hideFields.par_level = false;
      hideFields.salary_budget = false;
      hideFields.business_title = false;
      hideFields.target_effective_date = false;
      hideFields.cc_emails = false;
      hideFields.allocRateCard = false;
      hideFields.allocReconcilable = false;

      hideFields.requested_offer =
        isRMRole && isPARSubmissionsQueue ? true : isRMIntake;
      hideFields.job_code = isRMRole && isPARSubmissionsQueue;
      hideFields.compa_ratio_of_offer = isRMRole && isPARSubmissionsQueue;
      hideFields.percent_increase = isRMRole && isPARSubmissionsQueue;

      hideFields.current_performance_rating =
        isHRRole && isPARSubmissionsQueue ? false : !isBrandCEO && !isHRAction;
      hideFields.current_base_salary =
        isHRRole && isPARSubmissionsQueue ? false : !isBrandCEO && !isHRAction;
      hideFields.date_of_last_compensation_change =
        isHRRole && isPARSubmissionsQueue ? false : !isBrandCEO && !isHRAction;
      hideFields.date_of_lastpromotion =
        isHRRole && isPARSubmissionsQueue ? false : !isBrandCEO && !isHRAction;

      showFieldsReadOnly.target_effective_date = true;

      hideFields.approved_offer = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );
      hideFields.approved_effective_date = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction
      );

      showFieldsReadOnly.approved_offer = !(isBrandCEO || isFinance);
      showFieldsReadOnly.approved_effective_date = !(isBrandCEO || isFinance);

      hideFields.decision = !isHRAction;
    }
  };

  const promotionInPlaceWorkFlowFieldVisibility = () => {
    hideFields.relatedPARTickets = false;
    if (isSubmitterRole) {
      hideFields.is_exempt = false;
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.par_billable = false;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.open_requisition_reason = showDescription;
      showFieldsReadOnly.is_exempt = showDescription;
      showFieldsReadOnly.requested_level = showDescription;
      showFieldsReadOnly.promotion_business_title = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
    } else if (isRMIntake) {
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      hideFields.salary_budget = false;
      hideFields.par_brand = false;
      hideFields.par_axcompany = false;
      hideFields.par_payroll_company = false;
      hideFields.par_bpc = false;
      showFieldsReadOnly.allocReconcilable = false;
      showFieldsReadOnly.allocRateCard = false;
      showFieldsReadOnly.profit_center = false;
      showFieldsReadOnly.par_axcompany = true;
      showFieldsReadOnly.par_brand = true;
      showFieldsReadOnly.par_payroll_company = true;
      showFieldsReadOnly.par_bpc = true;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.file_upload = false;
      showFieldsReadOnly.promotion_business_title = showDescription;
      showFieldsReadOnly.requested_level = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
    } else if (isScaledServiceRole) {
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.salary_budget = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.current_performance_rating = false;
      hideFields.requested_offer = false;
      showFieldsReadOnly.requested_level = showDescription;
      showFieldsReadOnly.promotion_business_title = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
    } else if (isHRIntake) {
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.salary_budget = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.current_performance_rating = false;
      hideFields.requested_offer = false;
      hideFields.compa_ratio_of_offer = false;
      hideFields.percent_increase = false;
      hideFields.job_code = false;
      hideFields.par_brand = false;
      hideFields.par_axcompany = false;
      hideFields.par_payroll_company = false;
      hideFields.par_bpc = false;
      showFieldsReadOnly.business_title = false;
      showFieldsReadOnly.current_base_salary = false;
      showFieldsReadOnly.date_of_last_compensation_change = false;
      showFieldsReadOnly.date_of_lastpromotion = false;
      showFieldsReadOnly.current_performance_rating = false;
      showFieldsReadOnly.requested_level = showDescription;
      showFieldsReadOnly.requested_offer = false;
      showFieldsReadOnly.job_code = false;
    } else if (
      isFinance ||
      isBrandCEO ||
      isRMAction ||
      isHRAction ||
      isExecutive
    ) {
      hideFields.par_level = false;
      hideFields.par_billable = false;
      hideFields.salary_budget = false;
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      hideFields.requested_offer = false;
      hideFields.compa_ratio_of_offer = false;
      hideFields.percent_increase = false;
      if (isBrandCEO || isHRAction) {
        hideFields.current_base_salary = false;
        hideFields.date_of_last_compensation_change = false;
        hideFields.date_of_lastpromotion = false;
        hideFields.current_performance_rating = false;
      }
      if (isRMAction || isHRAction) {
        hideFields.requested_offer = showDescription;
        hideFields.compa_ratio_of_offer = showDescription;
        hideFields.percent_increase = false;
        hideFields.job_code = false;
        hideFields.par_brand = false;
        hideFields.par_axcompany = false;
        hideFields.par_payroll_company = false;
        hideFields.par_bpc = false;
        hideFields.position_id = false;
      }
      if (isExecutive) {
        hideFields.allocReconcilable = showDescription;
        hideFields.allocRateCard = showDescription;
        hideFields.profit_center = showDescription;
      }
      if (isHRAction) {
        hideFields.requested_offer = false;
        hideFields.compa_ratio_of_offer = false;
        hideFields.percent_increase = false;
        hideFields.position_id = false;
      }
      showFieldsReadOnly.requested_level = showDescription;
      showFieldsReadOnly.promotion_business_title = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
      showFieldsReadOnly.position_id = isRMAction
        ? !showDescription
        : showDescription;

      hideFields.approved_offer = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction ||
        isExecutive
      );
      hideFields.approved_effective_date = !(
        isBrandCEO ||
        isFinance ||
        isRMAction ||
        isHRAction ||
        isExecutive
      );

      showFieldsReadOnly.approved_offer = !(
        isBrandCEO ||
        isFinance ||
        isExecutive
      );
      showFieldsReadOnly.approved_effective_date = !(
        isBrandCEO ||
        isFinance ||
        isExecutive
      );

      hideFields.decision = !(isRMAction || isHRAction);
    }
  };

  const reallocationActiveWorkflowFieldVisibility = () => {
    hideFields.deleteAllocationBtn = true;
    hideFields.par_billable = false;
    hideFields.profit_center = true;
    hideFields.relatedPARTickets = false;
    hideFields.par_level = false;
    hideFields.salary_budget = true;
    hideFields.business_title = false;
    hideFields.addAllocationBtn = true;
    if (isSubmitterRole) {
      hideFields.par_level = true;
      hideFields.allocReconcilable = true;
      showFieldsReadOnly.open_requisition_reason = showDescription;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
      showFieldsReadOnly.comment = showDescription;
      showFieldsReadOnly.fill_date = showDescription;
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.cc_emails = showDescription;
      showFieldsReadOnly.reallocation_employee_type = showDescription;
      showFieldsReadOnly.reallocation_level = showDescription;
      showFieldsReadOnly.reallocation_reason = showDescription;
      showFieldsReadOnly.reallocation_serviceline_department = showDescription;
      showFieldsReadOnly.target_effective_date = showDescription;
      showFieldsReadOnly.reallocation_reassign_allocation_to = showDescription;
      showFieldsReadOnly.allocClient = true;
      showFieldsReadOnly.allocLOB = true;
      showFieldsReadOnly.allocVal = true;
    } else if (isRMIntake) {
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      showFieldsReadOnly.allocRateCard = true;
      showFieldsReadOnly.allocVal = true;
      showFieldsReadOnly.allocReconcilable = true;
      showFieldsReadOnly.profit_center = true;
      showFieldsReadOnly.related_pars = false;
      showFieldsReadOnly.cc_emails = false;
      showFieldsReadOnly.reallocation_reassign_allocation_to = true;
      showFieldsReadOnly.reallocation_reason = true;
      showFieldsReadOnly.target_effective_date = true;
      showFieldsReadOnly.file_upload = false;
    } else if (
      (!isPARSubmissionsQueue && (isScaledService || isFinance)) ||
      (isPARSubmissionsQueue && (isScaledExRole || isFinanceRole))
    ) {
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      showFieldsReadOnly.allocRateCard = true;
      showFieldsReadOnly.allocVal = true;
      showFieldsReadOnly.allocReconcilable = true;
      showFieldsReadOnly.profit_center = true;
      showFieldsReadOnly.related_pars = true;
      showFieldsReadOnly.cc_emails = true;
      showFieldsReadOnly.reallocation_reason = true;
      showFieldsReadOnly.reallocation_reassign_allocation_to = true;
      showFieldsReadOnly.target_effective_date = true;

      hideFields.approved_offer = !isFinance;
      hideFields.approved_effective_date = !isFinance;

      showFieldsReadOnly.approved_offer = !isFinance;
      showFieldsReadOnly.approved_effective_date = !isFinance;
    } else if (
      (!isPARSubmissionsQueue && isRMAction) ||
      (isPARSubmissionsQueue && isRMRole)
    ) {
      hideFields.allocReconcilable = false;
      hideFields.allocRateCard = false;
      hideFields.profit_center = false;
      hideFields.decision = false;
      showFieldsReadOnly.allocRateCard = true;
      showFieldsReadOnly.allocVal = true;
      showFieldsReadOnly.allocReconcilable = true;
      showFieldsReadOnly.profit_center = true;
      showFieldsReadOnly.related_pars = true;
      showFieldsReadOnly.cc_emails = true;
      showFieldsReadOnly.reallocation_reason = true;
      showFieldsReadOnly.reallocation_reassign_allocation_to = true;
      showFieldsReadOnly.target_effective_date = true;

      hideFields.approved_offer = !isRMAction;
      hideFields.approved_effective_date = !isRMAction;
    }
  };

  const setFieldVisibilityAndStatus = () => {
    if (isAddToStaffRequestWorkflow) {
      addToStaffWorkflowFieldVisibility();
    } else if (isOpenRequestTBHWorkflow) {
      openRequestTBHWorkflowFieldVisibility();
    } else if (isUrgentCounterOfferWorkflow) {
      urgentCounterWorkflowFieldVisibility();
    } else if (isBackfillWorkflow) {
      backfillRequestWorkflowFieldVisibility();
    } else if (isReallocationTBH) {
      reallocationTBHWorkflowFieldVisibility();
    } else if (isPromotionInPlace) {
      promotionInPlaceWorkFlowFieldVisibility();
    } else if (isSalaryAdjustmentWorkflow) {
      salaryAdjustmentWorkflowFieldVisibility();
    } else if (isReallocationActive) {
      reallocationActiveWorkflowFieldVisibility();
    }
  };

  const getSalaryBudget = (data) =>
    parseFloat(
      data?.allocations?.reduce(
        (sum, currentValue) =>
          sum +
          ((currentValue.rate_card || "").toString().replace(",", "")
            ? (currentValue.rate_card || "").toString().replace(",", "") *
              currentValue.allocation
            : 0),
        0
      )
    ).toFixed(2);

  const getInitialValues = (inputData) => {
    if (initialData) {
      inputData = initialData;
    }
    setFieldVisibilityAndStatus();

    if (!inputData) {
      inputData = {};
    }
    if (isAddToStaffRequestWorkflow) {
      inputData.open_requisition_reason =
        ParConstants.REQUISITION_PRACTICE_INVST;
    }
    const formInitialValues = {};
    formInitialValues.allocations = [];
    const formFields = Object.keys(ParConstants.FORM_FIELDS);
    formFields.forEach((field) => {
      if (ParConstants.LIST_FIELDS.includes(field)) {
        if (inputData[field]) {
          if (field === "cc_emails" || field === "related_pars") {
            formInitialValues[field] = inputData[field].split(",").map((x) => ({
              value: x,
              label: x
            }));
          } else {
            formInitialValues[field] = {
              value: inputData[field],
              label: inputData[field]
            };
          }
        }
      } else if (field === "allocations") {
        formInitialValues[field] = [];
        if (inputData[field] && inputData[field].length > 0) {
          inputData[field].forEach((item, count) => {
            let allocationRow = {
              client_brand: null,
              client_lob: null,
              allocation: item.allocation || "",
              rate_card: item.rateCard || item.rate_card || "",
              id: item.id,
              allocation_id: count
            };
            if (item.client_brand) {
              allocationRow.client_brand = {
                value: item.client_brand,
                label: item.client_brand
              };
            }
            if (item.client_lob) {
              allocationRow.client_lob = {
                value: item.client_lob || "",
                label: item.client_lob
              };
            }
            if (item.status) {
              allocationRow.status = {
                value: item.status,
                label: item.status
              };
            }
            allocationRow.reconcilable = item.reconcilable || "";
            allocationRow.rate_card = item.rate_card || "";
            allocationRow.disableAllocation = item.disableAllocation || false;
            formInitialValues[field].push(allocationRow);
          });
        }
      } else if (
        field === "fill_date" ||
        field === "date_of_last_compensation_change" ||
        field === "date_of_lastpromotion" ||
        field === "target_effective_date" ||
        field === "approved_effective_date"
      ) {
        formInitialValues[field] = "";
        if (inputData[field]) {
          // Convert to date which is required for the datePicker field
          const convertedDate = new Date(inputData[field]);
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
        } else if (isFinance && field === "approved_effective_date") {
          const convertedDate = inputData.fill_date
            ? new Date(inputData.fill_date)
            : new Date();
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
        }
      } else if (field === "approved_offer" && !inputData[field]) {
        formInitialValues[field] = inputData.requested_offer;
      } else if (inputData[field]) {
        formInitialValues[field] = inputData[field];
      } else if (DEFAULT_VALUES[field] !== undefined) {
        formInitialValues[field] = DEFAULT_VALUES[field];
      } else if (field === "current_queue_id") {
        formInitialValues[field] = null;
      } else if (field === "decision") {
        formInitialValues[field] = `${inputData?.ticket_status}, ${
          inputData.updated_date
            ? format(new Date(inputData.updated_date), "MM/dd/yyyy")
            : ""
        }, ${inputData?.updatedbyname}`;
      } else {
        formInitialValues[field] = "";
      }
    });
    // Do not show comments for a submitter when the ticket is already
    // in a queue, so that they can withdraw the ticket correctly
    // after entering a comment
    if (
      isSubmitterRole &&
      inputData.ticket_status &&
      inputData.ticket_status !== ParConstants.PAR_ACTIONS.WITHDRAWN
    ) {
      formInitialValues.comment = "";
    }
    formInitialValues.current_queue_name = inputData.current_queue_name;
    const budgetValue = parseFloat(inputData.salary_budget);
    const salaryBudget = Number.isNaN(budgetValue) ? null : budgetValue;
    formInitialValues.salary_budget =
      salaryBudget || getSalaryBudget(inputData);
    return formInitialValues;
  };

  const getPARAvailableMSLPINs = async () => {
    try {
      const res = await api.getAvailablePins({ countryId: 1 });
      return res;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };

  const getPARPayRanges = async () => {
    try {
      const res = await api.getPARPayRanges();
      return res;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };

  const getGlobalCostCardData = async () => {
    try {
      const data = await api.getGlobalCostData();
      return data;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (!parAvailablePins || parAvailablePins.length === 0) {
      getPARAvailableMSLPINs().then((data) => {
        if (isMounted) setAvailablePINs(data || []);
      });
    }
    if (!parPayRanges || parPayRanges.length === 0) {
      getPARPayRanges().then((data) => {
        if (isMounted) setPayRanges(data || []);
      });
    }
    if (isRMIntake) {
      getGlobalCostCardData().then((data) => {
        if (isMounted) setGlobalCostData(data || []);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getFormData = (data) => {
    let newData = {};
    for (let prop in data) {
      if (ParConstants.LIST_FIELDS.indexOf(prop) > -1) {
        if (prop === "cc_emails" || prop === "related_pars") {
          newData[prop] = data[prop]?.map((x) => x.value).join();
        } else {
          newData[prop] = data[prop]?.value;
        }
      } else if (prop === "allocations") {
        const allocations = JSON.parse(JSON.stringify(data[prop]));
        allocations.forEach((allocation) => {
          allocation.client_brand = allocation.client_brand.value;
          allocation.client_lob = allocation.client_lob?.value;
          allocation.status = allocation.status?.value;
          allocation.rate_card = allocation?.rate_card?.replace(/,/g, "") || 0;
          allocation.reconcilable = allocation.reconcilable || "";
        });
        newData[prop] = allocations;
      } else if (
        prop === "date_of_last_compensation_change" ||
        prop === "requested_offer" ||
        prop === "current_base_salary" ||
        prop === "date_of_lastpromotion" ||
        prop === "fill_date" ||
        prop === "target_effective_date" ||
        prop === "approved_effective_date" ||
        prop === "approved_offer"
      ) {
        newData[prop] = data[prop] !== "" ? data[prop] : null;
      } else {
        newData[prop] = data[prop];
      }
    }
    return newData;
  };

  const handleSubmit = async () => {
    const formData = getFormData(abuseFormikProps.values);
    setSaved(false);
    let response = {};
    try {
      // By default workflow_id is always for "Add To Staff"
      let workflowId = 1;
      if (parType && parType.workflow_id) {
        workflowId = parType.workflow_id;
      }
      // Set the incoming MSL PIN as request_id if present
      if (
        !formData.par_request_id &&
        initialData &&
        initialData.existing_msl_pin
      ) {
        formData.par_request_id = initialData.existing_msl_pin;
      }
      if (parType.name === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH) {
        if (
          formData.reallocation_reason ===
            ParConstants.REALLOCATION_REASONS[0] ||
          formData.reallocation_reason === ParConstants.REALLOCATION_REASONS[1]
        ) {
          formData.reallocation_employee_type = null;
          formData.reallocation_serviceline_department = null;
        }
        if (
          formData.reallocation_reason ===
            ParConstants.REALLOCATION_REASONS[2] ||
          formData.reallocation_reason === ParConstants.REALLOCATION_REASONS[3]
        ) {
          formData.reallocation_level = null;
          formData.reallocation_serviceline_department = null;
        }
        if (
          formData.reallocation_reason === ParConstants.REALLOCATION_REASONS[4]
        ) {
          formData.reallocation_employee_type = null;
          formData.reallocation_level = null;
        }
      }
      response = await api.savePARRequest({
        par_request_data: formData,
        par_workflow_id: workflowId,
        par_queue_id: abuseFormikProps.values.current_queue_id
      });
      if (response.success) {
        setParRequestId(response.par_request_id);
        abuseFormikProps.setFieldValue("par_request_id", {
          label: response.par_request_id,
          value: response.par_request_id
        });
        if (isSubmitterRole) {
          setRefreshForm(true);
        }
        setIsModalOpen(true);
        setModalMessage(
          `Your position action request (${response.par_request_id}) has been saved.`
        );
      } else {
        // Show error
        console.log("Error in saving PAR");
        console.log(response.error);
        toast.error(
          "There was a problem in saving the PAR",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in saving the PAR - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    setSaved(true);
  };

  const onSubmitPAR = async ({
    actionName = null,
    paramSuccessMessage = null,
    title = "Thank You"
  }) => {
    setSubmitting(true);
    if (abuseFormikProps.isValid) {
      let finalAction =
        actionName || ParConstants.PAR_ACTIONS.NEW_PAR_SUBMITTED;

      if (
        isGlobalEmployee(abuseFormikProps.values?.employee_type?.value) &&
        isUrgentCounterOfferWorkflow &&
        actionName === null
      ) {
        finalAction = ParConstants.PAR_ACTIONS.APPROVED_GLOBAL;
      }
      // default sucess message for Add To Staff PAR
      let finalSuccessMsg = "PAR Request Submitted Successfully";

      let comment = "";
      if (abuseFormikProps.values.comment) {
        comment = abuseFormikProps.values.comment;
      }
      await api
        .actionOnPARRequest({
          par_request_id: parRequestId,
          action: finalAction,
          comment
        })
        .then(
          (response) => {
            if (response.success) {
              // if nextqueue is returned and request is new PAR submission
              finalSuccessMsg = paramSuccessMessage || successMessage;
              finalSuccessMsg = finalSuccessMsg
                .replace("<<PARID>>", parRequestId)
                .replace("<<nextQueueMsg>>", response.nextQueueMsg || "");
              setModalMessage(finalSuccessMsg);
              setModalTitle(title);
              setIsModalOpen(true);
              setDoNavigate(true);
            } else {
              setModalTitle("Exception Occured");
              setModalMessage(response.error);
              setIsModalOpen(true);
            }
          },
          (error) => {
            setModalTitle("Exception Occured");
            setModalMessage(error);
            setIsModalOpen(true);
          }
        );
    }
    setSubmitting(false);
  };

  const onWithdrawPAR = async () => {
    // Call actionOnPARRequest API Method with withdrawn action
    await onSubmitPAR({
      actionName: ParConstants.PAR_ACTIONS.WITHDRAWN,
      paramSuccessMessage: "Your Position action request has been withdrawn",
      title: "Withdrawal Successful"
    });
  };

  const onDelete = () => {
    setModalType(Constants.MODAL_TYPE.DELETE);
    setIsConfirmModalOpen(true);
  };

  const onWithdraw = () => {
    setModalType(Constants.MODAL_TYPE.WITHDRAW);
    setIsConfirmModalOpen(true);
  };

  const onDeletePAR = async () => {
    setSubmitting(true);
    if (abuseFormikProps.isValid) {
      await api.deletePARRequest(parRequestId).then(
        (response) => {
          if (response.success) {
            setModalTitle("Ticket Deleted");
            setModalMessage(
              `PAR ${parRequestId} has been deleted successfully`
            );
            setIsModalOpen(true);
            setDoNavigate(true);
          } else {
            setModalMessage(response.error);
            setIsModalOpen(true);
          }
        },
        (error) => {
          setModalMessage(error);
          setIsModalOpen(true);
        }
      );
    }
    setSubmitting(false);
  };

  // Captures all business rules/actions based on the workflow
  const getActionTypeForWorkflow = () => {
    let actionName = ParConstants.PAR_ACTIONS.APPROVED;

    const isEmpTypeGlobal = isGlobalEmployee(
      abuseFormikProps.values?.employee_type?.value
    );

    const reqReason = abuseFormikProps.values?.open_requisition_reason?.value;
    const isClientCenter =
      abuseFormikProps.values?.summary_department?.value ===
      ParConstants.CLIENT_CENTER;

    // Remove all alphabets and convert to number
    const levelNum = getLevelInNumeric(
      abuseFormikProps.values?.par_level?.value
    );

    if (
      isUrgentCounterOfferWorkflow ||
      isAddToStaffRequestWorkflow ||
      isOpenRequestTBHWorkflow ||
      isReallocationTBH ||
      isBackfillWorkflow ||
      isPromotionInPlace ||
      isSalaryAdjustmentWorkflow ||
      isReallocationActive
    ) {
      if (isRMIntake) {
        actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED;
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_PRACTICE_INVT;
        }
        // Flag as client center request, so that the request skips scaled service
        if (isClientCenter) {
          actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_CLIENTCENTER;
          if (isEmpTypeGlobal) {
            actionName =
              ParConstants.PAR_ACTIONS.PIN_CONFIRMED_CLIENTCENTER_GLOBAL;
          }
        }
      } else if (isScaledService) {
        // If employeeType Global, skip HR and go to Finance
        if (isEmpTypeGlobal && !(isReallocationActive || isReallocationTBH)) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_GLOBAL;
        }
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = !isEmpTypeGlobal
            ? ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST
            : ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST_GLOBAL;
        }
      } else if (isHRIntake || isFinance) {
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST;
        }
        // If Finance Queue, for global employees the request needs to
        // move right ahead to the RM Action level
        if (
          isFinance &&
          isEmpTypeGlobal &&
          !(isReallocationActive || isReallocationTBH)
        ) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_GLOBAL;
        }
      } else if (isBrandCEO) {
        const {
          requested_offer: requestedOffer,
          current_base_salary: currentBaseSalary,
          approved_offer: approvedOffer
        } = abuseFormikProps.values;
        const offerValue = approvedOffer || requestedOffer;
        const percentageChange = getPercentageIncrease(
          currentBaseSalary,
          offerValue
        );
        const compaRatioOfOffer = ParHelpers.getCompRatioOfOffer(
          validations?.parGeoRange,
          parPayRanges,
          abuseFormikProps
        );
        // Check if Exception Request
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST;
        } else if (percentageChange > 20) {
          actionName =
            ParConstants.PAR_ACTIONS.APPROVED_PERCENT_INCREASE_EXCEPTION;
        } else if (compaRatioOfOffer > 1.2) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_COMPA_RATIO_EXCEPTION;
        } else {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_BELOW_50;

          if (levelNum >= 55) {
            actionName = ParConstants.PAR_ACTIONS.APPROVED_OVER_55;
          }
        }
      } else if (isRMAction) {
        // default action name
        actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE_RMACTION;
        // If Employee Type is Global (non us employee) we skip going to
        // HR Action and RM Action directly marks the ticket as complete
        if (isEmpTypeGlobal || isReallocationTBH || isReallocationActive) {
          actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE;
          successMessage = completeMessage;
        }
      } else if (isHRAction) {
        actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE;
        successMessage = completeMessage;
      } else if (isExecutive) {
        actionName = ParConstants.PAR_ACTIONS.APPROVED_EXCEPTION;
      }
    }
    // TODO: add conditions for other workflow types
    return {
      actionName,
      successMessage
    };
  };

  const onAdvancePAR = async () => {
    const actionObj = getActionTypeForWorkflow();
    // Call actionOnPARRequest API Method with relevant action
    await onSubmitPAR({
      actionName: actionObj.actionName,
      paramSuccessMessage: actionObj.successMessage
    });

    setSaved(false);
  };

  const onReturnPAR = async () => {
    // Call actionOnPARRequest API Method with relevant action
    let actionName = ParConstants.PAR_ACTIONS.RETURNED;
    let returnSuccessMessage = "PAR Request has been returned to the Submitter";

    if (isExecutive) {
      actionName = ParConstants.PAR_ACTIONS.RETURNED_TO_CEO;
      returnSuccessMessage = "PAR Request has been returned to Brand CEO Queue";

      // handles action name for Urgent counter workflow
      if (isUrgentCounterOfferWorkflow) {
        actionName = ParConstants.PAR_ACTIONS.RETURNED_TO_HR;
        returnSuccessMessage =
          "PAR Request has been returned to HR intake Queue";

        // for Employee type global return ticket to submitter
        // as HR intake queue is not applicable
        if (
          abuseFormikProps.values.employee_type?.value ===
          RapConstants.EMPLOYEE_TYPE_VALUES[1]
        ) {
          actionName = ParConstants.PAR_ACTIONS.RETURNED;
          returnSuccessMessage = "PAR Request has been returned to submitter";
        }
      }
    }
    await onSubmitPAR({
      actionName,
      paramSuccessMessage: returnSuccessMessage
    });
    setSaved(false);
  };

  const onViewHistory = async () => {
    setIsSidebarOpen(true);
  };

  const getInternalEmployeeWarning = () => {
    let message = null;
    const differentServicelineWarning = (
      <>
        The selected Employee’s <strong>Service Line Department</strong> does
        not match the value in PAR.
      </>
    );
    const differentLevelWarning = (
      <>
        The selected Employee’s <strong>Level</strong> does not match the value
        in PAR.
      </>
    );
    const levelSkipWarning = (
      <>The selected PAR Level skips the next available level for promotion.</>
    );
    const notaPromotionWarning = (
      <>The selected PAR Level is same/lesser than employee current level.</>
    );
    const fillType = abuseFormikProps.values.fill_type;
    const employeeName = abuseFormikProps.values.employee_name;
    let employee = null;
    if (employeeName) {
      employee = availablePINs.filter(
        (x) => x.employeeName === employeeName.value
      );
    }
    const parLevel = abuseFormikProps.values.par_level;
    const servicelineDepartment =
      abuseFormikProps.values.serviceline_department;
    if (
      fillType &&
      employee &&
      employee[0] &&
      servicelineDepartment &&
      parLevel
    ) {
      let employeeLevel = employee[0].level.match(/\d+/g);
      let selectedParLevel = parLevel.value.match(/\d+/g);
      const isDifferentDepartment =
        employee[0].serviceLineDepartment !== servicelineDepartment.value;

      if (fillType.value === ParConstants.FILL_TYPES[1]) {
        const isDifferentLevel = selectedParLevel[0] !== employeeLevel[0];
        if (isDifferentLevel && isDifferentDepartment) {
          message = (
            <>
              The selected Employee’s <strong>Level</strong> and{" "}
              <strong>Service Line Department</strong> does not match the value
              in PAR.
            </>
          );
        } else if (isDifferentLevel) {
          message = differentLevelWarning;
        } else if (isDifferentDepartment) {
          message = differentServicelineWarning;
        } else {
          message = null;
        }
      }
      if (fillType.value === ParConstants.FILL_TYPES[2]) {
        // Selected level needs to be more than employee's level
        const levelDiff = selectedParLevel[0] - employeeLevel[0];
        const isInValidPromotionLevel = levelDiff > 5;
        const isNotPromotionWarning = levelDiff <= 5;
        if (isDifferentDepartment && isInValidPromotionLevel) {
          message = (
            <>
              {differentServicelineWarning} {levelSkipWarning}
            </>
          );
        } else if (isDifferentDepartment && isNotPromotionWarning) {
          message = (
            <>
              {differentServicelineWarning} {notaPromotionWarning}
            </>
          );
        } else if (isInValidPromotionLevel) {
          message = levelSkipWarning;
        } else if (isNotPromotionWarning) {
          message = notaPromotionWarning;
        } else if (isDifferentDepartment) {
          message = differentServicelineWarning;
        } else {
          message = null;
        }
      }
    }
    return message;
  };

  const onConfirm = () => {
    setIsConfirmModalOpen(false);
    switch (modalType) {
      case Constants.MODAL_TYPE.DELETE:
        onDeletePAR();
        break;
      case Constants.MODAL_TYPE.WITHDRAW:
        onWithdrawPAR();
        break;
      case Constants.MODAL_TYPE.WARN:
        handleSubmit();
        break;
      case Constants.MODAL_TYPE.CONFIRM:
        break;
      default:
        break;
    }
  };

  const OnSaveAsDraft = () => {
    if (abuseFormikProps.isValid) {
      if (isSubmitterRole) {
        // Show the warning only for submitter role
        let message = null;
        const isExternalFillType = isExternalFill(
          abuseFormikProps.values?.fill_type?.value
        );
        if (!isExternalFillType) {
          message = getInternalEmployeeWarning();
        }
        if (message) {
          setWarningMessage(message);
          setModalType(Constants.MODAL_TYPE.WARN);
          setIsConfirmModalOpen(true);
        } else {
          handleSubmit();
        }
      } else {
        handleSubmit();
      }
    }
  };

  const OnCancel = () => {
    navigate("/par", {
      state: { currentTab: activeTab }
    });
  };

  const getAdvanceButtonText = () => {
    let buttonText = "Advance";
    if (isHRAction || (isRMAction && isGlobalEmployee)) {
      // Set button text as “Complete” since for global RM Action is the last step
      // or its at HRAction
      buttonText = "Complete";
    }
    return buttonText;
  };
  return (
    <>
      <div className="row sticky-header" id="sticky-header">
        <div className="col-md-12">
          {initialData &&
          initialData.par_request_id &&
          !initialData.existing_msl_pin
            ? `View PAR Details - ${parType?.name}`
            : parType
            ? `Create PAR - ${parType.name}`
            : "Create PAR - Add To Staff Request"}
        </div>
      </div>
      <div className="row col-md-12 page-title" id="page-title">
        <div className="col-md-6">
          {initialData &&
          initialData.par_request_id &&
          !initialData.existing_msl_pin
            ? "PAR - " + initialData.par_request_id
            : initialData && initialData.existing_msl_pin
            ? `New PAR - ${initialData.existing_msl_pin}`
            : `Add to Staff Form`}
        </div>
        {initialData && initialData.ticket_status && (
          <div className="col-md-6  d-flex align-self-center justify-end pr-0">
            <button
              type="button"
              id="btnViewTicketHistory"
              name="btnViewTicketHistory"
              className="btn btn-sm btn-secondary par-btn"
              onClick={onViewHistory}
            >
              View Ticket History
            </button>
          </div>
        )}
      </div>
      <div>
        <Formik
          enableReinitialize
          initialValues={getInitialValues(initialData)}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form id="CreateForm">
              {(abuseFormikProps = formikProps) && <></>}

              {checkSectionVisibility("PositionDetails") && (
                <PositionDetails
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  validations={validations || {}}
                  showDescription={showDescription}
                  workflowName={parType && parType.name}
                  showFieldsReadOnly={showFieldsReadOnly}
                  hideFields={hideFields}
                  getSalaryBudget={getSalaryBudget}
                />
              )}
              {checkSectionVisibility("EmployeeDetails") && (
                <EmployeeDetails
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  showFieldsReadOnly={showFieldsReadOnly}
                  workflowName={parType && parType.name}
                  hideFields={hideFields}
                  parPayRanges={payRanges || []}
                  parGeoRange={validations?.parGeoRange || []}
                  parType={parType}
                />
              )}

              {checkSectionVisibility("ReallocationInstructions") && (
                <ReallocationInstructions
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  showFieldsReadOnly={showFieldsReadOnly}
                  workflowName={parType && parType.name}
                  availablePINs={availablePINs || []}
                  validations={validations}
                  hideFields={hideFields}
                />
              )}

              {checkSectionVisibility("RequisitionInstructions") && (
                <RequisitionInstructions
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  availablePINs={availablePINs || []}
                  validations={validations || {}}
                  showDescription={showDescription}
                  workflowName={parType && parType.name}
                  showFieldsReadOnly={showFieldsReadOnly}
                  hideFields={hideFields}
                  parPayRanges={payRanges || []}
                  globalCostData={globalCostData}
                />
              )}

              {checkSectionVisibility("PromotionInstructions") && (
                <PromotionInstructions
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  availablePARRequests={availablePINs}
                  showDescription={showDescription}
                  showFieldsReadOnly={showFieldsReadOnly}
                  isSubmitterRole={isSubmitterRole}
                  hideFields={hideFields}
                  validations={validations || {}}
                  parPayRanges={payRanges || []}
                />
              )}

              {checkSectionVisibility("PositionJustification") && (
                <PositionJustification
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  availablePARRequests={availablePINs}
                  showDescription={showDescription}
                  showFieldsReadOnly={showFieldsReadOnly}
                  isSubmitterRole={isSubmitterRole}
                  isRMIntake={isRMIntake}
                  hideFields={hideFields}
                />
              )}

              {checkSectionVisibility("ParStatus") && (
                <ParStatus
                  fillType={abuseFormikProps.values.fill_type}
                  parType={parType && parType.name}
                  formikProps={formikProps}
                  showFieldsReadOnly={showFieldsReadOnly}
                  hideFields={hideFields}
                  parGeoRange={validations?.parGeoRange || []}
                  parPayRanges={parPayRanges || []}
                />
              )}

              {checkSectionVisibility("TicketAction") && (
                <TicketAction
                  formikProps={formikProps}
                  isDraft={!showDescription}
                  queueType={queueType}
                />
              )}

              <div className="row  d-flex align-self-center justify-end">
                <div className="mr-3">
                  <button
                    type="button"
                    id="btnCancel"
                    name="btnCancel"
                    className="btn btn-sm btn-link par-btn"
                    onClick={OnCancel}
                  >
                    Cancel
                  </button>
                </div>
                {/* Show Buttons only if PAR is not complete */}
                {!isCancelOnly && (
                  <>
                    {showDescription && isSubmitterRole && !isHRAction ? (
                      <div className="mr-3">
                        <button
                          type="button"
                          id="btnWithdraw"
                          name="btnWithdraw"
                          className="btn btn-sm btn-primary par-btn"
                          onClick={onWithdraw}
                          disabled={
                            !abuseFormikProps.values.current_queue_id ||
                            !abuseFormikProps.isValid ||
                            isSubmitting
                          }
                        >
                          Withdraw
                        </button>
                        &nbsp;
                        {isSubmitting && (
                          <i className="fas fa-spinner fa-pulse fa-lg" />
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="mr-3">
                          <button
                            type="button"
                            id="btnSaveAsDraft"
                            name="btnSaveAsDraft"
                            className="btn btn-sm btn-secondary par-btn"
                            onClick={OnSaveAsDraft}
                            disabled={
                              !abuseFormikProps.isValid ||
                              !abuseFormikProps.dirty
                            }
                          >
                            Save As Draft
                          </button>
                        </div>
                        {isSubmitterRole &&
                          parRequestId &&
                          !abuseFormikProps.values.ticket_status && (
                            <div className="mr-3">
                              <button
                                type="button"
                                id="btnSubmitPAR"
                                name="btnSubmitPAR"
                                className="btn btn-sm btn-secondary par-btn"
                                onClick={onDelete}
                                disabled={
                                  !abuseFormikProps.isValid || isSubmitting
                                }
                              >
                                Delete PAR
                              </button>
                            </div>
                          )}
                        <div>
                          {isSubmitterRole && (
                            <button
                              type="button"
                              id="btnSubmitPAR"
                              name="btnSubmitPAR"
                              className="btn btn-sm btn-primary par-btn"
                              onClick={onSubmitPAR}
                              disabled={
                                !parRequestId ||
                                !abuseFormikProps.isValid ||
                                isSubmitting ||
                                abuseFormikProps.dirty
                              }
                            >
                              Submit PAR
                            </button>
                          )}
                          {!isSubmitterRole && (
                            <button
                              type="button"
                              id="btnAdvancePAR"
                              name="btnAdvancePAR"
                              className="btn btn-sm btn-primary par-btn"
                              onClick={onAdvancePAR}
                              disabled={
                                !parRequestId ||
                                !abuseFormikProps.isValid ||
                                !isSaved ||
                                isSubmitting
                              }
                            >
                              {getAdvanceButtonText()}
                            </button>
                          )}
                        </div>
                        <div className="ml-3 mr-3">
                          {!isSubmitterRole && !isHRAction && !isRMAction && (
                            <button
                              type="button"
                              id="btnReturnPAR"
                              name="btnReturnPAR"
                              className="btn btn-sm btn-primary par-btn"
                              onClick={onReturnPAR}
                              disabled={
                                !parRequestId ||
                                !abuseFormikProps.isValid ||
                                !isSaved ||
                                isSubmitting
                              }
                            >
                              Return
                            </button>
                          )}
                          &nbsp;
                          {isSubmitting && (
                            <i className="fas fa-spinner fa-pulse fa-lg" />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
        <Modal isOpen={isModalOpen} title={modalTitle}>
          <div>{modalMessage}</div>
        </Modal>
      </div>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => onConfirm()}
        modalType={modalType}
      >
        <div>{warningMessage}</div>
      </ConfirmationModal>

      {initialData && initialData.ticket_status && (
        <Sidebar
          isOpen={isSidebarOpen}
          header="Ticket History"
          onClose={() => setIsSidebarOpen(false)}
          id="viewHistory"
        >
          <TicketHistory parRequestId={initialData?.par_request_id} />
        </Sidebar>
      )}
    </>
  );
};

ParForm.propTypes = {
  getValidationSchema: PropTypes.func,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array),
    parGeoRange: PropTypes.instanceOf(Array)
  }).isRequired,
  initialData: PropTypes.shape({
    par_request_id: PropTypes.string,
    current_queue_id: PropTypes.number,
    existing_msl_pin: PropTypes.string,
    current_queue_name: PropTypes.string,
    ticket_status: PropTypes.string,
    created_by: PropTypes.number
  }),
  parType: PropTypes.shape({
    workflow_id: PropTypes.number,
    name: PropTypes.string
  }),
  queueType: PropTypes.string,
  parAvailablePins: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  refreshForm: PropTypes.func,
  activeTab: PropTypes.string
};

ParForm.defaultProps = {
  parType: null,
  queueType: null,
  getValidationSchema: {},
  initialData: {
    par_request_id: null,
    current_queue_id: null,
    current_queue_name: null
  },
  parAvailablePins: [],
  parPayRanges: [],
  refreshForm: {},
  activeTab: ""
};

export default ParForm;
