import { useMemo } from "react";
import ParConstants from "../../../common/ParConstants";

const useWorkflowStatus = (parType) => {
  const {
    URGENT_COUNTER_OFFER,
    BACKFILL_REQUEST,
    ADD_TO_STAFF_REQUEST,
    OPEN_REQUEST_TBH,
    REALLOCATION_ACTIVE,
    REALLOCATION_TBH,
    PROMOTION_IN_PLACE,
    SALARY_ADJUSTMENT
  } = ParConstants.PAR_WORKFLOW;
  const workflowName = parType?.name;
  return useMemo(
    () => ({
      isUrgentCounterOfferWorkflow:
        workflowName?.includes(URGENT_COUNTER_OFFER),
      isBackfillWorkflow: workflowName === BACKFILL_REQUEST,
      isAddToStaffRequestWorkflow: workflowName === ADD_TO_STAFF_REQUEST,
      isOpenRequestTBHWorkflow: workflowName === OPEN_REQUEST_TBH,
      isReallocationActive: workflowName === REALLOCATION_ACTIVE,
      isReallocationTBH: workflowName === REALLOCATION_TBH,
      isPromotionInPlace: workflowName === PROMOTION_IN_PLACE,
      isSalaryAdjustmentWorkflow: workflowName === SALARY_ADJUSTMENT
    }),
    [parType]
  );
};

export default useWorkflowStatus;
