import { useMemo } from "react";
import ParConstants from "../../../common/ParConstants";

const useRoleStatus = (userRole) => {
  const {
    PAR_ClientBrandSubmitter,
    PAR_RM,
    PAR_HR,
    PAR_ScaledServiceLead,
    PAR_Finance,
    PAR_BrandCEO,
    PAR_Executive
  } = ParConstants.PAR_PERMISSIONS_MAPPING;
  return useMemo(() => {
    const roles = {
      isSubmitterRole: userRole === PAR_ClientBrandSubmitter,
      isRMRole: userRole === PAR_RM,
      isHRRole: userRole === PAR_HR,
      isScaledServiceRole: userRole === PAR_ScaledServiceLead,
      isFinanceRole: userRole === PAR_Finance,
      isBrandCEORole: userRole === PAR_BrandCEO,
      isExecutiveRole: userRole === PAR_Executive
    };

    // isFinance || isBrandCEO || isRMAction || isHRAction
    roles.isFinCEORMHRRole =
      roles.isHRRole ||
      roles.isRMRole ||
      roles.isBrandCEORole ||
      roles.isFinanceRole;

    // isScaledService || isExecutive
    roles.isScaledExRole = roles.isScaledServiceRole || roles.isExecutiveRole;

    return roles;
  }, [userRole]);
};

export default useRoleStatus;
