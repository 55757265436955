import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { useTable, useExpanded, useSortBy, usePagination } from "react-table";
import Tooltip from "../../shared/Tooltip";

import DropdownMenu from "./DropDownMenu";
import String from "../../../helpers/String";
import Pagination from "../../shared/Pagination";
import ParConstants from "../../../common/ParConstants";
import { useParContext } from "../../../store/ParContext";

const ParStaffTable = ({ data, handleClick, tableRef }) => {
  const [expanded, setExpanded] = useState(false);
  const { parWorkflows } = useParContext();

  const renderDropdownMenu = useCallback(
    ({ row }) => (
      <DropdownMenu
        row={row}
        parWorkFlows={parWorkflows}
        handleClick={handleClick}
      />
    ),
    [parWorkflows]
  );

  const columnsDef = React.useMemo(
    () => [
      {
        Header: "MSL PIN",
        accessor: "mslPin"
      },
      {
        Header: "Effective Dates",
        accessor: "effectiveDates",
        disableSortBy: true
      },
      {
        Header: "Employee Name",
        accessor: "employeeName"
      },
      {
        Header: "Workday Title",
        accessor: "workdayTitle",
        disableSortBy: true
      },
      {
        Header: "Department - Service",
        accessor: "departmentService",
        disableSortBy: true
      },
      {
        Header: "Client - Brand - LOB",
        accessor: "clientBrandLob",
        disableSortBy: true
      },
      {
        Header: "FTE",
        accessor: "allocations",
        disableSortBy: true
      },
      {
        id: "options",
        Cell: renderDropdownMenu,
        disableSortBy: true
      },
      {
        // not shown in table, added to determine which menu options to show
        Header: "Hidden",
        accessor: "pinStatus"
      }
    ],
    [data]
  );
  const memoizedData = React.useMemo(() => data, [data]);

  const table = useTable(
    {
      columns: columnsDef,
      data: memoizedData,
      getSubRows: () => [],
      initialState: {
        hiddenColumns: ["pinStatus"],
        sortBy: [
          {
            id: "employeeName",
            desc: false
          }
        ],
        pageSize:
          parseInt(
            localStorage.getItem(ParConstants.STAFF_PAGE_LOCAL_STORAGE),
            10
          ) || 100,
        pageIndex: 0
      },
      disableSortRemove: true
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    toggleAllRowsExpanded
  } = table;

  const handleExpandCollapse = () => {
    setExpanded(!expanded);
    toggleAllRowsExpanded(!expanded);
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [pageIndex]);

  return (
    <>
      <table className="staffplantable" {...getTableProps()}>
        <thead className="staffplanheader">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>
                <button
                  className="btn expandAllRows"
                  data-testid="expandAllRows"
                  type="button"
                  title="expandCollapse"
                  onClick={handleExpandCollapse}
                >
                  {expanded ? "Collapse All" : "Expand All"}
                </button>
              </th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="header-container">
                    <div className="colHeader">{column.render("Header")}</div>
                    <div className="sortContainer">
                      {column.isSorted ? (
                        <>
                          <div
                            className={`sortIcon-up ${
                              column.isSortedDesc ? "disabled" : ""
                            }`}
                          >
                            <i className="fas fa-chevron-up fa-xs" />
                          </div>
                          <div
                            className={`sortIcon-down ${
                              column.isSortedDesc ? "" : "disabled"
                            }`}
                          >
                            <i className="fas fa-chevron-down fa-xs" />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const isSubRow = row.depth > 0;
            let rowClass = "staffplanrow";

            if (isSubRow) {
              rowClass += " childRow";
            } else if (row.isExpanded) {
              rowClass += " parentRow";
            }

            return (
              <React.Fragment key={row.id}>
                {/* Render the main row */}
                <tr className={rowClass} {...row.getRowProps()}>
                  {!isSubRow && (
                    <td>
                      <div
                        data-testid="expander"
                        className="toggleArrow"
                        {...row.getToggleRowExpandedProps({})}
                      >
                        {row.isExpanded ? (
                          <i className="fas fa-minus fa-xs" />
                        ) : (
                          <i className="fas fa-plus fa-xs" />
                        )}
                      </div>
                    </td>
                  )}
                  {isSubRow && <td>&nbsp;</td>}
                  {row.cells.map((cell) => {
                    if (isSubRow) {
                      // logic to render child row
                      if (
                        cell.column.id === "mslPin" &&
                        !String.isNullOrEmptyOrUndefined(cell.value)
                      ) {
                        return (
                          <td {...cell.getCellProps()}>
                            <span className="statusValue">
                              {cell.render("Cell")}
                            </span>
                          </td>
                        );
                      }

                      if (cell.column.id === "clientBrandLob") {
                        return (
                          <td {...cell.getCellProps()} colSpan={2}>
                            <table className="clientBrandTbl">
                              <tbody>
                                {cell.value &&
                                  cell.value.map((item, index) => {
                                    const keyIndex = `client_${index}`;
                                    return (
                                      <tr key={keyIndex}>
                                        <td>{item.clientBrandLob}</td>
                                        <td>
                                          {parseFloat(item.allocation).toFixed(
                                            2
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                        );
                      }

                      if (cell.column.id !== "allocations") {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    } else if (
                      cell.column.id === "workdayTitle" ||
                      cell.column.id === "employeeName" ||
                      cell.column.id === "departmentService"
                    ) {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className="cell-content tooltip-cell">
                            <Tooltip
                              className="tooltip-content"
                              text={cell.value || ""}
                            >
                              <span className="clientBrand__content text-ellipsis">
                                {cell.value}
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      );
                    } else if (cell.column.id === "clientBrandLob") {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className="cell-content">
                            <div
                              className="expandClientLob"
                              {...row.getToggleRowExpandedProps({})}
                            >
                              {row.isExpanded
                                ? "Collapse Client - Brand - LOB"
                                : "See All Client - Brand - LOB"}
                            </div>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className="cell-content">
                            {cell.column.id === "allocations" &&
                            String.isFloat(cell.value)
                              ? parseFloat(cell.value).toFixed(2)
                              : cell.render("Cell")}
                          </div>
                        </td>
                      );
                    }

                    return null;
                  })}
                </tr>

                {/* Render expanded content inline */}
                {row.isExpanded && (
                  <tr className="expandedRow">
                    {/* Dynamically display data from row.original */}
                    <td> </td>
                    <td style={{ paddingRight: "8px" }}>
                      <span className="statusValue">
                        {row.original.subRows[0].mslPin || "N/A"}
                      </span>
                    </td>
                    <td>
                      {row.original.subRows[0].effectiveDates || "No PAR"}
                    </td>
                    <td>{row.original.subRows[0].employeeName || "Unknown"}</td>
                    <td>
                      {row.original.subRows[0].workdayTitle ||
                        "Unknown Location"}
                    </td>
                    <td>
                      {row.original.subRows[0].departmentService || "N/A"}
                    </td>
                    <td colSpan={2}>
                      <table className="clientBrandTbl">
                        <tbody>
                          {row.original.subRows[0].clientBrandLob &&
                            row.original.subRows[0].clientBrandLob.map(
                              (item, index) => {
                                const keyIndex = `client_${index}`;
                                return (
                                  <tr key={keyIndex}>
                                    <td>{item.clientBrandLob}</td>
                                    <td>
                                      {parseFloat(item.allocation).toFixed(2)}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <Pagination
        currentPage={pageIndex}
        totalPages={pageOptions.length}
        onPageChange={gotoPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        totalRows={data.length}
        showPerPage={ParConstants.SHOW_PER_PAGE}
        localStorageName={ParConstants.STAFF_PAGE_LOCAL_STORAGE}
      />
    </>
  );
};

ParStaffTable.propTypes = {
  row: PropTypes.shape({
    mslPin: PropTypes.string,
    effectiveDates: PropTypes.string,
    employeeName: PropTypes.string,
    workdayTitle: PropTypes.string,
    departmentService: PropTypes.string,
    clientBrandLob: PropTypes.string,
    allocations: PropTypes.string,
    subRows: PropTypes.shape({}),
    isExpanded: PropTypes.bool,
    getToggleRowExpandedProps: PropTypes.func,
    depth: PropTypes.number,
    canExpand: PropTypes.bool
  }),
  data: PropTypes.instanceOf(Array).isRequired,
  columns: PropTypes.shape({}),
  handleClick: PropTypes.func,
  tableRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })
};

ParStaffTable.defaultProps = {
  row: {},
  columns: {},
  handleClick: () => {},
  tableRef: {}
};

export default ParStaffTable;
