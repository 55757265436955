/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import CollapsibleCard from "../../shared/CollapsibleCard";
import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";

import TextBoxField from "../../shared/TextBoxField";
import Allocation from "./Allocation";
import { isGlobalEmployee } from "../../../helpers/ParHelpers";

const PositionDetails = ({
  formikProps,
  canModifyPARRecords,
  showDescription,
  validations,
  workflowName,
  showFieldsReadOnly,
  hideFields,
  getSalaryBudget
}) => {
  const [serviceLineDept, setServiceLineDepartment] = useState([]);
  const [userAddedValidations, setUserAddedValidations] = useState([]);

  const isRMIntake =
    formikProps.initialValues?.current_queue_name ===
    ParConstants.PAR_QUEUE.RM_INTAKE;
  const getPositionTitleAndBillable = (value, fieldName) => {
    formikProps.setFieldValue("par_billable", "");
    formikProps.setFieldValue("par_position_title", "");
    const parLevel =
      fieldName === "par_level" ? value : formikProps.values.par_level?.value;
    const summaryDepartment =
      fieldName === "summary_department"
        ? value
        : formikProps.values.summary_department?.value;
    const servicelineDepartment =
      fieldName === "serviceline_department"
        ? value
        : formikProps.values.serviceline_department?.value;

    if (parLevel && summaryDepartment && servicelineDepartment) {
      const parPositionTitlePrefix = validations.parPositionTitlePrefix?.filter(
        (x) => x.parentValue.toUpperCase() === parLevel
      );
      const val = servicelineDepartment + "~" + summaryDepartment;
      const parPositionTitleSuffix = validations.parPositionTitleSuffix?.filter(
        (x) => x.parentValue === val
      );
      const positionTitlePre =
        parPositionTitlePrefix && parPositionTitlePrefix[0]
          ? parPositionTitlePrefix[0].value
          : null;
      const positionTitleSuf =
        parPositionTitleSuffix && parPositionTitleSuffix[0]
          ? parPositionTitleSuffix[0].value
          : null;
      const positionTitle =
        positionTitlePre && positionTitleSuf
          ? positionTitlePre + ", " + positionTitleSuf
          : positionTitlePre || positionTitleSuf;
      formikProps.setFieldValue("par_position_title", positionTitle || "");
      const billable = validations.parBillable?.filter(
        (x) => x.parentValue === val
      );
      if (billable && billable.length > 0) {
        const parBillable = billable[0]?.value;
        formikProps.setFieldValue("par_billable", parBillable);
      }
    }
  };

  const getRequisitionValues = (profitCenter) => {
    if (isGlobalEmployee(formikProps.values?.employee_type?.value)) return;

    formikProps.setFieldValue("par_brand", "");
    formikProps.setFieldValue("par_axcompany", "");
    formikProps.setFieldValue("par_payroll_company", "");
    formikProps.setFieldValue("par_bpc", "");

    if (profitCenter) {
      const parBPC = validations.parBPC?.filter(
        (x) => x.parentValue.toUpperCase() === profitCenter.toUpperCase()
      );
      const parAXCompany = validations.parAXCompany?.filter(
        (x) => x.parentValue.toUpperCase() === profitCenter.toUpperCase()
      );

      const parBrand = validations.parBrand?.filter(
        (x) => x.parentValue.toUpperCase() === profitCenter.toUpperCase()
      );
      const parPayrollCompany = validations.parPayrollCompany?.filter(
        (x) => x.parentValue.toUpperCase() === profitCenter.toUpperCase()
      );

      if (parBPC && parBPC.length > 0) {
        formikProps.setFieldValue("par_bpc", parBPC[0].value || "");
      }
      if (parAXCompany && parAXCompany.length > 0) {
        formikProps.setFieldValue("par_axcompany", parAXCompany[0].value || "");
      }
      if (parBrand && parBrand.length > 0) {
        formikProps.setFieldValue("par_brand", parBrand[0].value || "");
      }
      if (parPayrollCompany && parPayrollCompany.length > 0) {
        formikProps.setFieldValue(
          "par_payroll_company",
          parPayrollCompany[0].value || ""
        );
      }
    }
  };

  useEffect(() => {
    // Get the Title and Billable fields for only a new PAR
    getPositionTitleAndBillable();

    // Only update the associated fields if non-global employee type
    const profitCenterVal = formikProps.values?.profit_center?.value;
    if (
      profitCenterVal &&
      formikProps.values?.employee_type &&
      formikProps.values.employee_type?.value !==
        RapConstants.EMPLOYEE_TYPE_VALUES[1]
    ) {
      getRequisitionValues(profitCenterVal);
    }
  }, []);

  const filterServiceLineDept = (value) => {
    // Clearing previous state
    setServiceLineDepartment([]);
    // filtering service line based on summary department value
    let filteredServiceLine = validations.serviceLineDepartmentList.filter(
      (x) => x.parentValue?.split(",").indexOf(value) > -1
    );
    if (
      formikProps &&
      !filteredServiceLine.filter(
        (x) => x.value === formikProps.values.serviceline_department?.value
      ).length > 0
    ) {
      // Clear selection from service line dropdown if not in filtered service lines
      formikProps.setFieldValue("serviceline_department", null);
    }
    setServiceLineDepartment(filteredServiceLine || []);
    getPositionTitleAndBillable(value, "summary_department");
  };

  // Method to set the list of user added validation values, which can be shown
  // in the warning later
  const setUserAddedValidationsValue = (fieldName, value) => {
    const userValidations = [userAddedValidations];
    let foundIndex = userValidations.findIndex(
      (x) => x.fieldName === fieldName
    );
    if (foundIndex >= 0) {
      userValidations[foundIndex] = { fieldName, value };
    } else {
      userValidations.push({ fieldName, value });
    }
    setUserAddedValidations(userValidations);
  };

  const clearUserAddedValidations = (fieldName) => {
    const userValidations = [userAddedValidations];
    let foundIndex = userValidations.findIndex(
      (x) => x.fieldName === fieldName
    );
    if (foundIndex >= 0) {
      userValidations.splice(foundIndex, 1);
    }
    setUserAddedValidationsValue(userValidations);
  };

  const isTBHWorkflow = () =>
    workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH;

  const isReallocationTBH = () =>
    workflowName === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH;

  const isOptionalField = () => {
    const { URGENT_COUNTER_OFFER, BACKFILL_REQUEST } =
      ParConstants.PAR_WORKFLOW;
    return [URGENT_COUNTER_OFFER, BACKFILL_REQUEST].includes(workflowName);
  };

  const isEditableBackfillReason = (formProps) => {
    const hasNoBackfillReasonValue =
      !formProps.initialValues?.backfill_reason?.value;

    if (isRMIntake && hasNoBackfillReasonValue) {
      return false;
    }

    return showDescription || showFieldsReadOnly.backfill_reason;
  };

  return (
    <CollapsibleCard title="Position Details">
      <div className="row">
        {!hideFields.open_requisition_reason && (
          <div className="mt-3 col-md-3">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={
                workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH
                  ? ParConstants.REASON_FOR_REQUEST_TBH
                  : ParConstants.REASON_FOR_REQUEST
              }
              fieldName="open_requisition_reason"
              label="Reason for Request"
              setUserAddedValidationsValue={setUserAddedValidationsValue}
              clearUserAddedValidations={clearUserAddedValidations}
              disable={!canModifyPARRecords}
              showDescription={
                showDescription || showFieldsReadOnly.open_requisition_reason
              }
              isOptional={isOptionalField()}
            />
          </div>
        )}

        {!hideFields.backfill_reason && (
          <div className="mt-3 col-md-3">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={ParConstants.BACKFILL_REASON}
              fieldName="backfill_reason"
              label="Reason for Backfill"
              setUserAddedValidationsValue={setUserAddedValidationsValue}
              clearUserAddedValidations={clearUserAddedValidations}
              disable={!canModifyPARRecords}
              showDescription={isEditableBackfillReason(formikProps)}
            />
          </div>
        )}
        <div className="mt-3 col-md-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={validations.summaryDepartmentList || []}
            fieldName="summary_department"
            label="Summary Department"
            disable={!canModifyPARRecords}
            placeholder="Select Summary Department."
            setUserAddedValidationsValue={setUserAddedValidationsValue}
            clearUserAddedValidations={clearUserAddedValidations}
            updateChildDropdown={filterServiceLineDept}
            showDescription={showFieldsReadOnly.summary_department}
            isParent
          />
        </div>
        <div className="mt-3 col-md-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={serviceLineDept?.map((x) => x.value) || []}
            fieldName="serviceline_department"
            label="Service Line Department"
            setUserAddedValidationsValue={setUserAddedValidationsValue}
            clearUserAddedValidations={clearUserAddedValidations}
            disable={!canModifyPARRecords}
            showDescription={showFieldsReadOnly.serviceline_department}
            updateChildDropdown={(value) =>
              getPositionTitleAndBillable(value, "serviceline_department")
            }
            isParent
          />
        </div>
        {!hideFields.par_level && (
          <div className="mt-3 col-md-1">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={validations.levelList || []}
              fieldName="par_level"
              label="Level"
              disable={!canModifyPARRecords}
              showDescription={
                showDescription || isTBHWorkflow() || isReallocationTBH()
              }
              onValueChange={(value) =>
                getPositionTitleAndBillable(value, "par_level")
              }
            />
          </div>
        )}
        <div className="mt-3 col-md-3">
          <TextBoxField
            className="mt-0"
            formikProps={formikProps}
            fieldName="business_title"
            placeholder="Type Business Title"
            label="Business Title"
            disable={!canModifyPARRecords}
            isOptional
            showDescription={showFieldsReadOnly.business_title}
          />
        </div>
        <div className="mt-4 col-md-3">
          <TextBoxField
            className="mb-2"
            formikProps={formikProps}
            fieldName="par_position_title"
            placeholder="Type Position Title"
            label="Position Title"
            disable={!canModifyPARRecords}
            isOptional
            showDescription
          />
        </div>
        {!hideFields.par_billable && (
          <div className="mt-4 col-md-3">
            <TextBoxField
              className="ml-1"
              formikProps={formikProps}
              fieldName="par_billable"
              placeholder="Type Funded/ NonFunded"
              label="Billable/Non-Billable"
              disable={!canModifyPARRecords}
              isOptional
              showDescription
            />
          </div>
        )}
        {!hideFields.profit_center && (
          <div className="mt-3 col-md-3">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={validations.payrollProfitCenterList || []}
              fieldName="profit_center"
              label="Profit Center"
              isOptional={false}
              disable={!canModifyPARRecords}
              showDescription={showFieldsReadOnly.profit_center}
              isParent
              updateChildDropdown={(value) =>
                // Only update child fields if non-global employee type
                formikProps.values?.employee_type &&
                formikProps.values.employee_type?.value !==
                  RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                getRequisitionValues(value)
              }
            />
          </div>
        )}
        {!isGlobalEmployee(formikProps.values?.employee_type?.value) &&
          !hideFields.target_budget && (
            <div className="mt-2 col-md-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="target_budget"
                placeholder="Type Target Budget"
                type="number"
                label="Target Budget"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.target_budget}
                isOptional
              />
            </div>
          )}
      </div>
      <Allocation
        formikProps={formikProps}
        validations={validations}
        canModifyPARRecords={canModifyPARRecords}
        showDescription={showDescription}
        showFieldsReadOnly={showFieldsReadOnly}
        hideFields={hideFields}
        getSalaryBudget={getSalaryBudget}
        workflowName={workflowName}
      />
      {!hideFields.salary_budget && (
        <div className="mt-4 col-md-3">
          <TextBoxField
            formikProps={formikProps}
            fieldName="salary_budget"
            placeholder="Type Salary Budget"
            label="Salary Budget"
            type="number"
            showTooltip
            toolTipText="Add Rate Card to calculate and display Salary Budget"
            disable={!canModifyPARRecords}
            isOptional
            showDescription={showFieldsReadOnly.salary_budget}
          />
        </div>
      )}
    </CollapsibleCard>
  );
};

PositionDetails.propTypes = {
  formikProps: PropTypes.shape({
    initialValues: PropTypes.shape({
      current_queue_name: PropTypes.string,
      backfill_reason: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    values: PropTypes.shape({
      par_request_id: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      summary_department: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      serviceline_department: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      open_requisition_reason: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      par_level: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      profit_center: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array),
    parPositionTitlePrefix: PropTypes.instanceOf(Array),
    parPositionTitleSuffix: PropTypes.instanceOf(Array),
    parBillable: PropTypes.instanceOf(Array),
    parBPC: PropTypes.instanceOf(Array),
    parAXCompany: PropTypes.instanceOf(Array),
    parBrand: PropTypes.instanceOf(Array),
    parPayrollCompany: PropTypes.instanceOf(Array)
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  workflowName: PropTypes.string,
  showFieldsReadOnly: PropTypes.shape({
    allocClient: PropTypes.bool,
    allocLOB: PropTypes.bool,
    allocRateCard: PropTypes.bool,
    allocVal: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    business_title: PropTypes.bool,
    target_budget: PropTypes.bool,
    salary_budget: PropTypes.bool,
    backfill_reason: PropTypes.bool,
    open_requisition_reason: PropTypes.bool,
    summary_department: PropTypes.bool,
    serviceline_department: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    allocRateCard: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    target_budget: PropTypes.bool,
    salary_budget: PropTypes.bool,
    open_requisition_reason: PropTypes.bool,
    par_level: PropTypes.bool,
    backfill_reason: PropTypes.bool,
    par_billable: PropTypes.bool
  }),
  getSalaryBudget: PropTypes.func
};

PositionDetails.defaultProps = {
  canModifyPARRecords: false,
  showDescription: false,
  workflowName: null,
  showFieldsReadOnly: {},
  hideFields: {},
  getSalaryBudget: () => {}
};

export default PositionDetails;
