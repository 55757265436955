import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ParConstants from "../../../common/ParConstants";
import CollapsibleCard from "../../shared/CollapsibleCard";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import TextBoxField from "../../shared/TextBoxField";
import Tooltip from "../../shared/Tooltip";
import helpers, {
  getPercentageIncrease,
  isGlobalEmployee
} from "../../../helpers/ParHelpers";

const PromotionInstructions = ({
  formikProps,
  canModifyPARRecords,
  showFieldsReadOnly,
  hideFields,
  parPayRanges,
  validations
}) => {
  const parLevel = formikProps?.values?.par_level;

  const filteredPayRanges = useMemo(() => {
    if (!parLevel) return [];
    // Extract numeric part from par_level
    const numericLevel = parLevel?.value.match(/\d+/)?.[0];
    return numericLevel
      ? parPayRanges.filter((range) => range.level.includes(numericLevel))
      : [];
  }, [parLevel]);

  const isFieldRequired = () =>
    formikProps?.values?.current_queue_name ===
      ParConstants.PAR_QUEUE.HR_INTAKE &&
    !isGlobalEmployee(formikProps?.values?.employee_type?.value);

  return (
    <CollapsibleCard title="Promotion Instructions">
      <>
        <div className="row">
          <div className="mt-3 col-md-3">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={validations.levelList || []}
              fieldName="requested_level"
              label="Requested Level"
              disable={!canModifyPARRecords}
              showDescription={showFieldsReadOnly.requested_level}
            />
          </div>
          <div className="mt-3 col-md-3">
            <TextBoxField
              formikProps={formikProps}
              fieldName="par_position_title"
              placeholder="Type Position Title"
              label="Position Title"
              disable={!canModifyPARRecords}
              isOptional
              showDescription
            />
          </div>
          <div className="mt-3 col-md-3">
            <TextBoxField
              className="mt-0"
              formikProps={formikProps}
              fieldName="promotion_business_title"
              placeholder="Type Requested Business Title"
              label="Requested Business Title"
              disable={!canModifyPARRecords}
              isOptional
              showDescription={showFieldsReadOnly.promotion_business_title}
            />
          </div>
        </div>
        <div className="row">
          {!hideFields.requested_offer && (
            <div className="col-md-3 mt-2">
              <TextBoxField
                type="number"
                formikProps={formikProps}
                fieldName="requested_offer"
                label="Requested Offer"
                disable={!canModifyPARRecords}
                isOptional={!isFieldRequired()}
                showDescription={showFieldsReadOnly.requested_offer}
              />
            </div>
          )}

          {!hideFields.target_effective_date && (
            <div className="col-md-3 mt-3">
              <DatePickerBoxField
                className="ml-0"
                formikProps={formikProps}
                label="Target Effective Date"
                fieldName="target_effective_date"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.target_effective_date}
              />
            </div>
          )}
          {!hideFields.job_code && (
            <div className="mt-3 col-md-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={filteredPayRanges?.map((x) => x.job_profile) || []}
                fieldName="job_code"
                placeholder="Job Profile"
                label="Job Profile"
                disable={!canModifyPARRecords}
                showDescription={showFieldsReadOnly.job_code}
              />
            </div>
          )}
        </div>
        <div className="row">
          {!hideFields.percent_increase && (
            <div className="mt-3 col-md-3">
              <div className="topTooltipContainer ml-1">
                <div className="textLabel">Percent Increase</div>
                <div className="tooltip-cell">
                  <Tooltip
                    className="tooltip-content"
                    text="Please enter the Requested Offer and Current Base Salary to see the Percent Increase value"
                  >
                    <i className="fas fa-info-circle fa-lg info-color" />
                  </Tooltip>
                </div>
              </div>
              <div id="percent_increase" className="form-description mt-2 ml-1">
                <strong>
                  {getPercentageIncrease(
                    formikProps.values.current_base_salary,
                    formikProps.values.requested_offer
                  )}
                </strong>
              </div>
            </div>
          )}
          {!hideFields.compa_ratio_of_offer && (
            <div className="mt-3 col-md-3">
              <div className="ml-1">
                <div className="topTooltipContainer">
                  <div className="textLabel">Compa Ratio of Offer</div>
                  <div className="tooltip-cell">
                    <Tooltip
                      className="tooltip-content"
                      text="Please Enter the Requested Offer and Select Job Profile to see this Calculated Ratio"
                    >
                      <i className="fas fa-info-circle fa-lg info-color" />
                    </Tooltip>
                  </div>
                </div>
                <div
                  id="compa_ratio_of_offer"
                  className="form-description mt-2"
                >
                  <strong>
                    {helpers.getCompRatioOfOffer(
                      validations?.parGeoRange,
                      parPayRanges,
                      formikProps
                    )}
                  </strong>
                </div>
              </div>
            </div>
          )}
        </div>

        {/** Only show this Row with BPC, AX Company etc for non-Global employees * */}
        {!isGlobalEmployee(formikProps.values?.employee_type?.value) && (
          <div className="row">
            {!hideFields.par_brand && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_brand"
                  label="Brand"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_brand}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
            {!hideFields.par_axcompany && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_axcompany"
                  label="AX Company"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_axcompany}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
            {!hideFields.par_payroll_company && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_payroll_company"
                  label="Payroll Company"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_payroll_company}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
            {!hideFields.par_bpc && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_bpc"
                  label="BPC"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_bpc}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
          </div>
        )}
      </>
    </CollapsibleCard>
  );
};

PromotionInstructions.propTypes = {
  canModifyPARRecords: PropTypes.bool,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      allocations: PropTypes.instanceOf(Array),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      current_queue_name: PropTypes.string,
      current_base_salary: PropTypes.string,
      requested_offer: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_name: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      par_level: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_location: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      client_lob: PropTypes.string
    }),
    errors: PropTypes.any,
    initialValues: PropTypes.shape({
      allocations: PropTypes.instanceOf(Array),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      client_lob: PropTypes.string
    }),
    setFieldValue: PropTypes.func
  }).isRequired,
  hideFields: PropTypes.shape({
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    current_performance_rating: PropTypes.bool,
    requested_offer: PropTypes.bool,
    target_effective_date: PropTypes.bool,
    compa_ratio_of_offer: PropTypes.bool,
    percent_increase: PropTypes.bool,
    job_code: PropTypes.bool,
    par_brand: PropTypes.bool,
    par_axcompany: PropTypes.bool,
    par_payroll_company: PropTypes.bool,
    par_bpc: PropTypes.bool
  }),
  showFieldsReadOnly: PropTypes.shape({
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    current_performance_rating: PropTypes.bool,
    requested_offer: PropTypes.bool,
    target_effective_date: PropTypes.bool,
    job_code: PropTypes.bool,
    requested_level: PropTypes.bool,
    promotion_business_title: PropTypes.bool,
    par_brand: PropTypes.bool,
    par_axcompany: PropTypes.bool,
    par_payroll_company: PropTypes.bool,
    par_bpc: PropTypes.bool
  }),
  parPayRanges: PropTypes.instanceOf(Array),
  validations: PropTypes.shape({
    levelList: PropTypes.instanceOf(Array),
    parGeoRange: PropTypes.instanceOf(Array)
  }).isRequired
};

PromotionInstructions.defaultProps = {
  canModifyPARRecords: false,
  showFieldsReadOnly: {},
  hideFields: {},
  parPayRanges: []
};

export default PromotionInstructions;
