/* eslint-disable no-return-assign */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import TextBoxField from "../../shared/TextBoxField";
import Tooltip from "../../shared/Tooltip";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";

import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";
import CollapsibleCard from "../../shared/CollapsibleCard";
import ButtonGroup from "../../shared/ButtonGroup";
import helpers, { isGlobalEmployee } from "../../../helpers/ParHelpers";

const ExternalFill = ({
  formikProps,
  validations,
  canModifyPARRecords,
  showDescription,
  workflowName,
  showFieldsReadOnly,
  hideFields,
  globalCostData,
  parGeoRange,
  parPayRanges
}) => {
  const [offshoreRoles, setOffshoreRoles] = useState([]);
  const [payrollCompany, setPayrollCompany] = useState(null);
  const [location, setLocation] = useState(null);

  const setPartnerCost = (value) => {
    if (value) {
      let record = globalCostData.filter(
        (x) =>
          x.offshore_role_type === value &&
          x.payrollcompany === payrollCompany &&
          x.location === location
      )[0];
      if (record) {
        formikProps.setFieldValue("partner_cost", record.annual_cost);
        // Set payroll company to the data from global_cost_card_data
        formikProps.setFieldValue("par_payroll_company", payrollCompany);
      }
    } else {
      formikProps.setFieldValue("partner_cost", "", true);
    }
  };

  const filterOffshoreRoles = (value) => {
    let partner = value && value.split(" - ");
    if (partner) {
      setPayrollCompany(partner[0]);
      setLocation(partner[1]);
      const offshoreRole = globalCostData.filter(
        (x) =>
          x.payrollcompany === partner[0] &&
          x.location === partner[1] &&
          x.offshore_role_type !== ""
      );
      setOffshoreRoles(offshoreRole.map((x) => x.offshore_role_type));
    } else {
      setOffshoreRoles([]);
      formikProps.setFieldValue("offshore_role", null);
      formikProps.setFieldValue("partner_cost", "");
    }
  };
  const employeeType = formikProps.values?.employee_type?.value;
  return (
    <CollapsibleCard
      className="highlighted-panel"
      title="External Fill Requirements"
    >
      <div className="row">
        <div className="col-md-3 mt-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={RapConstants.EMPLOYEE_TYPE_VALUES}
            fieldName="employee_type"
            label="Employee Type"
            disable={!canModifyPARRecords}
            showDescription={showDescription}
            onValueChange={(value) => {
              if (value === RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
                formikProps.setFieldValue("job_code", null);
              }
            }}
          />
        </div>
        {/* Show fields for Non Global Employee Type */}
        {employeeType && !isGlobalEmployee(employeeType) && (
          <>
            <div className="col-md-3 mt-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={validations.locationList || []}
                fieldName="fill_location"
                label="Fill Location"
                disable={!canModifyPARRecords}
                showDescription={showDescription}
              />
            </div>
            <div className="col-md-3 mt-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={ParConstants.LOCATION_REASON}
                fieldName="location_reason"
                label="Location Reason"
                disable={!canModifyPARRecords}
                showDescription={showDescription}
              />
            </div>
            {/* isExempt is valid only for non-global employees */}
            {formikProps.values?.employee_type &&
              formikProps.values.employee_type?.value !==
                RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
              !hideFields.is_exempt &&
              !showFieldsReadOnly.is_exempt && (
                <div className="col-sm-2 mt-3">
                  <Field name="is_exempt">
                    {({ meta }) => (
                      <ButtonGroup
                        metaObj={meta}
                        fieldName="is_exempt"
                        fieldValue={formikProps.values.is_exempt}
                        label="Exempt"
                        buttonValues={ParConstants.BOOLEAN_VALUES}
                        setButtonValue={(value) => {
                          formikProps.setFieldValue("is_exempt", value);
                        }}
                        inError={!!meta.error}
                        isDisabled={!canModifyPARRecords}
                        isRequired
                      />
                    )}
                  </Field>
                </div>
              )}
            {!isGlobalEmployee(formikProps.values?.employee_type?.value) &&
              !hideFields.is_exempt &&
              showFieldsReadOnly.is_exempt && (
                <div className="col-sm-2 mt-3">
                  <label htmlFor="btnGrpForm-is_exempt">
                    Exempt
                    <span className="red-text"> *</span>
                  </label>
                  <div id="btnGrpForm-is_exempt" className="form-description">
                    <strong>{formikProps.values.is_exempt}</strong>
                  </div>
                </div>
              )}
          </>
        )}
        {/* Show fields for Global Employee Type */}
        {employeeType && isGlobalEmployee(employeeType) && (
          <>
            {!hideFields.partner && (
              <div className="col-md-3 mt-3">
                <TypeAheadBoxField
                  formikProps={formikProps}
                  suggestions={
                    workflowName ===
                    ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST
                      ? ParConstants.PARTNERS_OPEN_REQ
                      : ParConstants.PARTNERS
                  }
                  fieldName="partner"
                  label="Partner"
                  disable={!canModifyPARRecords}
                  showDescription={showFieldsReadOnly.partner}
                  isParent
                  updateChildDropdown={filterOffshoreRoles}
                />
              </div>
            )}
            {!hideFields.offshore_role && (
              <div className="col-md-3 mt-3">
                <TypeAheadBoxField
                  formikProps={formikProps}
                  suggestions={offshoreRoles || []}
                  fieldName="offshore_role"
                  label="Offshore Role"
                  disable={!canModifyPARRecords}
                  showDescription={showFieldsReadOnly.offshore_role}
                  isParent
                  updateChildDropdown={(val, event) =>
                    setPartnerCost(val, event)
                  }
                />
              </div>
            )}
            {!hideFields.partner_cost && (
              <div className="col-md-3 mt-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="partner_cost"
                  label="Partner Cost"
                  type="number"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.partner_cost}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
          </>
        )}
      </div>
      {employeeType && !isGlobalEmployee(employeeType) && (
        <div className="row">
          {!hideFields.requested_offer && (
            <div className="col-md-3 mt-3">
              <TextBoxField
                className="ml-1"
                type="number"
                formikProps={formikProps}
                fieldName="requested_offer"
                label="Requested Offer"
                disable={!canModifyPARRecords}
                isOptional
                showDescription={showFieldsReadOnly.requested_offer}
              />
            </div>
          )}
          {formikProps.values?.employee_type &&
            formikProps.values.employee_type?.value !==
              RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
            !hideFields.compa_ratio_of_offer && (
              <div className="mt-3 col-md-3">
                <div className="topTooltipContainer">
                  <div className="textLabel">Compa Ratio of Offer</div>
                  <div className="tooltip-cell">
                    <Tooltip
                      className="tooltip-content"
                      text="Please Enter the Requested Offer and Select Job Profile to see this Calculated Ratio"
                    >
                      <i className="fas fa-info-circle fa-lg info-color" />
                    </Tooltip>
                  </div>
                </div>
                <div
                  id="compa_ratio_of_offer"
                  className="form-description mt-2"
                >
                  <strong>
                    {helpers.getCompRatioOfOffer(
                      parGeoRange,
                      parPayRanges,
                      formikProps
                    )}
                  </strong>
                </div>
              </div>
            )}
        </div>
      )}
    </CollapsibleCard>
  );
};

ExternalFill.propTypes = {
  formikProps: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      is_exempt: PropTypes.string,
      location_reason: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      fill_location: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      partner: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    })
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array)
  }).isRequired,
  workflowName: PropTypes.string,
  showFieldsReadOnly: PropTypes.shape({
    allocClient: PropTypes.bool,
    allocLOB: PropTypes.bool,
    allocRateCard: PropTypes.bool,
    allocVal: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    is_exempt: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    offshore_role: PropTypes.bool,
    requested_offer: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    allocRateCard: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    is_exempt: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    offshore_role: PropTypes.bool,
    requested_offer: PropTypes.bool,
    compa_ratio_of_offer: PropTypes.bool
  }),
  globalCostData: PropTypes.instanceOf(Array),
  parGeoRange: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array)
};

ExternalFill.defaultProps = {
  canModifyPARRecords: false,
  showDescription: false,
  workflowName: null,
  showFieldsReadOnly: {},
  hideFields: {},
  globalCostData: [],
  parGeoRange: [],
  parPayRanges: []
};

export default ExternalFill;
